<template>
  <button
    type="button"
    class="btn primary"
    @click="goto()"
    :class="{ newPlusBtn: !isMessages }"
  >
    <font-awesome-icon
      v-if="isMobileWidth && isMessages"
      icon="fa-light fa-pen-to-square"
      class="icon"
    />
    <font-awesome-icon
      v-if="isMobileWidth && !isMessages"
      icon="fa-light fa-plus"
      class="icon-size-1"
    />
    <span class="btn-text">{{ getLabel }}</span>
  </button>
</template>

<script lang="ts" setup>
import { useCommonStore } from '@/stores/CommonStore'
import { storeToRefs } from 'pinia'
import { computed, nextTick, toRef } from 'vue'
import { useRoute, useRouter } from 'vue-router'
const props = defineProps({
  label: {
    type: String
  },
})

// get route name to determine where to route to the add new whatever it is
const router = useRouter()
const route = useRoute()

const commonStore = useCommonStore()

const { isMobileWidth } = storeToRefs(commonStore)

const isMessages = computed(() => {
  return route.name?.toString() == 'messages'
})

const getLabel = computed(() => {
  return `${route.meta.nameForLabels}`
})

function goto() {
  if (route.meta.addNewRouteName) {
    router.push({ name: route.meta.addNewRouteName })
  }
}
</script>

<style lang="scss" scoped>
.newPlusBtn {
  width: 26px !important;
  height: 26px !important;
  border-radius: .4rem !important;
  border: 2px solid var(--brand) !important;
  
  @media(min-width:48em){
    border-radius: 3rem !important;
    border: 0 !important;
    width: unset !important;
    height: unset !important;
  }
}

.btn-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  margin: -1px;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  font-variation-settings: 'wght' 500;

  @media(min-width: 48em){
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    clip-path: none;
    white-space: normal;
  }
}
button[type='button'] {
  width: 26px;
  border-radius: 0.5rem;
  color: var(--brand);
  min-width: 0;
  display: inline-flex;
  gap: 0.5rem;
  background-color: transparent;
  padding: 0;
  white-space: nowrap;
  border: 0;
  flex: none;

  @media (min-width: 48em) {
    width: auto;
    height: 40px;
    border-radius: 3rem;
    color: var(--gray-12);
    padding: var(--size-0) var(--size-2);
    background-color: light-dark(var(--brand-5), var(--brand-3));
  }
}

button[type='button']:hover {
  --background: var(--gray-3);

  @media (min-width: 48em) {
    background-color: #55c6b0;
  }
}

.icon {
  width: 21px;
  height: 21px;

  @media (min-width: 48em) {
    width: 0.75rem;
    height: 0.75rem;
  }
}

.label {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  @media (min-width: 48em) {
    clip: unset;
    height: unset;
    margin: unset;
    overflow: unset;
    padding: unset;
    position: unset;
    width: unset;
  }
}
</style>
