﻿/* eslint-disable no-useless-escape */
export default {
  getPasswordLengthRequirement() {
    return 8
  },
  getPasswordRegexRequirement() {
    return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[\~\`\!\@\#\$\%\^\&\*\(\)\_\-\+\=\{\[\}\]\|\\\:\;\"\'\<\,\>\.\?\/])[A-Za-z\d\~\`\!\@\#\$\%\^\&\*\(\)\_\-\+\=\{\[\}\]\|\\\:\;\"\'\<\,\>\.\?\/]{10,}$/
  },
  getPasswordRequirementMessage() {
    return 'Please provide a password with at least 10 characters, one letter, one number, and one special character.'
  },
  getPasswordHintText() {
    return 'Must contain at least 10 characters, 1 letter, 1 number, and 1 special character.'
  }
}
