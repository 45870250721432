import i18n from '.'
import { nextTick } from 'vue'

const i18nHelpers = {
  get defaultLocale() {
    return 'en'
  },

  get supportedLocales() {
    return ['en', 'es']
  },

  get currentLocale() {
    return i18n.global.locale.value
  },

  set currentLocale(newLocale) {
    i18n.global.locale.value = newLocale
  },

  async switchLanguage(newLocale: string) {
    await i18nHelpers.loadLocaleMessages(newLocale)
    i18nHelpers.currentLocale = newLocale

    i18nHelpers.saveLocale(newLocale)
  },

  saveLocale(locale: string) {
    i18nHelpers.currentLocale = locale

    const htmlElement: HTMLElement | null = document.querySelector('html')

    if (htmlElement) {
      htmlElement.setAttribute('lang', locale)
    }

    localStorage.setItem('user-locale', locale)
  },

  async loadLocaleMessages(locale: string) {
    if (!i18n.global.availableLocales.includes(locale)) {
      const messages = await import(`@/i18n/locales/${locale}.json`)
      i18n.global.setLocaleMessage(locale, messages.default)
    }

    return nextTick()
  },

  isLocaleSupported(locale: string) {
    return i18nHelpers.supportedLocales.includes(locale)
  },

  getUserLocale(): { locale: string; localeNoRegion: string } {
    const locale: string =
      window.navigator.language ||
      (window.navigator as any)['userLanguage'] ||
      'en' // Replace 'en-US' with a default locale if necessary

    return {
      locale: locale,
      localeNoRegion: locale.split('-')[0]
    }
  },

  getPersistedLocale() {
    const persistedLocale = localStorage.getItem('user-locale') ?? ''

    if (i18nHelpers.isLocaleSupported(persistedLocale)) {
      return persistedLocale
    } else {
      return null
    }
  },

  getGuessDefaultLocale() {
    // if we decide to use local storage to store locale
    // const userPersistedLocale = i18nHelpers.getPersistedLocale()
    // if (userPersistedLocale) {
    //   return userPersistedLocale
    // }

    const userPreferredLocale = i18nHelpers.getUserLocale()

    if (i18nHelpers.isLocaleSupported(userPreferredLocale.locale)) {
      i18nHelpers.saveLocale(userPreferredLocale.locale)
      return userPreferredLocale.locale
    }

    if (i18nHelpers.isLocaleSupported(userPreferredLocale.localeNoRegion)) {
      i18nHelpers.saveLocale(userPreferredLocale.localeNoRegion)
      return userPreferredLocale.localeNoRegion
    }

    return i18nHelpers.defaultLocale
  }
}

export default i18nHelpers
