<template>
  <div id="recaptcha" class="g-recaptcha" />
</template>

<script lang="ts" setup>
import { onMounted, reactive } from 'vue'

const props = defineProps({
  verifiedCallback: {
    type: Function,
    required: true
  }
})

const emit = defineEmits<{
  (e: 'loaded', val: boolean): void
}>()

const state = reactive({
  recaptchaId: null as unknown as string
})

onMounted(() => {
  window.grecaptchaApiLoaded = recaptchaApiLoaded
  const script = document.createElement('script')
  script.src =
    'https://www.google.com/recaptcha/api.js?onload=grecaptchaApiLoaded&render=explicit'
  script.id = 'grecaptcha'
  document.body.appendChild(script)
})

function recaptchaApiLoaded() {
  state.recaptchaId = window.grecaptcha.render('recaptcha', {
    sitekey: '6Ld1i_IUAAAAAPpTS6-MQWIcTgUcWvq5TBuQ3a3R',
    callback: verified,
    size: 'invisible'
  })
  emit('loaded', true)
}
function verify() {
  window.grecaptcha.execute()
}

defineExpose({ verify })

function verified(token: string) {
  props.verifiedCallback(token)
  window.grecaptcha.reset(state.recaptchaId)
}
</script>
