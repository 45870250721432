<template>
  <button v-if="isScrollingUp" type="button" @click="BackToTop()">
    <font-awesome-icon icon="fa-light fa-chevron-up" class="icon" />
    <!-- <span class="label">{{ getLabel }}</span> -->
  </button>
</template>

<script lang="ts" setup>
import { onMounted, onUnmounted, ref } from 'vue'
import { useThrottleFn } from '@vueuse/core'

const isScrollingUp = ref(false)
const isScrolling = ref()
const prevWindowHeight = ref(0)

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})

function BackToTop() {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  })
}

async function handleScroll() {
  window.clearTimeout(isScrolling.value)

  if (window.scrollY > 150) {
    throttledScrollFn()
  } else {
    isScrollingUp.value = false
  }
  prevWindowHeight.value = window.scrollY
}

const throttledScrollFn = useThrottleFn(() => {
  if (prevWindowHeight.value > window.scrollY) {
    isScrollingUp.value = true
  } else {
    isScrollingUp.value = false
  }
  prevWindowHeight.value = window.scrollY
}, 200)
</script>

<style lang="scss" scoped>
button[type='button'] {
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 0;
  flex: none;
  position: fixed;
  right: 0.5rem;
  bottom: 0.5rem;
  align-self: center;
  border-radius: 3rem;
  background: var(--brand-gradient-1);
  box-shadow: var(--shadow-5);
  @media (min-width: 48em) {
    right: 20px;
    bottom: 20px;
  }
}
.icon {
  width: 24px;
  height: 24px;
  color: white;
}
</style>
