import { ColumnLayoutColumnState } from '@/models/enums.ts'
import type { ILayoutState } from '@/models/stores/layout'
import { defineStore } from 'pinia'
import { computed, reactive, toRefs, watch } from 'vue'
import helper from '@/exports/helper'

const paths: string[] = []

export const useLayoutStore = defineStore('layout', () => {
  const initialState = (): ILayoutState => ({
    numOfColumns: 3,
    columns: [
      ColumnLayoutColumnState.Visible,
      ColumnLayoutColumnState.Visible,
      ColumnLayoutColumnState.Visible
    ],
    dividerWidthInPixels: 2
  })

  const state = reactive(initialState())

  function reset() {
    Object.assign(
      state,
      helper.omit(initialState(), paths as (keyof ILayoutState)[])
    )
  }

  function getDividerWidthInPixelsForColumn(column: number) {
    return visibleColumnIndicesIncludes(column) &&
      visibleColumnToRightOf(column)
      ? state.dividerWidthInPixels
      : 0
  }

  const visibleColumnIndices = computed(() => {
    const indices = [] as number[]

    state.columns.forEach((c, i) => {
      if (c != ColumnLayoutColumnState.Hidden) indices.push(i)
    })

    return indices
  })

  function visibleColumnIndicesIncludes(index: number) {
    return visibleColumnIndices.value.includes(index)
  }

  function visibleColumnToRightOf(column: number) {
    return state.columns.slice(column + 1).some((c) => c)
  }

  function setNumOfColumns(value: number) {
    state.numOfColumns = value
  }

  watch(
    () => state.numOfColumns,
    async (newVal, oldVal) => {
      if (newVal > oldVal) {
        state.columns.length = newVal
        state.columns.fill(ColumnLayoutColumnState.Visible, oldVal)

        return
      }

      state.columns.splice(newVal)
    }
  )

  function setColumnState(column: number, value: ColumnLayoutColumnState) {
    state.columns[column] = value
  }

  function collapsibleColumnsContains(column: number) {
    return state.columns[column] == ColumnLayoutColumnState.Collapsible
    //return collapsibleColumns.value.includes(column)
  }

  function staticColumnsContains(column: number) {
    return state.columns[column] == ColumnLayoutColumnState.Static
    //return staticColumns.value.includes(column)
  }

  function isColumnStaticOrCollapsible(column: number) {
    return collapsibleColumnsContains(column) || staticColumnsContains(column)
  }

  function resetColumnProperties() {
    state.columns = Array(state.numOfColumns).fill(
      ColumnLayoutColumnState.Visible
    )
  }

  function isColumnVisible(column: number) {
    return visibleColumnIndices.value.includes(column)
  }

  function setDividerWidthInPixels(width: number) {
    state.dividerWidthInPixels = width
  }

  function hideColumn(column: number) {
    setColumnState(column, ColumnLayoutColumnState.Hidden)
  }

  function showColumn(column: number) {
    setColumnState(column, ColumnLayoutColumnState.Visible)
  }

  //only reset columns that are hidden
  function softResetColumns() {
    state.columns.forEach((c, i) => {
      if (!c) setColumnState(i, ColumnLayoutColumnState.Visible)
    })
  }

  function setVaultDesktopView() {
    softResetColumns()
    setColumnState(0, ColumnLayoutColumnState.Static)
  }

  function setVaultTabletView() {
    softResetColumns()
    setColumnState(0, ColumnLayoutColumnState.Static)
    setColumnState(2, ColumnLayoutColumnState.Hidden)
  }

  function setVaultMobileView() {
    softResetColumns()
    resetColumnProperties()

    setColumnState(0, ColumnLayoutColumnState.Hidden)
    setColumnState(2, ColumnLayoutColumnState.Hidden)
  }

  function setVaultItemSelectedView() {
    if (state.numOfColumns == 3) {
      // in vault page
      //item was selected in mobile or tablet view - hide second column, show third
      hideColumn(1)
      showColumn(2)
    } else if (state.numOfColumns == 2) {
      // in vaultpicker
      hideColumn(0)
      showColumn(1)
    }
  }

  function setVaultQuickAccessView() {
    hideColumn(1)
    showColumn(0)
  }

  function setVaultModalViewDesktop() {
    resetColumnProperties()
    setNumOfColumns(2)
  }

  async function setVaultModalViewMobile() {
    resetColumnProperties()
    setNumOfColumns(2)
    // in vaultpicker
    showColumn(0)
    hideColumn(1)
  }

  async function setVaultModalViewDetailsMobile() {
    resetColumnProperties()
    setNumOfColumns(2)
    // in vaultpicker
    showColumn(1)
    hideColumn(0)
  }

  function setCallingDetailViewMobile() {
    hideColumn(1)
    showColumn(2)
  }

  function setCallingDetailViewTablet() {
    hideColumn(1)
    showColumn(2)
  }

  return {
    visibleColumnIndices,
    setNumOfColumns,
    setColumnState,
    collapsibleColumnsContains,
    isColumnVisible,
    setDividerWidthInPixels,
    hideColumn,
    showColumn,
    setVaultDesktopView,
    setVaultTabletView,
    setVaultMobileView,
    setVaultItemSelectedView,
    visibleColumnIndicesIncludes,
    getDividerWidthInPixelsForColumn,
    visibleColumnToRightOf,
    setVaultQuickAccessView,
    setVaultModalViewDesktop,
    setVaultModalViewMobile,
    setVaultModalViewDetailsMobile,
    setCallingDetailViewMobile,
    isColumnStaticOrCollapsible,
    setCallingDetailViewTablet,
    staticColumnsContains,
    resetColumnProperties,
    softResetColumns,
    ...toRefs(state),
    reset
  }
})
