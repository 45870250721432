﻿<template>
  <fieldset class="flex align-middle fieldset">
    <legend class="sr-only">Enter 6 digit code below</legend>
    <input
      ref="input1"
      v-model="input1Value"
      name="VerifyCodeBlock1Input"
      type="text"
      aria-describedby="SubjectTip"
      :maxLength="1"
      :pattern=regexPattern
      class="verify-code-block-input"
      :class="{
        'error-border': verifyPhoneSmsErrorMessage.length || errorStateOverride
      }"
      autocomplete="false"
      :title="{ titlePrefix } + ' Code Block 1'"
      @input="input1Value = verifyInput($event)"
      @focus="input1.select()"
      @keyup="handleKeyUp($event)"
      @paste="handlePaste($event)"
    />
    <input
      ref="input2"
      v-model="input2Value"
      name="VerifyCodeBlock2Input"
      type="text"
      aria-describedby="SubjectTip"
      :maxLength="1"
      :pattern=regexPattern
      class="verify-code-block-input"
      :class="{
        'error-border': verifyPhoneSmsErrorMessage.length || errorStateOverride
      }"
      autocomplete="false"
      :title="{ titlePrefix } + ' Code Block 2'"
      @input="input2Value = verifyInput($event)"
      @keyup="handleKeyUp($event)"
      @paste="handlePaste($event)"
      @focus="input2.select()"
    />
    <input
      ref="input3"
      v-model="input3Value"
      name="VerifyCodeBlock3Input"
      type="text"
      aria-describedby="SubjectTip"
      :maxLength="1"
      :pattern=regexPattern
      class="verify-code-block-input"
      :class="{
        'error-border': verifyPhoneSmsErrorMessage.length || errorStateOverride
      }"
      autocomplete="false"
      :title="{ titlePrefix } + ' Code Block 3'"
      @input="input3Value = verifyInput($event)"
      @focus="input3.select()"
      @keyup="handleKeyUp($event)"
      @paste="handlePaste($event)"
    />
    <span class="hyphen gray-softgray"></span>
    <input
      ref="input4"
      v-model="input4Value"
      name="VerifyCodeBlock4Input"
      type="text"
      aria-describedby="SubjectTip"
      :maxLength="1"
      :pattern=regexPattern
      class="verify-code-block-input"
      :class="{
        'error-border': verifyPhoneSmsErrorMessage.length || errorStateOverride
      }"
      autocomplete="false"
      :title="{ titlePrefix } + ' Code Block 4'"
      @input="input4Value = verifyInput($event)"
      @focus="input4.select()"
      @keyup="handleKeyUp($event)"
      @paste="handlePaste($event)"
    />
    <input
      ref="input5"
      v-model="input5Value"
      name="VerifyCodeBlock5Input"
      type="text"
      aria-describedby="SubjectTip"
      :maxLength="1"
      :pattern=regexPattern
      class="verify-code-block-input"
      :class="{
        'error-border': verifyPhoneSmsErrorMessage.length || errorStateOverride
      }"
      autocomplete="false"
      :title="{ titlePrefix } + ' Code Block 5'"
      @input="input5Value = verifyInput($event)"
      @focus="input5.select()"
      @keyup="handleKeyUp($event)"
      @paste="handlePaste($event)"
    />
    <input
      ref="input6"
      v-model="input6Value"
      name="VerifyCodeBlock6Input"
      type="text"
      aria-describedby="SubjectTip"
      :maxLength="1"
      :pattern=regexPattern
      class="verify-code-block-input"
      :class="{
        'error-border': verifyPhoneSmsErrorMessage.length || errorStateOverride
      }"
      autocomplete="false"
      :title="{ titlePrefix } + ' Code Block 6'"
      @input="input6Value = verifyInput($event)"
      @focus="input6.select()"
      @keyup="handleKeyUp($event)"
      @paste="handlePaste($event)"
    />
  </fieldset>
</template>

<script setup lang="ts">
import { toRef, useFocus } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { computed, nextTick, ref, watch } from 'vue'
import { useField } from 'vee-validate'
import { useElementVisibility } from '@vueuse/core'
import { useAccountSettingsStore } from '@/stores/AccountSettingsStore'

const titlePrefix = 'MFA'

const accountSettingsStore = useAccountSettingsStore()
const { verifyPhoneSmsErrorMessage } = storeToRefs(accountSettingsStore)

const props = defineProps({
  isMatchId: {
    type: Boolean,
    default: false
  },
  errorStateOverride: {
    type: Boolean,
    default: false
  }
})

const errorStateOverride = toRef(props, 'errorStateOverride')

const emit = defineEmits<{
  (e: 'codeResult', value: string): void
  (e: 'inputChange'): void
  (e: 'focusedInput'): void
}>()

const input1 = ref()
const input2 = ref()
const input3 = ref()
const input4 = ref()
const input5 = ref()
const input6 = ref()
const submitBtn = ref()
const { focused: input1Focus } = useFocus(input1, { initialValue: true })
const { focused: input2Focus } = useFocus(input2)
const { focused: input3Focus } = useFocus(input3)
const { focused: input4Focus } = useFocus(input4)
const { focused: input5Focus } = useFocus(input5)
const { focused: input6Focus } = useFocus(input6)
const { focused: submitBtnFocus } = useFocus(submitBtn)

watch(useElementVisibility(input1), async (val: boolean) => {
  if (val) {
    await nextTick()
    input1Focus.value = true
  }
})

watch(
  () => verifyPhoneSmsErrorMessage.value,
  (errorMessage) => {
    if (errorMessage.length) {
      input1.value.error = true
    }
  }
)

const { value: input1Value } = useField('VerifyCodeBlock1Input', 'required')
const { value: input2Value } = useField('VerifyCodeBlock2Input', 'required')
const { value: input3Value } = useField('VerifyCodeBlock3Input', 'required')
const { value: input4Value } = useField('VerifyCodeBlock4Input', 'required')
const { value: input5Value } = useField('VerifyCodeBlock5Input', 'required')
const { value: input6Value } = useField('VerifyCodeBlock6Input', 'required')

watch([input1Focus, input2Focus, input3Focus, input4Focus, input6Focus], ([input1Focus, input2Focus, input3Focus, input4Focus, input6Focus]) => {
  if (input1Focus || input2Focus || input3Focus || input4Focus || input6Focus) emit('focusedInput')
})

function verifyInput(event: any) {
  event.preventDefault()

  if (event.target.value == '') {
    return event.target.value
  } else if (!props.isMatchId) {
    if (/^[0-9]*$/.test(event.target.value)) {
      switch (event.target.name) {
        case 'VerifyCodeBlock1Input':
          input2Focus.value = true
          break
        case 'VerifyCodeBlock2Input':
          input3Focus.value = true
          break
        case 'VerifyCodeBlock3Input':
          input4Focus.value = true
          break
        case 'VerifyCodeBlock4Input':
          input5Focus.value = true
          break
        case 'VerifyCodeBlock5Input':
          input6Focus.value = true
          break
        case 'VerifyCodeBlock6Input':
          submitBtnFocus.value = true
          break
      }
      emitFullValue()
      return event.target.value
    } else {
      return ''
    }
  } else if (props.isMatchId) {
    if (/^[a-zA-Z0-9]*$/.test(event.target.value)) {
      switch (event.target.name) {
        case 'VerifyCodeBlock1Input':
          input2Focus.value = true
          break
        case 'VerifyCodeBlock2Input':
          input3Focus.value = true
          break
        case 'VerifyCodeBlock3Input':
          input4Focus.value = true
          break
        case 'VerifyCodeBlock4Input':
          input5Focus.value = true
          break
        case 'VerifyCodeBlock5Input':
          input6Focus.value = true
          break
        case 'VerifyCodeBlock6Input':
          submitBtnFocus.value = true
          break
      }
      emitFullValue()
      return event.target.value
    } else {
      return ''
    }
  } else {
    return ''
  }
}

function handleKeyUp(event: any) {
  event.preventDefault()

  if (event.key == 'Backspace') {
    switch (event.target.name) {
      case 'VerifyCodeBlock1Input':
        input1Value.value = ''
        break
      case 'VerifyCodeBlock2Input':
        if (input2Value.value == '') {
          // input1Value.value = ''
          input1Focus.value = true
        } else {
          input2Value.value = ''
        }
        break
      case 'VerifyCodeBlock3Input':
        if (input3Value.value == '') {
          // input2Value.value = ''
          input2Focus.value = true
        } else {
          input3Value.value = ''
        }
        break
      case 'VerifyCodeBlock4Input':
        if (input4Value.value == '') {
          // input3Value.value = ''
          input3Focus.value = true
        } else {
          input4Value.value = ''
        }
        break
      case 'VerifyCodeBlock5Input':
        if (input5Value.value == '') {
          // input4Value.value = ''
          input4Focus.value = true
        } else {
          input5Value.value = ''
        }
        break
      case 'VerifyCodeBlock6Input':
        if (input6Value.value == '') {
          // input5Value.value = ''
          input5Focus.value = true
        } else {
          input6Value.value = ''
        }
        break
    }
    emitFullValue()
  } else if (event.key == 'ArrowLeft') {
    switch (event.target.name) {
      case 'VerifyCodeBlock1Input':
        input1Focus.value = true
        break
      case 'VerifyCodeBlock2Input':
        input1Focus.value = true
        break
      case 'VerifyCodeBlock3Input':
        input2Focus.value = true
        break
      case 'VerifyCodeBlock4Input':
        input3Focus.value = true
        break
      case 'VerifyCodeBlock5Input':
        input4Focus.value = true
        break
      case 'VerifyCodeBlock6Input':
        input5Focus.value = true
        break
    }
    emitFullValue()
  } else if (event.key == 'ArrowRight') {
    switch (event.target.name) {
      case 'VerifyCodeBlock1Input':
        input2Focus.value = true
        break
      case 'VerifyCodeBlock2Input':
        input3Focus.value = true
        break
      case 'VerifyCodeBlock3Input':
        input4Focus.value = true
        break
      case 'VerifyCodeBlock4Input':
        input5Focus.value = true
        break
      case 'VerifyCodeBlock5Input':
        input6Focus.value = true
        break
      case 'VerifyCodeBlock6Input':
        input6.value.select()
    }
    emitFullValue()
  } else if (event.key == 'Home') {
    input1Focus.value = true
    emitFullValue()
  } else if (event.key == 'End') {
    input6Focus.value = true
    emitFullValue()
  }
}

function handlePaste(event: any) {
  event.preventDefault()

  const pastedData = event.clipboardData.getData('text/plain')

  if (!props.isMatchId && !/^[0-9]{3}-[0-9]{3}$/.test(pastedData)) {
    return
  } else if (props.isMatchId && !/^[a-zA-Z0-9]{3}-[a-zA-Z0-9]{3}$/.test(pastedData)) {
    return
  }
  input1Value.value = pastedData[0]
  input2Value.value = pastedData[1]
  input3Value.value = pastedData[2]
  input4Value.value = pastedData[4]
  input5Value.value = pastedData[5]
  input6Value.value = pastedData[6]

  emitFullValue()
  useFocus(submitBtn)
}

function emitFullValue() {
  emit(
    'codeResult',
    (input1Value.value as string) +
      input2Value.value +
      input3Value.value +
      input4Value.value +
      input5Value.value +
      input6Value.value
  )
}

const regexPattern = computed(() => {
  return props.isMatchId ? '[a-zA-Z0-9]*' : '[0-9]*'
})
</script>

<style lang="scss" scoped>
.fieldset {
  justify-content: center;
  gap: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-block: 0.25rem;
  border: 0;
}
.fieldset input[type='text'] {
  min-width: 0;
  padding: 0;
  max-width: 3.125rem;
  border-radius: var(--radius-3);
  aspect-ratio: 11/13;
  text-align: center;
  color: var(--black);
}

.code-input-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  gap: 0.5rem;
}

.verify-code-block-input {
  border-radius: var(--size-00);
  width: 3.125rem;
  height: 3.125rem;
}

.hyphen {
  width: 1.25rem;
  height: 0.25rem;
  border-radius: 0.1rem;
  background-color: var(--gray-3);
}

.error-border {
  border-color: var(--error-red);
  border-width: 1px;
  box-shadow: 0px 0px 0px 2px var(--error-red);
}
</style>
