import { defineStore } from 'pinia'
import { computed, reactive, toRefs } from 'vue'
import { useSorted } from '@vueuse/core'

import httpClient from '@/httpClient'

import ErrorHelper from '@/exports/error'

import {
  GetSharedVaultItemResponse,
  type IGetSharedVaultItemResponse,
  type IGetSharedVaultItemResult,
  type IFileParam,
  type INewFolderParam,
  type IVaultAttachmentParam,
  type IVaultJournalAttachmentParam,
  type IShareEmailParam,
  FileParam
} from '@/models/models'
import { ref } from 'vue'
import type {
  IVaultItem,
  IGetVaultItemsParam,
  IGetVaultShareLinkParam,
  INewFolderResponse,
  IUploadTokenResponse,
  IVaultUpload,
  IVaultFolder,
  IBreadcrumbEvent,
  IVaultDownload,
  WorkerRequest,
  IThumbnailFetch,
  IGetRecentVaultItemsParam,
  IMoveVaultItemsParam,
  ISearchFilterSortVaultItemsParam,
  ITrashOrRestoreVaultItemsParam,
  IStarVaultItemsParam,
  ITrackingTrackFunction
} from '@/models/interfaces'
import {
  AxiosError,
  type AxiosProgressEvent,
  type AxiosRequestConfig,
  type AxiosResponse
} from 'axios'
import axios from 'axios'
import {
  HttpStatusCodes,
  VaultColumnFilter,
  VaultListViewMode,
  VaultSortBy,
  VaultThumbnailStatus
} from '@/models/enums'
import constants from '@/exports/constants'
import moment from 'moment'
import { useCommonStore } from './CommonStore'

import WorkerService from '@/services/webWorkerService'
import { useLoginStore } from '@/stores/LoginStore'
import { useModals } from '@/composables/useModal/useModal'
import fileDownloaderWorker from '../workers/fileDownloader?worker&url'
import thumbnailFetcherWorker from '../workers/thumbnailFetcher?worker&url'
import i18n from '@/i18n'
import { br } from '@/plugins/trackerPlugin'
import { inject } from 'vue'
import type { IVaultState } from '@/models/stores/vault'

import helper from '@/exports/helper'

const baseUrl = '/mobile/api/vault'

const downloadControllerBaseUrl = `${baseUrl}/download`
const fileControllerBaseUrl = `${baseUrl}/file`
const folderControllerBaseUrl = `${baseUrl}/folder`
const uploadTokenControllerBaseUrl = `${baseUrl}/uploadtoken`
const vaultAccountControllerBaseUrl = 'mobile/api/vaultaccount'
const vaultAttachmentControllerBaseUrl = `${baseUrl}/attachment`
const vaultItemControllerBaseUrl = `${baseUrl}/item`
const vaultJournalAttachmentControllerBaseUrl = `${baseUrl}/journalattachment`
const vaultShareControllerBaseUrl = `${baseUrl}/share`

const paths: string[] = []

export const useVaultStore = defineStore('vault', () => {
  //#region share vault

  const initialState = (): IVaultState => ({
    apiError: null,
    sharedVaultItem: null,
    vaultItems: [
      {
        itemID: 0,
        userID: 0,
        blobName: '',
        name: '',
        description: '',
        fileSize: 0,
        isFolder: false,
        createdDate: new Date(),
        isStarred: false,
        isTrashed: false,
        mimeType: '',
        hasThumbnail: false,
        isDeleted: false,
        isSubTrashed: false,
        skeletonLoading: true
      },
      {
        itemID: 0,
        userID: 0,
        blobName: '',
        name: '',
        description: '',
        fileSize: 0,
        isFolder: false,
        createdDate: new Date(),
        isStarred: false,
        isTrashed: false,
        mimeType: '',
        hasThumbnail: false,
        isDeleted: false,
        isSubTrashed: false,
        skeletonLoading: true
      },
      {
        itemID: 0,
        userID: 0,
        blobName: '',
        name: '',
        description: '',
        fileSize: 0,
        isFolder: false,
        createdDate: new Date(),
        isStarred: false,
        isTrashed: false,
        mimeType: '',
        hasThumbnail: false,
        isDeleted: false,
        isSubTrashed: false,
        skeletonLoading: true
      }
    ],
    pageSize: 25,
    pageNumber: 1,
    rangeStart: null,
    rangeEnd: null,
    loadMoreVaultItems: false,
    vaultItemAttachments: [],
    selectedItemsList: null,
    draggingVaultItem: null,
    hoveringOverVaultItem: null,
    currentFolderLevel: null,
    multiSelectMode: false,
    currentFolder: null,
    selectedVaultSortBy: VaultSortBy.CreatedDateDesc,
    vaultListViewMode: VaultListViewMode.List,
    searchTerm: '',
    treeData: [],
    downloads: [],
    fetchedThumbnails: [],
    editingItem: false,
    selectedVaultColumnFilter: VaultColumnFilter.All,
    quotaAccountInfo: null,
    uploads: [],
    upload: null,
    undoItems: null,
    thumbnailWorkerIds: []
  })

  const state = reactive(initialState())

  async function setApiError(payload: string | null) {
    state.apiError = payload
  }

  async function setSharedVaultItem(payload: IGetSharedVaultItemResponse) {
    state.sharedVaultItem = payload
  }

  async function fetchSharedVaultItem(itemGuid: string) {
    setApiError(null)

    let response: IGetSharedVaultItemResult = {
      success: false,
      errorCode: undefined,
      errorMessage: undefined,
      value: new GetSharedVaultItemResponse()
    }

    try {
      response = (
        await httpClient.get(
          `/web/api/Vault/GetSharedVaultItem?itemGuid=${itemGuid}`
        )
      ).data

      if (!response.success) {
        throw new Error(response.errorMessage || '')
      }
    } catch (e) {
      ErrorHelper.handleError(e, 'fetchSharedVaultItem')
    }

    setSharedVaultItem(response.value || new GetSharedVaultItemResponse())
  }
  //#endregion share vault

  //#region vault feature
  const track = inject<ITrackingTrackFunction>(
    '$trackingTrack'
  ) as ITrackingTrackFunction

  function reset() {
    Object.assign(
      state,
      helper.omit(initialState(), paths as (keyof IVaultState)[])
    )
  }

  function handleVaultItemsUpdate(items: IVaultItem[]) {
    setVaultItems(items)

    if (state.vaultItems.length) {
      const commonStore = useCommonStore()
      commonStore.setShowingNoCount(false)
    } else if (
      state.selectedVaultColumnFilter == VaultColumnFilter.All &&
      !state.searchTerm.length &&
      state.currentFolder == null
    ) {
      const commonStore = useCommonStore()
      commonStore.setShowingNoCount(true)
    }
  }

  const getCurrentFolderId = computed(() => {
    if (!state.selectedItemsList) {
      return
    }

    const selectedItem = state.selectedItemsList[0]

    if (!selectedItem) {
      return
    }

    return selectedItem.isFolder
      ? state.selectedItemsList[0].itemID
      : state.selectedItemsList[0]?.parentItemID
  })

  const selectedVaultSortByColumn = computed(() => {
    switch (state.selectedVaultSortBy) {
      case VaultSortBy.NameAsc:
        return 'Name'
      case VaultSortBy.NameDesc:
        return 'Name'
      case VaultSortBy.CreatedDateAsc:
        return 'CreatedDate'
      case VaultSortBy.CreatedDateDesc:
        return 'CreatedDate'
      case VaultSortBy.FileSizeAsc:
        return 'FileSize'
      case VaultSortBy.FileSizeDesc:
        return 'FileSize'
      default:
        return 'CreatedDate'
    }
  })

  const selectedVaultSortByDirection = computed(() => {
    switch (state.selectedVaultSortBy) {
      case VaultSortBy.NameAsc:
        return 'ASC'
      case VaultSortBy.NameDesc:
        return 'DESC'
      case VaultSortBy.CreatedDateAsc:
        return 'ASC'
      case VaultSortBy.CreatedDateDesc:
        return 'DESC'
      case VaultSortBy.FileSizeAsc:
        return 'ASC'
      case VaultSortBy.FileSizeDesc:
        return 'DESC'
      default:
        return 'ASC'
    }
  })

  const skeletonLoading = computed(
    () => !!state.vaultItems.find((vi) => vi.skeletonLoading)
  )

  function resetVaultItems() {
    state.vaultItems = [
      {
        itemID: 0,
        userID: 0,
        blobName: '',
        name: '',
        description: '',
        fileSize: 0,
        isFolder: false,
        createdDate: new Date(),
        isStarred: false,
        isTrashed: false,
        mimeType: '',
        hasThumbnail: false,
        isDeleted: false,
        isSubTrashed: false,
        skeletonLoading: true
      },
      {
        itemID: 0,
        userID: 0,
        blobName: '',
        name: '',
        description: '',
        fileSize: 0,
        isFolder: false,
        createdDate: new Date(),
        isStarred: false,
        isTrashed: false,
        mimeType: '',
        hasThumbnail: false,
        isDeleted: false,
        isSubTrashed: false,
        skeletonLoading: true
      },
      {
        itemID: 0,
        userID: 0,
        blobName: '',
        name: '',
        description: '',
        fileSize: 0,
        isFolder: false,
        createdDate: new Date(),
        isStarred: false,
        isTrashed: false,
        mimeType: '',
        hasThumbnail: false,
        isDeleted: false,
        isSubTrashed: false,
        skeletonLoading: true
      }
    ]

    const selectedItemsListItemIds = state.selectedItemsList?.map(
      (si) => si.itemID
    )

    state.fetchedThumbnails = state.fetchedThumbnails.filter((ft) =>
      selectedItemsListItemIds?.includes(ft.itemID)
    )
  }

  function setLoadMoreVaultItems(loadMore: boolean) {
    state.loadMoreVaultItems = loadMore
  }

  function setSelectedVaultSortBy(sortBy: VaultSortBy) {
    state.selectedVaultSortBy = sortBy
  }

  function setVaultListViewMode(viewMode: VaultListViewMode) {
    state.vaultListViewMode = viewMode
  }

  function setSearchTerm(term: string) {
    state.searchTerm = term
  }

  async function setTreeData(payload: Array<IVaultFolder>) {
    state.treeData = { ...payload }
  }

  async function setVaultColumnFilter(payload: VaultColumnFilter) {
    state.selectedVaultColumnFilter = payload
  }

  function setvaultItemAttachments(attachmentList: IVaultItem[]) {
    state.vaultItemAttachments = attachmentList
  }

  function setSelectedItemsList(selectedList: IVaultItem[] | null) {
    state.selectedItemsList = selectedList
  }

  function setUndoItems(items: IVaultItem[] | null) {
    state.undoItems = items
  }

  function setCurrentFolderLevel(id: number | null) {
    state.currentFolderLevel = id
  }

  function setMultiSelectMode(mode: boolean) {
    state.multiSelectMode = mode
  }

  function setCurrentFolder(folder: IVaultItem | null) {
    state.currentFolder = folder
  }

  function setRangeStart(start: string | null) {
    state.rangeStart = start
  }

  function setRangeEnd(end: string | null) {
    state.rangeEnd = end
  }

  function pushVaultItemToAttachments(item: IVaultItem) {
    if (state.vaultItemAttachments) {
      if (state.vaultItemAttachments.find((f) => f.itemID === item.itemID)) {
        return false
      }
      state.vaultItemAttachments?.push(item)
    } else {
      state.vaultItemAttachments = new Array(item)
    }

    return true
  }

  function removeVaultItemFromAttachments(itemID: number) {
    if (state.vaultItemAttachments) {
      const index = getVaultItemAttachmentIndex(itemID)
      if (index != undefined) {
        state.vaultItemAttachments.splice(index, 1)
      }
    }
  }

  function getVaultItemAttachmentIndex(itemID: number) {
    if (state.vaultItemAttachments) {
      const index = state.vaultItemAttachments.findIndex(
        (f) => f.itemID === itemID
      )
      return index
    } else {
      return -1
    }
  }

  function pushVaultItemToSelectedList(item: IVaultItem) {
    if (state.selectedItemsList) {
      if (state.selectedItemsList.find((f) => f.itemID === item.itemID)) {
        return false
      }
      state.selectedItemsList?.push(item)
      return true
    }

    state.selectedItemsList = new Array(item)
    return true
  }

  function removeVaultItemFromSelectedList(itemID: number) {
    if (state.selectedItemsList) {
      const index = getVaultItemSelectedListIndex(itemID)
      if (index != undefined) {
        state.selectedItemsList.splice(index, 1)
      }
    }
  }

  function getVaultItemSelectedListIndex(itemID: number) {
    if (state.selectedItemsList) {
      const index = state.selectedItemsList.findIndex(
        (f) => f.itemID === itemID
      )
      return index
    }

    return -1
  }

  const selectedVaultColumnFilterAPI = computed(() => {
    switch (state.selectedVaultColumnFilter) {
      case VaultColumnFilter.Starred:
        return 2
      case VaultColumnFilter.Trashed:
        return 1
      case VaultColumnFilter.Files:
        return 4
      case VaultColumnFilter.Folders:
        return 3
      case VaultColumnFilter.Images:
        return 5
      case VaultColumnFilter.Videos:
        return 6
      case VaultColumnFilter.Recent:
      default:
        return undefined
    }
  })

  const fetchItemsRequestObject = computed(() => ({
    parentItemID: state.currentFolder?.itemID,
    pageNumber: state.pageNumber,
    pageSize: state.pageSize,
    rangeStart:
      state.selectedVaultColumnFilter == VaultColumnFilter.Recent
        ? state.rangeStart ?? undefined
        : undefined,
    rangeEnd:
      state.selectedVaultColumnFilter == VaultColumnFilter.Recent
        ? state.rangeEnd ?? undefined
        : undefined,
    searchTerm: state.searchTerm.length ? state.searchTerm : undefined,
    filterBy: selectedVaultColumnFilterAPI.value,
    sortBy: asVaultFilter(state.selectedVaultSortBy)
  }))

  async function fetchItems() {
    if (state.pageNumber == 1) {
      //does all the resetting common between all the different functions - search, column filter, navigation, etc.
      setLoadMoreVaultItems(false)
      terminateAllThumbnailWorkers()
      resetVaultItems()
    }

    let fetchedItems = null as IVaultItem[] | null

    if (state.selectedVaultColumnFilter == VaultColumnFilter.All) {
      fetchedItems = (
        await searchFilterSortDirectory(fetchItemsRequestObject.value)
      )?.data as IVaultItem[]
    } else {
      fetchedItems = (await searchFilterSortAll(fetchItemsRequestObject.value))
        ?.data as IVaultItem[]
    }

    setLoadMoreVaultItems(fetchedItems.length == state.pageSize)

    handleVaultItemsUpdate(
      state.pageNumber == 1
        ? fetchedItems
        : [...state.vaultItems, ...fetchedItems]
    )
  }

  async function fetchMoreItems() {
    setPageNumber(++state.pageNumber)

    await fetchItems()
  }

  async function fetchFirstItems() {
    setPageNumber(1)

    await fetchItems()
  }

  async function navigateIntoFolder(
    folderItem?: IVaultItem,
    clearSelectedList = true
  ) {
    setPageNumber(1)
    setCurrentFolder(folderItem ?? null)
    setVaultColumnFilter(VaultColumnFilter.All)

    if (clearSelectedList) {
      handleSelectedItemsListUpdate(null)
    }

    if (!state.treeData) {
      await updateFolderTree()
    }

    updateBreadcrumbs()

    await fetchItems()
  }

  // assumes we're in the correct folder
  async function updateCurrentFolder(pageNumber?: number) {
    setPageNumber(pageNumber ?? 1)

    await fetchItems()
  }

  async function handleSortByUpdate(
    sortBy: VaultSortBy,
    clearSelectedList = true
  ) {
    setSelectedVaultSortBy(sortBy)
    if (state.loadMoreVaultItems) {
      setPageNumber(1)

      if (clearSelectedList) {
        handleSelectedItemsListUpdate(null)
      }

      await fetchItems()
    } else {
      sortLocally()
    }
  }

  function sortLocally() {
    const folders = state.vaultItems.filter((vi) => vi.isFolder)
    const files = state.vaultItems.filter((vi) => !vi.isFolder)

    sortItemsLocally(folders)
    sortItemsLocally(files)

    setVaultItems([...folders, ...files])
  }

  function sortItemsLocally(items: IVaultItem[]) {
    switch (state.selectedVaultSortBy) {
      case VaultSortBy.NameAsc:
        useSorted(items, (a, b) => a.name.localeCompare(b.name), {
          dirty: true
        })
        break
      case VaultSortBy.NameDesc:
        useSorted(items, (a, b) => b.name.localeCompare(a.name), {
          dirty: true
        })
        break
      case VaultSortBy.CreatedDateAsc:
        useSorted(
          items,
          (a, b) =>
            new Date(a.createdDate).getTime() -
            new Date(b.createdDate).getTime(),
          {
            dirty: true
          }
        )
        break
      case VaultSortBy.CreatedDateDesc:
        useSorted(
          items,
          (a, b) =>
            new Date(b.createdDate).getTime() -
            new Date(a.createdDate).getTime(),
          {
            dirty: true
          }
        )
        break
      case VaultSortBy.FileSizeAsc:
        useSorted(
          items,
          (a, b) => {
            if (a.isFolder && b.isFolder) {
              //folders are typically sorted alphabetically when sorting by file size
              return a.name.localeCompare(b.name)
            }

            return a.fileSize - b.fileSize
          },
          {
            dirty: true
          }
        )
        break
      case VaultSortBy.FileSizeDesc:
        useSorted(
          items,
          (a, b) => {
            if (a.isFolder && b.isFolder) {
              //still alphabetical
              return a.name.localeCompare(b.name)
            }

            return b.fileSize - a.fileSize
          },
          {
            dirty: true
          }
        )
        break
    }
  }

  async function handleColumnFilterUpdate(
    filterBy: VaultColumnFilter,
    clearSelectedList = true
  ) {
    setVaultColumnFilter(filterBy)
    setPageNumber(1)
    setCurrentFolder(null)

    if (clearSelectedList) {
      handleSelectedItemsListUpdate(null)
    }

    updateBreadcrumbs()

    if (
      filterBy == VaultColumnFilter.Folders &&
      (state.selectedVaultSortBy == VaultSortBy.FileSizeAsc ||
        state.selectedVaultSortBy == VaultSortBy.FileSizeDesc)
    ) {
      setSelectedVaultSortBy(VaultSortBy.CreatedDateDesc)
    }

    if (filterBy == VaultColumnFilter.Recent) {
      setRangeStart(
        moment.utc().subtract(60, 'days').format('YYYY-MM-DDTHH:mm:ss')
      )
    } else {
      setRangeStart(null)
    }

    await fetchItems()
  }

  function handleSelectedItemsListUpdate(items: IVaultItem[] | null) {
    setSelectedItemsList(items)

    if (!state.selectedItemsList?.length) {
      setMultiSelectMode(false)
    }
  }

  function asVaultFilter(value: VaultSortBy) {
    if (value == VaultSortBy.CreatedDateDesc) return 1
    if (value == VaultSortBy.CreatedDateAsc) return 2
    if (value == VaultSortBy.NameAsc) return 3
    if (value == VaultSortBy.NameDesc) return 4
    if (value == VaultSortBy.FileSizeAsc) return 5
    if (value == VaultSortBy.FileSizeDesc) return 6
  }

  async function handleSearchTermUpdate(
    term: string,
    clearSelectedList = true
  ) {
    setSearchTerm(term)
    setPageNumber(1)
    updateBreadcrumbs()

    if (clearSelectedList) {
      handleSelectedItemsListUpdate(null)
    }

    await fetchItems()
  }

  async function handleTrashFileOrfolder(item: IVaultItem) {
    let trashResult = false
    // trash item / folder
    if (item.isFolder) {
      trashResult =
        (await folderTrash(item.itemID))?.status == HttpStatusCodes.OK
      if (trashResult) {
        await updateFolderTree()
      }
    } else {
      trashResult = (await fileTrash(item.itemID))?.status == HttpStatusCodes.OK
    }

    // todo: add undo
    if (trashResult) {
      handleSelectedItemsListUpdate(null)
      if (state.currentFolderLevel == item.itemID && state.treeData) {
        const folder = findFolderPath(state.treeData, item)?.pop()
        await navigateIntoFolder(folder as IVaultItem)
      } else {
        await removeVaultItemFromList(item.itemID)
      }
    }
    // todo: error message??
  }

  function isThumbableType(extension: string) {
    return constants.thumbableTypes.some((t) => t == extension)
  }

  function setDraggingVaultItem(item: IVaultItem | null) {
    state.draggingVaultItem = item
  }

  function setHoveringOverVaultItem(item: IVaultItem | null) {
    state.hoveringOverVaultItem = item
  }

  function setVaultItems(items: IVaultItem[]) {
    state.vaultItems = items
  }

  function getVaultItemFromListByItemID(itemID: number) {
    return state.vaultItems.find((v) => v.itemID == itemID)
  }

  function removeVaultItemFromList(itemID: number) {
    state.vaultItems = state.vaultItems.filter((i) => i.itemID != itemID)
  }

  function removeVaultItemsFromList(itemIds: number[]) {
    state.vaultItems = state.vaultItems.filter(
      (i) => !itemIds.includes(i.itemID)
    )
  }

  function setEditingItem(editing: boolean) {
    state.editingItem = editing
  }

  function getCurrentExpireTimeInTicks(linkExpireInDays: number): number {
    // moment object returns current date in milliseconds
    const TICKS_PER_MILLISECOND = 10000
    // js ticks start at 01/01/1970, c# ticks start at 01/01/0001 -- this offset is added to get accurate utc timestamp in c#
    const JS_DATE_TO_C_SHARP_TICKS_OFFSET = 621355968000000000

    // when never is selected on the mobile app the expire time is actually 5 years (see: TPXF/Pages/Vault)
    const expireTimeInUTCMoment =
      linkExpireInDays > 0
        ? moment().utc().add(linkExpireInDays, 'd')
        : moment().utc().add(5, 'y')

    // this works !! but, beatodo: look into why i had to convert to JS Date cuz i forgor the reason
    return (
      expireTimeInUTCMoment.toDate().getTime() * TICKS_PER_MILLISECOND +
      JS_DATE_TO_C_SHARP_TICKS_OFFSET
    )
  }

  function handleThumbnailGeneratedNotification(itemId: number) {
    updateThumbnailStatusForItem(itemId)
    updateThumbnailStatusForItem(itemId, VaultThumbnailStatus.Generated)
  }

  function fileNameIncludesRestrictedChars(
    fileName: string,
    showError?: boolean
  ) {
    const commonStore = useCommonStore()

    const _char = commonStore.appSettings?.attachmentRestrictedFilenameChars
      ? [...commonStore.appSettings.attachmentRestrictedFilenameChars]
      : []
    for (let i = 0; i < _char.length; i++) {
      if (fileName.includes(_char[i].trim())) {
        if (showError) {
          showUploadErrorModal(
            `One or more of your files has an invalid file name. File names should not contain any of the following characters: ${commonStore.appSettings?.attachmentRestrictedFilenameChars}`
          )
        }
        return true
      }
    }

    return false
  }

  function isFileExtensionInvalid(fileName: string) {
    const commonStore = useCommonStore()

    const restrictedExtensions = commonStore.appSettings
      ?.attachmentRestrictedFileExtensions
      ? commonStore.appSettings.attachmentRestrictedFileExtensions.split(',')
      : []

    for (let i = 0; i < restrictedExtensions.length; i++) {
      if (fileName.includes(restrictedExtensions[i].trim())) {
        return true
      }
    }
    return false
  }

  // confirm delete modal
  function confirmDeleteAll() {
    const { closeModal, generateModal } = useModals()

    const uploadString = i18n.global.t('vault.emptyTrashModal.content')
    const loading = ref<boolean>(false)

    const el = generateModal({
      default: {
        headerText: i18n.global.t('vault.emptyTrashModal.header'),
        contentText: uploadString,
        footerButtonLabel: i18n.global.t('vault.emptyTrashModal.emptyButton')
      },
      config: {
        footerButtonLoading: loading,
        showHeader: true,
        showFooter: true,
        showCloseButton: true,
        showSecondaryCTA: true,
        secondaryCTALabel: i18n.global.t('vault.emptyTrashModal.cancelButton'),
        footerStyle: 'flex-reverse gap-3',
        closeOnLoadEnd: true,
        closeOnConfirm: false
      },
      callback: {
        confirmFn: async () => {
          loading.value = true
          await deleteAll()
          handleVaultItemsUpdate([])
          await updateVaultQuota()
          loading.value = false
        },
        secondaryFn: () => {
          closeModal(el)
        }
      }
    })
  }

  function setPageNumber(number: number) {
    state.pageNumber = number
  }
  //#endregion vault feature

  //#region DownloadController
  async function fetchDownloadUrl(
    vaultItemID: number
  ): Promise<string | undefined> {
    const endpoint = `${downloadControllerBaseUrl}/getdownloadurl`

    try {
      const result = await httpClient.get(endpoint, {
        params: { vaultItemID }
      })
      return result.data
    } catch (e) {
      ErrorHelper.handleError(e, 'fetchDownloadUrl')
    }
  }
  async function fetchDownloadUrls(
    vaultItemIds: number[]
  ): Promise<string[] | undefined> {
    const endpoint = `${downloadControllerBaseUrl}/getdownloadurls`

    try {
      const result = await httpClient.post(endpoint, vaultItemIds)

      return result.data
    } catch (e) {
      ErrorHelper.handleError(e, 'fetchDownloadUrls')
    }
  }
  //#endregion DownloadController

  //#region FileController
  async function filePost(p: IFileParam) {
    const endpoint = fileControllerBaseUrl

    try {
      return await httpClient.post(endpoint, p)
    } catch (e) {
      if (e instanceof AxiosError && e.response?.status == 400) {
        ErrorHelper.handleError(e, 'filePost', true, e.response.data.Message)
      } else {
        ErrorHelper.handleError(e, 'filePost')
      }
    }
  }

  async function fetchFileItems(p: IGetVaultItemsParam) {
    let endpoint = `${fileControllerBaseUrl}/getFileItems?PageNumber=${p.pageNumber}&PageSize=${p.pageSize}`
    if (p.parentItemID) endpoint += `&ParentItemID=${p.parentItemID}`
    if (p.sortBy) endpoint += `&SortBy=${p.sortBy}`
    //if (p.filterBy) endpoint += `&FilterBy=${p.filterBy}`

    try {
      return await httpClient.get(endpoint)
    } catch (e) {
      ErrorHelper.handleError(e, 'fetchFileItems')
    }
  }

  async function fileTrash(itemID: number) {
    const endpoint = `${fileControllerBaseUrl}/trash?itemID=${itemID}`

    try {
      return await httpClient.put(endpoint)
    } catch (e) {
      ErrorHelper.handleError(e, 'fileTrash')
    }
  }

  async function fileRestore(itemID: number) {
    const endpoint = `${fileControllerBaseUrl}/restore?itemId=${itemID}`

    try {
      return await httpClient.put(endpoint)
    } catch (e) {
      ErrorHelper.handleError(e, 'fileRestore')
    }
  }

  async function fileDelete(itemID: number) {
    const endpoint = `${fileControllerBaseUrl}/delete?itemId=${itemID}`

    try {
      return await httpClient.delete(endpoint)
    } catch (e) {
      ErrorHelper.handleError(e, 'fileDelete')
    }
  }

  async function trashOrRestoreVaultItems(p: ITrashOrRestoreVaultItemsParam) {
    const endpoint = `${vaultItemControllerBaseUrl}/trashOrRestoreItems`

    try {
      return await httpClient.put(endpoint, p)
    } catch (e) {
      ErrorHelper.handleError(e, 'trashOrRestoreVaultItems')
    }
  }

  async function deleteItems(itemIds: number[]) {
    const endpoint = `${vaultItemControllerBaseUrl}/deleteItems`

    try {
      return await httpClient.post(endpoint, itemIds)
    } catch (e) {
      ErrorHelper.handleError(e, 'deleteItems')
    }
  }

  async function getOrCreateThumbnailSasUrl(
    itemID: number,
    width?: number,
    height?: number
  ) {
    let endpoint = `${fileControllerBaseUrl}/getOrCreateThumbnailSasUrl?itemId=${itemID}`
    if (width) endpoint += `&width=${width}`
    if (height) endpoint += `&height=${height}`

    try {
      return await httpClient.get(endpoint)
    } catch (e) {
      ErrorHelper.handleError(e, 'getOrCreateThumbnailSasUrl')
    }
  }
  //#endregion FileController

  //#region FolderController
  async function folderPost(
    p: INewFolderParam
  ): Promise<AxiosResponse<INewFolderResponse, unknown> | undefined> {
    const endpoint = folderControllerBaseUrl

    try {
      return await httpClient.post(endpoint, p)
    } catch (e) {
      ErrorHelper.handleError(e, 'folderPost')
    }
  }

  async function fetchSubFolders(itemId?: number, bIncludeTrashed = false) {
    const endpoint = `${folderControllerBaseUrl}/getsubfolders`

    try {
      return await httpClient.get(endpoint, {
        params: { itemId, bIncludeTrashed }
      })
    } catch (e) {
      ErrorHelper.handleError(e, 'fetchSubFolders')
    }
  }

  async function getAllFolders() {
    const endpoint = `${folderControllerBaseUrl}/getAllFolders`

    try {
      return await httpClient.get(endpoint)
    } catch (e) {
      ErrorHelper.handleError(e, 'getAllFolders')
    }
  }

  async function folderTrash(itemId: number) {
    const endpoint = `${folderControllerBaseUrl}/trash?itemId=${itemId}`

    try {
      return await httpClient.put(endpoint)
    } catch (e) {
      ErrorHelper.handleError(e, 'folderTrash')
    }
  }

  async function folderRestore(itemId: number) {
    const endpoint = `${folderControllerBaseUrl}/restore?itemId=${itemId}`

    try {
      return await httpClient.put(endpoint)
    } catch (e) {
      ErrorHelper.handleError(e, 'folderRestore')
    }
  }

  async function folderDelete(itemId: number) {
    const endpoint = `${folderControllerBaseUrl}/delete?itemId=${itemId}`

    try {
      return await httpClient.delete(endpoint)
    } catch (e) {
      ErrorHelper.handleError(e, 'folderDelete')
    }
  }

  // FOLDER HELPERS
  async function updateFolderTree() {
    const result = await getAllFolders()
    if (result?.data) {
      const tree: IVaultFolder[] = []

      buildTree(tree, result.data, null)

      await setTreeData(tree)
    }
  }

  function buildTree(
    treeObject: IVaultFolder[],
    sourceArray: IVaultFolder[],
    level: number | undefined | null
  ) {
    const nodes = sourceArray.filter((item) => item.parentItemID == level)

    for (const node of nodes) {
      node.children = []

      buildTree(node.children, sourceArray, node.itemID)

      treeObject.push(node)
    }
  }

  function findFolderPath(
    sourceTree: IVaultFolder[],
    targetVaultItem: IVaultItem
  ): Array<IVaultFolder> | null {
    const targetID = targetVaultItem.parentItemID

    for (const node in sourceTree) {
      if (sourceTree[node].itemID === targetID) {
        return [sourceTree[node]]
      }

      if (sourceTree[node].children) {
        const leaf = findFolderPath(
          sourceTree[node].children as IVaultFolder[],
          targetVaultItem
        )
        if (leaf) return [sourceTree[node], ...leaf]
      }
    }

    return null
  }

  function updateBreadcrumbs() {
    const commonStore = useCommonStore()
    const { setBreadcrumbs } = commonStore
    if (state.treeData && state.currentFolder) {
      const breadcrumbs = findFolderPath(
        state.treeData,
        state.currentFolder
      )?.map((folder) => ({
        crumbName: folder.name,
        breadcrumbData: { ...folder, isFolder: true }
      }))
      if (breadcrumbs) {
        setBreadcrumbs([
          { crumbName: 'Vault File Storage' },
          ...(breadcrumbs as IBreadcrumbEvent[]),
          {
            crumbName: state.currentFolder.name,
            breadcrumbData: state.currentFolder
          }
        ])
      } else if (
        commonStore.isMobileWidth &&
        state.selectedItemsList?.length == 1
      ) {
        setBreadcrumbs([
          { crumbName: 'Vault File Storage' },
          {
            crumbName: state.currentFolder.name,
            breadcrumbData: state.currentFolder
          },
          {
            crumbName: state.selectedItemsList[0].name,
            breadcrumbData: state.selectedItemsList[0]
          }
        ])
      } else {
        setBreadcrumbs([
          { crumbName: 'Vault File Storage' },
          {
            crumbName: state.currentFolder.name,
            breadcrumbData: state.currentFolder
          }
        ])
      }
    } else {
      setBreadcrumbs([{ crumbName: 'Vault File Storage' }])
    }
  }
  //#endregion FolderController

  //#region Upload
  async function startUploadQueue(files: File[], parentItemId?: number) {
    let index =
      Array.isArray(state.uploads) && state.uploads.length > 0
        ? state.uploads[state.uploads.length - 1].priority + 1
        : 0
    const toBeUploaded: IVaultUpload[] = []
    let totalSize = 0

    await updateVaultQuota()

    for (let i = 0; i < files.length; i++) {
      totalSize += files[i].size

      if (
        state.quotaAccountInfo?.quotaMaxBytes &&
        totalSize + state.quotaAccountInfo?.quotaBytesUsed >
          state.quotaAccountInfo?.quotaMaxBytes
      ) {
        showUploadErrorModal(
          "Your Vault doesn't have enough space to upload these files."
        )
        return
      }

      if (fileNameIncludesRestrictedChars(files[i].name, true)) {
        return
      }

      if (isFileExtensionInvalid(files[i].name)) {
        showUploadErrorModal(
          'The file could not be added because this file type is not accepted.'
        )
        return
      }

      // set file, parentItemId, status
      const queueFile: IVaultUpload = {
        file: files[i],
        parentItemID: parentItemId,
        status: constants.vaultUploadStatus.queued,
        priority: index
      }
      // add files to array
      toBeUploaded.push(queueFile)

      index++
    }

    if (Array.isArray(state.uploads)) {
      state.uploads = [...state.uploads, ...toBeUploaded]
    } else {
      state.uploads = []
      state.uploads = [...state.uploads, ...toBeUploaded]
    }

    // if there is no currently uploading item or item with highest priority
    if (!getCurrentUpload.value && getHighestPriorityUpload.value) {
      queueNextUpload()
    }
  }

  async function startUploadProcess(item: IVaultUpload) {
    if (item.file) {
      // 1. get Sas token for Azure Blob storage for the file
      const _tokenResponse = await uploadTokenPost(item.file)

      if (_tokenResponse == undefined) {
        // Fail upload
        item.status = constants.vaultUploadStatus.failed
        updateVaultUploadItemByPriority(item)
        return
      }
      if (
        state.quotaAccountInfo?.quotaMaxBytes &&
        item.file.size + state.quotaAccountInfo?.quotaBytesUsed >
          state.quotaAccountInfo?.quotaMaxBytes
      ) {
        showUploadErrorModal(
          "Your Vault doesn't have enough space to upload these files."
        )
      }

      let _fileUpload: IVaultUpload = {
        uploadSasToken: _tokenResponse?.tokenUri,
        blobName: _tokenResponse?.blobName,
        file: item.file,
        parentItemID: item.parentItemID,
        status: constants.vaultUploadStatus.converting,
        lastDataUploadedIndex: 0,
        blockData: [],
        blockIds: [],
        priority: item.priority
      }
      updateVaultUploadItemByPriority(_fileUpload)

      // 2. break file into block blobs
      _fileUpload = await convertFileToBlocks(_fileUpload)

      // 3. start uploading
      _fileUpload.status = constants.vaultUploadStatus.uploading
      updateVaultUploadItemByPriority(_fileUpload)
      await doBlockUpload(_fileUpload)
    }
  }

  async function doBlockUpload(item: IVaultUpload): Promise<IVaultUpload> {
    // start the block upload
    if (
      item.uploadSasToken &&
      item.file &&
      item.status === constants.vaultUploadStatus.uploading &&
      item.blockIds &&
      item?.blockIds?.length > 0
    ) {
      for (
        let i = item.lastDataUploadedIndex || 0;
        i < item.blockIds.length;
        i++
      ) {
        if (
          item.uploadSasToken &&
          item.blockData &&
          !hasUploadBeenPaused(item)
        ) {
          const result = await uploadBlock(
            item.uploadSasToken,
            item.blockIds[i],
            item.blockData[i]
          )
          if (result) {
            item.lastDataUploadedIndex = i
            updateVaultUploadItemLastDataUploadedIndex(item.priority, i)
          } else {
            // block upload failed, need to stop the upload and alert the user
            item.status = constants.vaultUploadStatus.failed
            updateVaultUploadItemByPriority(item)
            queueNextUpload()
            break
          }
        }
      }
      const response = await commitBlockList(
        item.uploadSasToken,
        item.blockIds,
        item.file
      )
      if (response) {
        const filePostResponse = await filePost(
          new FileParam({
            parentItemID: item.parentItemID,
            name: item.file.name,
            description: '',
            fileSize: item.file.size,
            mimeType: item.file.type,
            blobName: item.blobName
          })
        )
        if (filePostResponse == undefined) {
          item.status = constants.vaultUploadStatus.failed
        } else {
          item.status = constants.vaultUploadStatus.completed
          item.itemID = filePostResponse.data.itemID
        }
        updateVaultUploadItemByPriority(item)
        finishUpload(item)
      }
    }
    return item
  }

  async function resumeUpload(priority: number) {
    const _index = state.uploads.findIndex((u) => u.priority == priority)

    if (_index >= 0) {
      // if file has started uploading, resume
      if ((state.uploads[_index].uploadSasToken?.length ?? 0) > 0) {
        state.uploads[_index].status = constants.vaultUploadStatus.uploading
        const item = await doBlockUpload(state.uploads[_index])
        finishUpload(item)
        // if there is no current file uploading AND paused file is top of priority list
      } else if (
        !getCurrentUpload.value &&
        getQueuedUploads.value.findIndex((e) => {
          return (
            e.priority < priority &&
            e.status != constants.vaultUploadStatus.completed &&
            e.status != constants.vaultUploadStatus.failed
          )
        }) == -1
      ) {
        state.uploads[_index].status = constants.vaultUploadStatus.queued
        queueNextUpload()
      } else {
        // only update status if still in queue
        state.uploads[_index].status = constants.vaultUploadStatus.queued
      }
    }
  }

  // this will stop block upload from looping again
  async function pauseUpload(priority: number) {
    const _index = state.uploads.findIndex((u) => u.priority == priority)

    if (_index >= 0 && state.uploads[_index]) {
      state.uploads[_index].status = constants.vaultUploadStatus.paused
    }
  }

  // TODO: add method to send empty blocks to azure
  async function cancelUpload(priority: number, isUploading: boolean) {
    const _index = state.uploads.findIndex((u) => u.priority == priority)

    // if upload was paused befored cancelling and has started uploading, pause next
    if (
      isUploading &&
      state.uploads[_index].status == constants.vaultUploadStatus.paused
    ) {
      state.uploads[_index].status = constants.vaultUploadStatus.failed
      pauseNextUpload()
    } else {
      state.uploads[_index].status = constants.vaultUploadStatus.failed
      queueNextUpload()
    }

    // remove canceled from list
    if (_index >= 0) {
      state.uploads.splice(_index, 1)
    }
  }

  async function finishUpload(item: IVaultUpload) {
    track(br.eventTypes.appAction, {
      feature: br.appActionFeature.vault,
      name: br.appActionEventNames.itemUploaded
    })

    // update current list if ur on it
    if (
      item.parentItemID == state.currentFolder?.itemID ||
      (!item.parentItemID && !state.currentFolder)
    ) {
      await updateCurrentFolder()
    }

    await updateVaultQuota()
    queueNextUpload()
  }

  function queueNextUpload() {
    // start next upload
    if (!getCurrentUpload.value && getQueuedUploads.value.length > 0) {
      startUploadProcess(getQueuedUploads.value[0])
    }
  }

  function pauseNextUpload() {
    // pause next upload
    if (
      !getCurrentUpload.value &&
      getQueuedUploads.value.length > 0 &&
      !getPausedUploads.value[0]
    ) {
      pauseUpload(getQueuedUploads.value[0].priority)
    }
  }

  function removeUploadFromList(priority: number) {
    const _index = state.uploads.findIndex((u) => u.priority == priority)

    // remove canceled from list
    if (_index >= 0) {
      state.uploads.splice(_index, 1)
    }
  }

  async function uploadTokenPost(file: File) {
    const endpoint = uploadTokenControllerBaseUrl
    const abortController = new AbortController()
    try {
      const result = await httpClient.post(endpoint, null, {
        headers: {
          FileSizeBytes: file.size
        },
        signal: abortController.signal
      })
      if (result) {
        const tokenResponse: IUploadTokenResponse = result.data
        return tokenResponse
      }
    } catch (e: any) {
      if (e.response.data.errorCode == 4) {
        ErrorHelper.handleError(
          e,
          'uploadTokenPost',
          true,
          'Not enough space in Vault.'
        )
      } else {
        ErrorHelper.handleError(e, 'uploadTokenPost')
      }
    }
  }

  async function uploadToAzure(
    tokenResponse: IUploadTokenResponse,
    file: File
  ) {
    if (!file) {
      return
    }
    try {
      //vaultUploads.value.push()
      const sasUrl = tokenResponse.tokenUri
      const config: AxiosRequestConfig = {
        headers: {
          'Content-Type': file.type,
          'x-ms-blob-type': 'BlockBlob'
        },
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          // uploadProgress.value = parseInt(
          //   Math.round(
          //     (progressEvent.loaded * 100) / progressEvent.total
          //   ).toString()
          // )
        }
      }

      try {
        const response = await axios.put(sasUrl, file, config)
        // call API to database the vault item
        if (response) {
          filePost(
            new FileParam({
              parentItemID: getCurrentFolderId.value || undefined,
              blobName: tokenResponse.blobName,
              name: file.name,
              description: '',
              fileSize: file.size,
              mimeType: file.type
            })
          )
        }
      } catch (error) {
        console.error('Error during file upload:', error)
      }
    } catch (error) {
      console.error('Error uploading file', error)
    }
  }

  async function convertFileToBlocks(upload: IVaultUpload) {
    //TODO: make values constants and figure out a block size strategy for different sized files
    const blockSize = 1024 * 1024 // For example, 1 MB per block

    const _fileUpload: IVaultUpload = {
      ...upload,
      lastDataUploadedIndex: 0,
      blockData: [],
      blockIds: []
    }
    if (upload.file) {
      for (
        let start = 0, index = 0;
        start < upload.file.size;
        start += blockSize, index++
      ) {
        const end = Math.min(start + blockSize, upload.file.size)
        const _blockData = upload.file.slice(start, end)
        const _blockId = generateBlockId(index)
        _fileUpload.blockData?.push(_blockData)
        _fileUpload.blockIds?.push(_blockId)
      }
    }

    return _fileUpload
  }

  async function uploadToAzureInBLocks(
    tokenResponse: IUploadTokenResponse,
    file: File
  ) {
    const blockSize = 1024 * 1024 // For example, 1 MB per block
    const blockIds = []
    const blockData = []
    for (
      let start = 0, index = 0;
      start < file.size;
      start += blockSize, index++
    ) {
      const end = Math.min(start + blockSize, file.size)
      const _blockData = file.slice(start, end)
      const blockId = generateBlockId(index)
      blockData.push(_blockData)
      // await uploadBlock(tokenResponse.tokenUri, blockId, blockData)
      blockIds.push(blockId)
    }
    //commitBlockList(tokenResponse.tokenUri, blockIds, tokenResponse, file)
  }

  async function uploadBlock(sasUrl: string, blockId: string, data: Blob) {
    const blockSasUrl = `${sasUrl}&comp=block&blockid=${blockId}`

    try {
      await axios.put(blockSasUrl, data, {
        headers: {
          'x-ms-blob-type': 'BlockBlob',
          'Content-Type': 'application/octet-stream'
        }
      })

      return true
    } catch (error) {
      console.error('Error uploading block:', error)
      return false
    }
  }

  function generateBlockId(index: number): string {
    const padding = '000000000000' // Ensure the block ID string length is consistent
    const id = padStart(`${index}`, padding.length, '0')
    return btoa(id).replace(/=+$/, '') // Remove any trailing '=' characters
  }

  function padStart(
    str: string,
    targetLength: number,
    padString: string
  ): string {
    if (str.length >= targetLength) {
      return str
    }
    const padding = Array(targetLength - str.length + 1).join(padString)
    return padding + str
  }

  async function commitBlockList(
    sasUrl: string,
    blockIds: string[],
    file: File
  ) {
    const blockListXml = `<?xml version="1.0" encoding="utf-8"?><BlockList>${blockIds
      .map((id) => `<Latest>${id}</Latest>`)
      .join('')}</BlockList>`
    const commitSasUrl = `${sasUrl}&comp=blocklist`

    try {
      await axios.put(commitSasUrl, blockListXml, {
        headers: {
          'Content-Type': 'application/xml'
        }
      })
      return true
    } catch (error) {
      console.error('Error committing block list:', error)
      throw error
    }
  }

  function getVaultUpload(item: IVaultUpload) {
    if (Array.isArray(state.uploads)) {
      return state.uploads.find((v) => v.file?.name == item.file?.name)
    }
    return null
  }

  function getVaultUploadByPriority(item: IVaultUpload) {
    if (Array.isArray(state.uploads)) {
      return state.uploads.find((v) => v.priority == item.priority)
    }
    return null
  }

  function hasUploadBeenPaused(item: IVaultUpload) {
    const _upload = getVaultUploadByPriority(item)
    if (_upload) {
      return _upload.status == constants.vaultUploadStatus.paused
    } else {
      //TODO: What should we do if we don't find the item
      return true
    }
  }

  function updateVaultUploadItemLastDataUploadedIndex(
    priority: number,
    lastIndex: number
  ) {
    if (Array.isArray(state.uploads)) {
      const _index = state.uploads.findIndex((u) => u.priority == priority)
      if (_index !== -1) {
        state.uploads[_index] = {
          ...state.uploads[_index],
          lastDataUploadedIndex: lastIndex
        }
      }
    }
  }

  function updateVaultUploadItemByPriority(item: IVaultUpload) {
    if (Array.isArray(state.uploads)) {
      // index and length will be offset if the user has cancelled an upload in the queue
      const _index = state.uploads.findIndex((v) => v.priority == item.priority)
      if (_index !== -1) {
        state.uploads[_index] = { ...item }
      }
    }
  }

  const getCurrentUpload = computed(() => {
    return state.uploads.filter((u) => {
      return (
        u.uploadSasToken &&
        u.status != constants.vaultUploadStatus.completed &&
        u.status != constants.vaultUploadStatus.failed
      )
    })[0]
  })

  const getHighestPriorityUpload = computed(() => {
    return state.uploads.filter((u) => {
      return (
        u.status != constants.vaultUploadStatus.completed &&
        u.status != constants.vaultUploadStatus.failed
      )
    })[0]
  })

  const getQueuedUploads = computed(() => {
    return state.uploads.filter((f) => {
      return f.status == constants.vaultUploadStatus.queued
    })
  })

  const getPausedUploads = computed(() => {
    return state.uploads.filter((f) => {
      return f.status == constants.vaultUploadStatus.paused
    })
  })

  const getCompletedUploads = computed(() => {
    return state.uploads.filter((f) => {
      f.status == constants.vaultUploadStatus.completed
    })
  })

  // upload modals
  function cancelAllUploads() {
    const uploadString = `<span class="place-center">Are you sure you want to stop uploading?</span>`

    if (
      getCurrentUpload.value ||
      getQueuedUploads.value.length > 0 ||
      getPausedUploads.value.length > 0
    ) {
      const { createSlot, closeModal, generateModal, HTMLtoComponent } =
        useModals()
      const el = generateModal({
        default: {
          headerText: 'Cancel upload',
          footerButtonLabel: 'Confirm'
        },
        slot: {
          content: createSlot('content', HTMLtoComponent(uploadString)).content
        },
        config: {
          showHeader: true,
          showBody: true,
          showFooter: true,
          addContentPadding: true,
          closeOnConfirm: true,
          showCloseButton: true,
          secondaryCTALabel: 'Cancel'
        },
        callback: {
          confirmFn: () => {
            state.uploads = []
          },
          secondaryFn: () => {
            closeModal(el)
          }
        }
      })
    } else {
      state.uploads = []
    }
  }

  function showUploadErrorModal(errorText: string) {
    const errorString = `<span class="place-center">${errorText}</span>`

    const { createSlot, generateModal, HTMLtoComponent } = useModals()

    generateModal({
      default: {
        headerText: 'Upload Error',
        footerButtonLabel: 'Okay'
      },
      slot: {
        content: createSlot('content', HTMLtoComponent(errorString)).content
      },
      config: {
        showHeader: true,
        showBody: true,
        showFooter: true,
        addContentPadding: true,
        closeOnConfirm: true,
        showCloseButton: true
      }
    })
  }

  //#endregion UploadTokenController

  //#region VaultAccountController
  async function vaultAccountPost() {
    const endpoint = vaultAccountControllerBaseUrl

    try {
      return await httpClient.post(endpoint)
    } catch (e) {
      ErrorHelper.handleError(e, 'vaultAccountPost')
    }
  }

  async function fetchQuotaInfo() {
    const endpoint = `${vaultAccountControllerBaseUrl}/getQuotaInfo`

    try {
      return await httpClient.get(endpoint)
    } catch (e) {
      ErrorHelper.handleError(e, 'getQuotaInfo')
    }
  }

  async function updateVaultQuota() {
    const response = await fetchQuotaInfo()
    if (response?.status == HttpStatusCodes.OK) {
      state.quotaAccountInfo = response.data
    }
  }
  //#endregion VaultAccountController

  //#region VaultAttachmentController
  async function vaultAttachmentPost(p: IVaultAttachmentParam) {
    const endpoint = vaultAttachmentControllerBaseUrl

    try {
      return await httpClient.post(endpoint, p)
    } catch (e) {
      ErrorHelper.handleError(e, 'vaultAttachmentPost')
    }
  }
  //#endregion VaultAttachmentController

  //#region VaultItemController
  async function fetchFileProviderItems(parentItemID?: number) {
    const endpoint = `${vaultItemControllerBaseUrl}/getfileprovideritems`

    try {
      return await httpClient.get(endpoint, {
        params: { parentItemID }
      })
    } catch (e) {
      ErrorHelper.handleError(e, 'fetchFileProviderItems')
    }
  }

  async function fetchTrashedItems() {
    const endpoint = `${vaultItemControllerBaseUrl}/gettrasheditems`

    try {
      return await httpClient.get(endpoint)
    } catch (e) {
      ErrorHelper.handleError(e, 'fetchTrashedItems')
    }
  }

  async function search(searchTerm: string, searchTrash: boolean) {
    const endpoint = `${vaultItemControllerBaseUrl}/search`

    try {
      return await httpClient.get(endpoint, {
        params: { searchTerm, searchTrash }
      })
    } catch (e) {
      ErrorHelper.handleError(e, 'search')
    }
  }

  async function searchFilterSortAll(p: ISearchFilterSortVaultItemsParam) {
    const endpoint = `${vaultItemControllerBaseUrl}/searchFilterSortAll`
    // set range end to today if not passed
    if (p.rangeStart && !p.rangeEnd) {
      p.rangeEnd = moment().utc().format('YYYY-MM-DDTHH:mm:ss')
    }

    try {
      return await httpClient.get(endpoint, {
        params: p,
        headers: {
          'api-version': '2'
        }
      })
    } catch (e) {
      ErrorHelper.handleError(e, 'searchFilterSortAll')
    }
  }

  async function searchFilterSortDirectory(
    p: ISearchFilterSortVaultItemsParam
  ) {
    const endpoint = `${vaultItemControllerBaseUrl}/searchFilterSortDirectory`

    try {
      return await httpClient.get(endpoint, {
        params: p,
        headers: {
          'api-version': '2'
        }
      })
    } catch (e) {
      ErrorHelper.handleError(e, 'searchFilterSortDirectory')
    }
  }

  async function fetchPreviewInfo(itemID: number) {
    const endpoint = `${vaultItemControllerBaseUrl}/getpreviewinfo`

    try {
      return await httpClient.get(endpoint, {
        params: { itemID }
      })
    } catch (e) {
      ErrorHelper.handleError(e, 'fetchPreviewInfo')
    }
  }

  async function starVaultItem(itemID: number, isStarred: boolean) {
    const endpoint = `${vaultItemControllerBaseUrl}/star?itemID=${itemID}&isStarred=${isStarred}`

    try {
      return await httpClient.put(endpoint)
    } catch (e) {
      ErrorHelper.handleError(e, 'starVaultItem')
    }
  }

  async function starVaultItems(p: IStarVaultItemsParam) {
    const endpoint = `${vaultItemControllerBaseUrl}/starItems`

    try {
      return await httpClient.put(endpoint, p)
    } catch (e) {
      ErrorHelper.handleError(e, 'moveVaultItems')
    }
  }

  async function renameVaultItem(itemID: number, newName: string) {
    const endpoint = `${vaultItemControllerBaseUrl}/rename?itemID=${itemID}&newName=${newName}`
    try {
      return await httpClient.put(endpoint)
    } catch (e) {
      ErrorHelper.handleError(e, 'renameVaultItem')
    }
  }

  async function editDescriptionVaultItem(
    itemID: number,
    newDescription: string
  ) {
    const endpoint = `${vaultItemControllerBaseUrl}/description?itemID=${itemID}&newDescription=${newDescription}`
    try {
      return await httpClient.put(endpoint)
    } catch (e) {
      ErrorHelper.handleError(e, 'editDescriptionVaultItem')
    }
  }

  async function moveVaultItem(itemID: number, parentItemID?: number) {
    const undoItem = state.vaultItems.find((i) => i.itemID == itemID)

    let endpoint = `${vaultItemControllerBaseUrl}/move?itemID=${itemID}`
    if (parentItemID) endpoint += `&parentItemID=${parentItemID}`

    try {
      return await httpClient.put(endpoint)
    } catch (e) {
      ErrorHelper.handleError(e, 'moveVaultItem')
    } finally {
      if (undoItem) {
        state.undoItems = [...(state.undoItems ?? []), undoItem]
      }
    }
  }

  async function moveVaultItems(p: IMoveVaultItemsParam) {
    const undo = state.vaultItems.filter((vi) => p.itemIds.includes(vi.itemID))

    const endpoint = `${vaultItemControllerBaseUrl}/moveItems`

    try {
      return await httpClient.put(endpoint, p)
    } catch (e) {
      ErrorHelper.handleError(e, 'moveVaultItems')
    } finally {
      if (undo) {
        state.undoItems = [...(state.undoItems ?? []), ...undo]
      }
    }
  }

  async function undoMove() {
    if (!state.undoItems?.length) {
      return
    }

    const endpoint = `${vaultItemControllerBaseUrl}/moveItems`

    const param = {
      itemIds: state.undoItems.map((ui) => ui.itemID),
      parentItemId: state.undoItems?.[0]?.parentItemID
    } as IMoveVaultItemsParam

    try {
      return await httpClient.put(endpoint, param)
    } catch (e) {
      ErrorHelper.handleError(e, 'moveVaultItems')
    }
  }

  async function onDrop(
    dragEvent: DragEvent,
    destinationItem?: IVaultItem,
    trash = false
  ) {
    if (destinationItem && !destinationItem.isFolder) {
      return
    }

    if (!dragEvent.dataTransfer) {
      return
    }

    const types = dragEvent.dataTransfer.types

    //intended drops only have one entry in types:
    //items - 'text/plain'; action - move
    //files - 'Files'; action - upload
    if (
      types.length != 1 &&
      !(types.includes('application/x-moz-file') && types.length == 2)
    ) {
      return
    }

    //need to check if dataTransfer has text, or files
    if (types.includes('text/plain')) {
      //need to make sure item isn't dragged into its parent folder
      if (!destinationItem && !state.currentFolder && trash == false) {
        return
      }

      if (
        destinationItem &&
        destinationItem.itemID == state.currentFolder?.itemID
      ) {
        return
      }

      if (
        destinationItem &&
        state.selectedItemsList
          ?.map((si) => si.itemID)
          .includes(destinationItem.itemID)
      ) {
        return
      }

      //multi-selected item drop will contain a ; delimited list of ids
      const itemIds = dragEvent.dataTransfer
        .getData('text')
        .split(';') //array of strings split on semicolon
        .map((i) => +i) //+ unary operator to convert to number
        .filter((s) => !Number.isNaN(s)) //get rid of the non-number entries

      //if the destination is contained within the selection
      if (destinationItem && itemIds.includes(destinationItem?.itemID)) {
        return
      }

      if (trash) {
        const { closeModal, generateModal } = useModals()
        const loading = ref<boolean>(false)

        const headerText =
          itemIds.length > 1
            ? i18n.global.t('vault.trashModal.headerItems')
            : state.selectedItemsList?.find((si) => si.itemID == itemIds[0])
                ?.isFolder
            ? i18n.global.t('vault.trashModal.headerFolder')
            : i18n.global.t('vault.trashModal.headerFile')

        const contentText =
          itemIds.length > 1
            ? i18n.global.t('vault.trashModal.contentItems', {
                count: itemIds.length
              })
            : i18n.global.t('vault.trashModal.contentFileOrFolder', {
                name: state.selectedItemsList?.find(
                  (si) => si.itemID == itemIds[0]
                )?.name
              })

        const el = generateModal({
          default: {
            headerText: headerText,
            contentText: contentText,
            footerButtonLabel: i18n.global.t('vault.trashModal.confirmButton')
          },
          config: {
            footerButtonLoading: loading,
            showHeader: true,
            showFooter: true,
            showCloseButton: true,
            showSecondaryCTA: true,
            secondaryCTALabel: i18n.global.t('vault.trashModal.cancelButton'),
            footerStyle: 'flex-reverse gap-3',
            closeOnLoadEnd: true,
            closeOnConfirm: false
          },
          callback: {
            confirmFn: async () => {
              //then a list of items was dropped onto the trash quick access
              loading.value = true
              const success =
                (
                  await trashOrRestoreVaultItems({
                    itemIds: itemIds,
                    trash: trash
                  })
                )?.status == HttpStatusCodes.OK

              if (success) {
                removeVaultItemsFromList(itemIds)
                handleSelectedItemsListUpdate(null)
                updateFolderTree()
              }
              loading.value = false
            },
            secondaryFn: () => {
              closeModal(el)
            }
          }
        })

        return
      }

      // const firstItem = vaultItems.value.find((vi) => vi.itemID == itemIds[0])

      // if (firstItem?.isTrashed) {
      //   //then we know we're restoring a list of trashed items through a drop onto all items
      //   const success =
      //     (await trashOrRestoreVaultItems({ itemIds: itemIds, trash: false }))
      //       ?.status == HttpStatusCodes.OK

      //   if (success) {
      //     removeVaultItemsFromList(itemIds)
      //   }

      //   return
      // }

      //bulk move - can also be used for singular items
      const success =
        (
          await moveVaultItems({
            itemIds: itemIds,
            parentItemId: destinationItem?.itemID
          })
        )?.status == HttpStatusCodes.OK

      if (success) {
        removeVaultItemsFromList(itemIds)
      }
    } else if (types.includes('Files')) {
      //then a list of files was dropped, so upload
      const files = Array.from(dragEvent.dataTransfer.files)

      if (!files.length) {
        return
      }

      await startUploadQueue(files, destinationItem?.itemID)
    }
  }

  async function checkRestoreLocation(parentItemID: number) {
    const endpoint = `${vaultItemControllerBaseUrl}/checkrestorelocation`

    try {
      return await httpClient.get(endpoint, {
        params: { parentItemID }
      })
    } catch (e) {
      ErrorHelper.handleError(e, 'checkRestoreLocation')
    }
  }

  async function deleteAll() {
    const endpoint = `${vaultItemControllerBaseUrl}/deleteAll`

    try {
      return await httpClient.delete(endpoint)
    } catch (e) {
      ErrorHelper.handleError(e, 'deleteAll')
    }
  }
  //#endregion VaultItemController

  //#region VaultJournalAttachmentController
  async function vaultJournalAttachmentPost(p: IVaultJournalAttachmentParam) {
    const endpoint = vaultJournalAttachmentControllerBaseUrl

    try {
      return await httpClient.post(endpoint, p)
    } catch (e) {
      ErrorHelper.handleError(e, 'vaultJournalAttachmentPost')
    }
  }
  //#endregion VaultJournalAttachmentController

  //#region VaultShareController
  async function sendShareEmail(p: IShareEmailParam) {
    let endpoint = `${vaultShareControllerBaseUrl}/sendshareemail?Email=${p.email}&VaultItemID=${p.vaultItemID}&ExpiredWhenTicks=${p.expiresWhenTicks}`
    if (p.note) endpoint += `&Note=${p.note}`

    try {
      return await httpClient.post(endpoint, p)
    } catch (e) {
      ErrorHelper.handleError(e, 'sendShareEmail')
    }
  }

  async function getLink(p: IGetVaultShareLinkParam) {
    const endpoint = `${vaultShareControllerBaseUrl}/getlink?ItemID=${p.itemID}&ExpiresWhenTicks=${p.expiresWhenTicks}`

    try {
      return await httpClient.get(endpoint)
    } catch (e) {
      ErrorHelper.handleError(e, 'getLink')
    }
  }

  async function getSharedItems() {
    const endpoint = `${vaultShareControllerBaseUrl}/getshareditems`

    try {
      return await httpClient.get(endpoint)
    } catch (e) {
      ErrorHelper.handleError(e, 'getSharedItems')
    }
  }
  //#endregion VaultShareController

  //#region Downloads
  function startDownload(
    url: string,
    fileName: string,
    vaultItem?: IVaultItem
  ) {
    const id = Date.now().toString() // Generate a unique ID for the download
    //const workerUrl = new URL('./fileDownloader.js', import.meta.url)
    WorkerService.createWorker(id, fileDownloaderWorker)
    const options: RequestInit = {}
    const request: WorkerRequest = { url, options, id }

    WorkerService.postRequest(id, request, (response) => {
      if (response.success) {
        if (response.data.status === 'progress') {
          if (response.data.id === id) {
            const download = getDownloadFromList(id)
            if (download) {
              download.progress = response.data.progress
            }
          }
        } else if (response.data.status === 'completed') {
          const download = getDownloadFromList(id)
          if (download) {
            handleDownloadedFile(response.data.data, download.fileName)
            download.status = constants.vaultDownloadStatus.completed
          }
          WorkerService.terminateWorker(id)
        } else if (response.data.status === 'error') {
          const download = getDownloadFromList(id)
          if (download) {
            download.status = constants.vaultDownloadStatus.failed
          }
          WorkerService.terminateWorker(id)
        }
      } else {
        const download = getDownloadFromList(id)
        if (download) {
          download.status = constants.vaultDownloadStatus.failed
        }
      }
    })
    // const worker = new Worker(
    //   new URL('../workers/fileDownloader.ts', import.meta.url),
    //   { type: 'module' }
    // )

    // worker.postMessage({ action: 'download', url: url, id: id })
    // worker.onmessage = function (e) {
    //   if (e.data.status === 'progress') {
    //     if (e.data.id === id) {
    //       const download = getDownloadFromList(id)
    //       if (download) {
    //         download.progress = e.data.progress
    //       }
    //     }
    //   } else if (e.data.status === 'completed') {
    //     const download = getDownloadFromList(id)
    //     if (download) {
    //       handleDownloadedFile(e.data.data, download.fileName)
    //       download.status = constants.vaultDownloadStatus.completed
    //       download?.worker.terminate()
    //     }
    //   } else if (e.data.status === 'error') {
    //     const download = getDownloadFromList(id)
    //     if (download) {
    //       download.status = constants.vaultDownloadStatus.failed
    //       download?.worker.terminate()
    //     }
    //   }
    // }

    state.downloads.push({
      id,
      url,
      progress: 0,
      fileName,
      status: constants.vaultDownloadStatus.downloading,
      vaultItem: vaultItem
    })
  }

  function reDownload(existingFile: IVaultDownload) {
    //const workerUrl = new URL('../workers/fileDownloader.ts', import.meta.url)
    WorkerService.createWorker(existingFile.id, fileDownloaderWorker)
    const options: RequestInit = {}
    const request: WorkerRequest = {
      url: existingFile.url,
      options,
      id: existingFile.id
    }

    WorkerService.postRequest(existingFile.id, request, (response) => {
      if (response.success) {
        if (response.data.status === 'progress') {
          if (response.data.id === existingFile.id) {
            const download = getDownloadFromList(existingFile.id)
            if (download) {
              download.progress = response.data.progress
            }
          }
        } else if (response.data.status === 'completed') {
          const download = getDownloadFromList(existingFile.id)
          if (download) {
            handleDownloadedFile(response.data.data, download.fileName)
            download.status = constants.vaultDownloadStatus.completed
          }
          WorkerService.terminateWorker(existingFile.id)
        } else if (response.data.status === 'error') {
          const download = getDownloadFromList(existingFile.id)
          if (download) {
            download.status = constants.vaultDownloadStatus.failed
          }
          WorkerService.terminateWorker(existingFile.id)
        }
      } else {
        const download = getDownloadFromList(existingFile.id)
        if (download) {
          download.status = constants.vaultDownloadStatus.failed
        }
      }
    })
  }

  function handleDownloadedFile(blob: Blob, fileName: string) {
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  }

  function stopDownload(id: string) {
    const download = getDownloadFromList(id)
    if (download) {
      download.status = constants.vaultDownloadStatus.stopped
      WorkerService.terminateWorker(id)
    }
  }

  function removeDownloadFromList(id: string) {
    const downloadIndex = state.downloads.findIndex((d) => d.id === id)
    if (downloadIndex !== -1) {
      WorkerService.terminateWorker(id)
      state.downloads.splice(downloadIndex, 1)
    }
  }

  function getDownloadFromList(id: string) {
    return state.downloads.find((d) => d.id === id)
  }

  function removeAllDownloads() {
    state.downloads.forEach((d) => {
      if (d.status == constants.vaultDownloadStatus.downloading) {
        WorkerService.terminateWorker(d.id)
      }
    })
    state.downloads = []
  }

  const getInProgressDownloads = computed(() => {
    return state.downloads.filter(
      (d) => d.status == constants.vaultDownloadStatus.downloading
    )
  })

  // download modals
  function cancelAllDownloads() {
    const downloadString = `<span class="place-center">Some items are still downloading. Are you sure you want to stop all of them?</span>`

    if (getInProgressDownloads.value.length > 0) {
      const { createSlot, closeModal, generateModal, HTMLtoComponent } =
        useModals()
      const el = generateModal({
        default: {
          headerText: 'Cancel downloads',
          footerButtonLabel: 'Confirm'
        },
        slot: {
          content: createSlot('content', HTMLtoComponent(downloadString))
            .content
        },
        config: {
          showHeader: true,
          showBody: true,
          showFooter: true,
          addContentPadding: true,
          closeOnConfirm: true,
          showCloseButton: true,
          secondaryCTALabel: 'Cancel'
        },
        callback: {
          confirmFn: () => {
            removeAllDownloads()
          },
          secondaryFn: () => {
            closeModal(el)
          }
        }
      })
    } else {
      removeAllDownloads()
    }
  }

  //#endregion Downloads

  //#region Thumbnails
  async function startThumbnailFetch(itemID: number) {
    const id = `${Date.now().toString()}-${itemID}`
    addThumbnailWorkerId(id)

    WorkerService.createWorker(id, thumbnailFetcherWorker)

    const loginStore = useLoginStore()
    const authToken = loginStore.authToken

    const options: RequestInit = {
      headers: {
        'Content-Type': 'application/json',
        AID: 'Web',
        'App-Version': import.meta.env.VUE_APP_VERSION,
        'Trace-User': '0',
        Authorization: `Bearer ${authToken}`
      }
    }
    const url = `${fileControllerBaseUrl}/getAllThumbnailSasUrlsForItem?itemId=${itemID}`

    const workerRequest: WorkerRequest = {
      url,
      options,
      id
    }

    WorkerService.postRequest(id, workerRequest, (response) => {
      if (response.success) {
        switch (response.data.status) {
          case 'completed': {
            const fetch = getThumbnailFetchFromList(itemID)

            if (fetch) {
              handleFetchedThumbnail(response.data.data, fetch)
            }

            WorkerService.terminateWorker(id)
            break
          }
          case 'error': {
            const fetch = getThumbnailFetchFromList(itemID)

            if (fetch) fetch.urls = []

            WorkerService.terminateWorker(id)
            break
          }
          case 'unavailable': {
            //then the thumbnail needs to be generated
            const fetch = getThumbnailFetchFromList(itemID)
            if (fetch) fetch.urls = []

            WorkerService.terminateWorker(id)
            updateThumbnailStatusForItem(
              itemID,
              VaultThumbnailStatus.Generating
            )
            break
          }
        }

        return
      }

      const fetch = getThumbnailFetchFromList(itemID)
      if (fetch) fetch.urls = []
    })

    state.fetchedThumbnails.push({
      workerId: id,
      itemID: itemID,
      urls: null
    })
  }

  function addThumbnailWorkerId(id: string) {
    state.thumbnailWorkerIds.push(id)
  }

  function terminateAllThumbnailWorkers() {
    for (const id of state.thumbnailWorkerIds) {
      WorkerService.terminateWorker(id)
    }

    setThumbnailWorkerIds([])
  }

  function setThumbnailWorkerIds(ids: string[]) {
    state.thumbnailWorkerIds = ids
  }

  function getThumbnailFetchFromList(id: number) {
    return state.fetchedThumbnails.find((t) => t.itemID === id)
  }

  function updateThumbnailStatusForItem(
    itemId: number,
    status?: VaultThumbnailStatus
  ) {
    const item = state.vaultItems.find((i) => i.itemID == itemId)
    if (item) {
      item.thumbnailStatus = status
    }
  }

  function handleFetchedThumbnail(urls: string[], fetch: IThumbnailFetch) {
    fetch.urls = urls
  }
  //#endregion Thumbnails

  return {
    setApiError,
    setSharedVaultItem,
    fetchSharedVaultItem,
    setLoadMoreVaultItems,
    isThumbableType,
    setDraggingVaultItem,
    setVaultItems,
    removeVaultItemFromList,
    fetchDownloadUrl,
    filePost,
    fetchFileItems,
    fileTrash,
    fileRestore,
    fileDelete,
    getOrCreateThumbnailSasUrl,
    folderPost,
    fetchSubFolders,
    folderTrash,
    folderRestore,
    folderDelete,
    uploadTokenPost,
    vaultAccountPost,
    fetchQuotaInfo,
    vaultAttachmentPost,
    fetchFileProviderItems,
    fetchTrashedItems,
    search,
    fetchPreviewInfo,
    starVaultItem,
    renameVaultItem,
    editDescriptionVaultItem,
    moveVaultItem,
    checkRestoreLocation,
    deleteAll,
    vaultJournalAttachmentPost,
    sendShareEmail,
    getLink,
    getSharedItems,
    getCurrentFolderId,
    getAllFolders,
    setTreeData,
    updateFolderTree,
    uploadToAzureInBLocks,
    setVaultColumnFilter,
    selectedVaultSortByColumn,
    selectedVaultSortByDirection,
    setVaultListViewMode,
    startUploadProcess,
    resumeUpload,
    setSearchTerm,
    findFolderPath,
    updateBreadcrumbs,
    stopDownload,
    startDownload,
    getCurrentExpireTimeInTicks,
    handleSearchTermUpdate,
    startThumbnailFetch,
    resetVaultItems,
    updateVaultQuota,
    setHoveringOverVaultItem,
    handleThumbnailGeneratedNotification,
    startUploadQueue,
    pauseUpload,
    cancelUpload,
    getCurrentUpload,
    getQueuedUploads,
    getHighestPriorityUpload,
    getPausedUploads,
    cancelAllUploads,
    removeDownloadFromList,
    reDownload,
    cancelAllDownloads,
    confirmDeleteAll,
    setvaultItemAttachments,
    removeVaultItemFromAttachments,
    getVaultItemAttachmentIndex,
    pushVaultItemToAttachments,
    pushVaultItemToSelectedList,
    removeVaultItemFromSelectedList,
    getVaultItemSelectedListIndex,
    setSelectedItemsList,
    setEditingItem,
    navigateIntoFolder,
    setCurrentFolderLevel,
    getCompletedUploads,
    reset,
    removeUploadFromList,
    undoMove,
    setUndoItems,
    setMultiSelectMode,
    terminateAllThumbnailWorkers,
    addThumbnailWorkerId,
    setThumbnailWorkerIds,
    moveVaultItems,
    removeVaultItemsFromList,
    onDrop,
    setCurrentFolder,
    updateCurrentFolder,
    handleTrashFileOrfolder,
    trashOrRestoreVaultItems,
    skeletonLoading,
    starVaultItems,
    fetchDownloadUrls,
    deleteItems,
    setSelectedVaultSortBy,
    setPageNumber,
    fetchItems,
    handleVaultItemsUpdate,
    handleSortByUpdate,
    handleColumnFilterUpdate,
    handleSelectedItemsListUpdate,
    fetchMoreItems,
    fetchFirstItems,
    setRangeStart,
    setRangeEnd,
    sortItemsLocally,
    ...toRefs(state)
  }
})
