<template>
  <div class="stack">
    <h2 class="call-title">{{ t('calling.videoCall.incoming.name') }}</h2>
    <div class="mx-auto recorded-call">
      
      <font-awesome-icon
        icon="fa-light fa-circle-info"
        class="icon-size-1"
      />
      {{ t('calling.videoCall.accountableCalls') }}
    </div>
    
    <font-awesome-icon
      icon="fa-light fa-bell-ring"
      class="mx-auto icon-lg"
    />
    
    <!-- <p class="available-minutes mx-auto">Available minutes: {{ callingBalance }} minutes</p>  -->
    
    <div class="flex gap-1 align-center">
      <button 
        class="btn secondary" 
        @click="declineVideoCall()">
        <Loading :loading="loading"/>
        {{ t('calling.videoCall.incoming.decline') }}
      </button>
      <button 
        class="btn primary" 
        @click="acceptVideoCall()"
      >
        <Loading :loading="loading"/>
        {{ t('calling.videoCall.incoming.accept') }}
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { type PropType, onBeforeUnmount, onMounted, reactive, ref } from 'vue'
import { useEventBus, useIntervalFn } from '@vueuse/core'
import { useCallingStore } from '@/stores/CallingStore'
import constants from '@/exports/constants'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import type { Moment } from 'moment'
import moment from 'moment'
import type { IVideoCallNotification } from '@/models/interfaces'
import Loading from '@/components/library/Loading.vue'

const props = defineProps({
  expiresWhen: {
    type: Object as PropType<Moment>,
    required: true
  }
})

const loading = ref<boolean>(false)

const { t } = useI18n({ useScope: 'global' })

const callingStore = useCallingStore()
const { updateVideoCallStatus, setShowVideoCall, fetchCallingItems, getActiveVideoCall, setActiveCall } = callingStore
const { activeCall } = storeToRefs(callingStore)

const callNotif = useEventBus<IVideoCallNotification>('callNotification')

const emit = defineEmits<{
  (e: 'success'): void
  (e: 'cancel'): void
  (e: 'dropped'): void
}>()

const { resume, pause } = useIntervalFn(
  checkNotifExpiration, 
  1000,
  {
    immediate: false,
    immediateCallback: true
  }
)

onMounted(() => {
  // syncronize timer with clock second
  setTimeout(resume, 1000 - new Date().getMilliseconds())
})

onBeforeUnmount(() =>{
  pause()
  callNotif.reset()
})

function checkNotifExpiration() {
  if (props.expiresWhen.isBefore(moment.utc())) {
    pause()
    callNotif.emit({
      status: constants.callNotifStatus.ignored
    })
    setActiveCall(null)
    emit('cancel')
  }
}

async function acceptVideoCall() {
  loading.value = true
  await getActiveVideoCall() 
  // check if video call has ended
  if (activeCall.value) {
    setShowVideoCall(true)
    callNotif.emit({
      status: constants.callNotifStatus.accepted
    })
    emit('success')
  } else {
    callNotif.emit({
      status: constants.callNotifStatus.ignored
    })
    emit('dropped') // sender ended call before accepting
  }
  loading.value = false
}

async function declineVideoCall() {
  loading.value = true
  if (activeCall.value) {
    await updateVideoCallStatus({
      videoCallId: activeCall.value.itemID,
      newStatus: constants.VIDEO_CALL_STATUS_ENUM.CoparentRejected
    })
    fetchCallingItems()
    await getActiveVideoCall()

    callNotif.emit({
      status: constants.callNotifStatus.declined
    })
  } 

  loading.value = false
  emit('cancel')
}
</script>

<style scoped>
.call-title {
  background-color: var(--surface-2);
  text-align: center;
  margin: -1rem -1rem 0 -1rem;
  border-radius: .5rem .5rem 0 0;
  padding:1rem;
  font-size: 1rem;

  @media(width >= 48em) {
    margin: -1rem -2rem 0 -2rem;
  }
}

.recorded-call {
  background-color: var(--gray-cool-0);
  color: var(--teal-9);
  padding: 0.125rem 1rem;
  border-radius: 3rem;
  margin-top: .75rem; 
}

.icon-lg {
  color: rgba(10, 129, 133, 1);
  color: var(--brand);
  size: var(--size-8);
}

.available-minutes { font-size: 1rem; }
</style>