<template>
  <div class="main-wrapper">
    <div class="content-wrapper">
      <div class="title-wrapper">
        <h2
          v-dompurify-html="item.conversation?.title"
          :class="[
            {
              conversation: item.type == GlobalSearchResultType.Conversation
            },
            { 'is-new': item.conversation?.isNew || item.message?.isNew }
          ]"
          v-skeleton="item.skeletonLoading ?? loading"
        />

        <time
          v-if="
            item.type == GlobalSearchResultType.Conversation ||
            item.type == GlobalSearchResultType.Message
          "
          v-skeleton="item.skeletonLoading ?? loading"
        >
          {{ displayTime }}
        </time>
      </div>

      <div
        class="message-wrapper"
        :class="[
          {
            bubble:
              item.type == GlobalSearchResultType.Message ||
              (item.type == GlobalSearchResultType.Attachment &&
                item.attachment?.isNew)
          },
          bubbleClass
        ]"
        v-if="showMessageContent"
        v-skeleton="item.skeletonLoading ?? loading"
      >
        <FontAwesomeIcon
          v-if="showHiddenIcon"
          icon="fa-light fa-eye-slash"
          class="icon-size-2"
        />

        <h3 ref="messageContentEl" v-dompurify-html="messageContent" />
      </div>

      <div v-else class="attachment-wrapper" @click.stop="emit('download')">
        <Loading :loading="downloading" />

        <div class="filename-wrapper">
          <FontAwesomeIcon
            icon="fa-light fa-download"
            class="icon-size-2"
            v-skeleton="item.skeletonLoading ?? loading"
          />

          <h3
            v-dompurify-html="item.attachment?.fileName"
            v-skeleton="item.skeletonLoading ?? loading"
          />
        </div>

        <time v-skeleton="item.skeletonLoading ?? loading">
          {{ displayTime }}
        </time>
      </div>

      <h2 v-if="showMultipleMatches && !(item.skeletonLoading ?? loading)">
        <span class="multiple-title">
          {{ t('messages.sortAndFilter.multipleMatches.title') }}
        </span>

        <FontAwesomeIcon icon="fa-solid fa-circle" class="multiple-separator" />

        <span class="multiple-see-all">
          {{ t('messages.sortAndFilter.multipleMatches.seeAll') }}
        </span>

        <FontAwesomeIcon
          icon="fa-light fa-chevron-right"
          class="multiple-chevron"
        />
      </h2>
    </div>

    <FontAwesomeIcon
      icon="fa-light fa-chevron-right"
      class="chevron"
      v-skeleton="item.skeletonLoading ?? loading"
    />
  </div>
</template>
<script setup lang="ts">
import type { IGlobalSearchResult } from '@/models/interfaces.ts'
import { GlobalSearchResultType } from '@/models/enums.ts'
import { computed, ref, toRef } from 'vue'
import { useCommonStore } from '@/stores/CommonStore.ts'
import moment from 'moment'
import { storeToRefs } from 'pinia'
import Loading from '@/components/library/Loading.vue'
import { useI18n } from 'vue-i18n'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
const { t } = useI18n({ useScope: 'global' })
//notes
//if return of type 0 (conversation), item's message prop is the most recent sent message
//if return of type 2 (attachment), message does not exist, just conversation

const commonStore = useCommonStore()
const { fullUserInfo, isMobileWidth, isTabletWidth } = storeToRefs(commonStore)
const { formatTimeStamp, isToday, formatFromUtcTime, formatDate } = commonStore

const props = withDefaults(
  defineProps<{
    item: IGlobalSearchResult
    loading: boolean
    downloading: boolean
  }>(),
  {
    loading: false,
    downloading: false
  }
)
const item = toRef(props, 'item')
const loading = toRef(props, 'loading')

const emit = defineEmits<{
  (e: 'download'): void
}>()

const messageContentEl = ref<HTMLElement | null>(null)

const emTagCount = computed(() => messageContentEl.value?.childElementCount)

const showMessageContent = computed(
  () =>
    item.value.type == GlobalSearchResultType.Conversation ||
    item.value.type == GlobalSearchResultType.Message ||
    (item.value.type == GlobalSearchResultType.Attachment &&
      item.value.attachment?.isNew)
)

const messageContent = computed(() => {
  if (
    item.value.type == GlobalSearchResultType.Conversation &&
    item.value.conversation?.isNew
  ) {
    return t('messages.isNewSubtitle', {
      name:
        item.value.message?.creatorID == fullUserInfo.value.userId
          ? fullUserInfo.value.firstName
          : fullUserInfo.value.coparentFirstName
    })
  }

  if (item.value.type == GlobalSearchResultType.Conversation) {
    return t('messages.conversationSubtitle', {
      name:
        item.value.message?.creatorID == fullUserInfo.value.userId
          ? fullUserInfo.value.firstName
          : fullUserInfo.value.coparentFirstName,
      content: item.value.message?.content
    })
  }

  if (
    item.value.type == GlobalSearchResultType.Message &&
    item.value.message?.isNew
  ) {
    return isMobileWidth.value || isTabletWidth.value
      ? t('messages.sortAndFilter.unreadMessageContentTap')
      : t('messages.sortAndFilter.unreadMessageContentClick')
  }

  if (item.value.type == GlobalSearchResultType.Message) {
    return item.value.message?.content
  }

  //then type is attachment, and isNew is true
  return isMobileWidth.value || isTabletWidth.value
    ? t('messages.sortAndFilter.unreadAttachmentContentTap')
    : t('messages.sortAndFilter.unreadAttachmentContentClick')
})

const showHiddenIcon = computed(
  () =>
    (item.value.type == GlobalSearchResultType.Message &&
      item.value.message?.isNew) ||
    (item.value.type == GlobalSearchResultType.Attachment &&
      item.value.attachment?.isNew)
)

const bubbleClass = computed(() =>
  item.value.message?.creatorID == fullUserInfo.value.userId
    ? 'parent'
    : 'coparent'
)

const displayTime = computed(() => {
  if (item.value.type == GlobalSearchResultType.Conversation) {
    if (!item.value.conversation?.lastMessageDate) {
      return null
    }

    return isToday(item.value.conversation.lastMessageDate)
      ? formatFromUtcTime(item.value.conversation.lastMessageDate)
      : formatDate(item.value.conversation.lastMessageDate, false)
  }

  if (item.value.type == GlobalSearchResultType.Message) {
    if (!item.value.message?.createDate) {
      return null
    }

    return isToday(item.value.message.createDate)
      ? formatFromUtcTime(item.value.message.createDate)
      : formatDate(item.value.message.createDate, false)
  }

  if (item.value.type == GlobalSearchResultType.Attachment) {
    if (!item.value.attachment?.uploadDateTimeUtc) {
      return null
    }

    return formatTimeStamp(moment(item.value.attachment.uploadDateTimeUtc))
  }

  return null
})

const showMultipleMatches = computed(
  () =>
    item.value.type == GlobalSearchResultType.Message &&
    (item.value.message?.highlightCount ?? 0) > (emTagCount.value ?? 0)
)
</script>
<style scoped>
.main-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--size-1);
  padding-inline-end: var(--size-1);
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--size-00);
  position: relative;
  flex: 1;

  & > h2 {
    font-size: var(--font-size-00);
    display: flex;
    align-items: center;
    gap: var(--size-000);

    & > .multiple-title {
      color: var(--text-2);
    }

    & > .multiple-separator {
      width: 0.15rem;
      height: 0.15rem;
      color: var(--text-2);
    }

    & > .multiple-see-all {
      color: var(--brand);
    }

    & > .multiple-chevron {
      width: var(--font-size-000);
      height: var(--font-size-000);
      color: var(--brand);
    }
  }
}

.message-wrapper {
  padding-inline-end: var(--size-2);

  @media (width >= 48em) {
    padding-inline-end: var(--size-5);
  }

  & > :deep(h3) {
    color: var(--text-2);
    font-size: var(--font-size-0);
    word-break: break-all;
  }

  & > :deep(svg) {
    width: fit-content;
    height: fit-content;
  }

  &.bubble {
    padding: var(--size-00) var(--size-0);
    border-radius: 0.8625rem;
    display: flex;
    gap: var(--size-00);
    width: fit-content;

    & > :deep(h3) {
      font-size: var(--font-size-1);
    }

    &.parent {
      margin-inline-start: auto;
      background: var(--messages-parent-bubble-background);
      border-bottom-right-radius: 0;

      & > :deep(h3) {
        color: var(--messages-parent-bubble-text);

        & > em {
          font-variation-settings: 'wght' 500;
        }
      }

      @media (width >= 48em) {
        margin-inline-start: unset;
      }
    }

    &.coparent {
      background: var(--messages-coparent-bubble-background);
      border-bottom-left-radius: 0;

      & > :deep(h3) {
        color: var(--text-0);

        & > em {
          font-variation-settings: 'wght' 500;
        }
      }
    }
  }
}

.title-wrapper {
  display: flex;
  justify-content: space-between;
  gap: var(--size-1);
  position: relative;
  flex: 1 1 auto;

  :deep(h2) {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    min-width: 0;

    font-size: var(--font-size-00);
    color: var(--text-0);

    & > em {
      font-variation-settings: 'wght' 700;
    }
  }

  :deep(h2.conversation) {
    font-size: var(--font-size-2);
  }

  :deep(h2.is-new) {
    font-variation-settings: 'wght' 700;
  }

  :deep(h2.is-new::before) {
    content: '';
    width: var(--size-00);
    height: var(--size-00);
    border-radius: 50%;
    display: block;
    background-color: var(--surface-attention);
    position: absolute;
    inset-inline-start: -0.8625rem;
    inset-block-start: 0.25rem;

    @media (width >= 48em) {
      inset-inline-start: -1rem;
    }
  }

  time {
    font-size: var(--font-size-0);
    align-self: flex-start;
    flex: 0 0 fit-content;
  }
}

.chevron {
  width: 1.25rem;
  height: 1.25rem;
}

.attachment-wrapper {
  background: var(--surface-1);
  border: solid 1px var(--surface-3);
  border-radius: 0.5rem;
  padding: var(--size-00) var(--size-5) var(--size-00) var(--size-1);
  display: flex;
  flex-direction: column;
  gap: var(--size-0);
  width: fit-content;
  max-width: 100%;

  time {
    font-size: var(--size-0);
    color: var(--text-2);
    align-self: flex-end;
    margin-inline-end: calc(var(--size-3) * -1);
  }

  svg {
    flex: none;
  }
}

.filename-wrapper {
  display: flex;
  gap: var(--size-0);
  color: var(--brand);

  :deep(h3) {
    font-size: var(--font-size-1);
    color: var(--brand);
    font-variation-settings: 'wght' 500;
    min-width: 0;
    width: 0 0 fit-content;
    word-wrap: break-word;

    & > em {
      font-variation-settings: 'wght' 700;
    }
  }
}

:deep(em) {
  font-style: normal;
  color: var(--gray-0);
  background: var(--cyan-9);
}

.skeleton :deep(em) {
  background: transparent;
}
</style>
