<template>
  <button
    @click="goBack()"
    type="button"
    class="btn tertiary"
    :class="props.buttonClass"
  >
    <font-awesome-icon icon="fa-light fa-arrow-left-long" class="icon-size-2 " />
    <span
      class="heading-action font-weight-2"
      :class="{ 'sr-only': !showLabel }"
    >
      {{ label }}
    </span>
  </button>
</template>
<script lang="ts" setup>
import type { IRouterLinkToObject } from '@/models/interfaces'
import type { PropType } from 'vue'

import { useRouter } from 'vue-router'
const props = defineProps({
  label: {
    type: String
  },
  to: { type: Object as PropType<IRouterLinkToObject>, required: false },
  buttonClass: { type: String, default: '', required: false },
  useEmit: {
    type: Boolean,
    default: false
  },
  showLabel: {
    type: Boolean,
    default: true
  }
})
const router = useRouter()

const emit = defineEmits<{
  (e: 'goBack'): void
}>()


function goBack() {
  emit('goBack')

  if (!props.useEmit) {
    if (props.to) {
      router.push(props.to)
    } else {
      router.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
.btn.tertiary { color: var(--brand); }
span {
  display: none;

  @media (min-width: 48em) {
    display: inline;
  }
}
</style>
