﻿<template>
  <div>
    <section class="relative">
      <div v-if="!state.showStep2">
        <button
        type="button"
        class="btn tertiary brand back-button"
        @click="sendToForgotEmailOrPassword"
        >
          <font-awesome-icon icon="fa-light fa-arrow-left-long" class="icon-size-2 brand" />
        </button>
      </div>
      <header class="pb-1 mb-2" :class="{'border-bottom' : isDesktopWidth}">
        <h1 class="brand text-center">
          <i18n-t
            :keypath="state.showStep2 ? 'resetPassword.updatedTitle' : 'resetPassword.title'"
          >
            <template #sr>
              <span class="sr-only">{{ t('resetPassword.srTitle') }}</span>
            </template>
          </i18n-t>
        </h1>
      </header>
    </section>
    <div>
      <form 
        v-if="state.showStep1" 
        class="stack size-3"
        @submit.prevent="onSubmit"
      >
        <p class="text-center black">
          {{ t('resetPassword.stepOne.title')}}:
          <strong class="font-weight-3">
              {{ state.email }}
          </strong>
        </p>
        <div class="stack size-3" role="form">
          <text-input
            id="NewPasswordInput"
            name="NewPasswordInput"
            :label="t('resetPassword.stepOne.newPassword.label')"
            v-model="state.newPassword"
            type="password"
            aria-describedby="NewPasswordTip"
            :password-visibility-option="true"
            :required="true"
            :class="{
              invalid: !meta.valid,
              valid: meta.valid
            }"
            :hint="passwordHint"
          />
          <text-input
            id="ConfirmNewPasswordInput"
            name="ConfirmNewPasswordInput"
            :label="t('resetPassword.stepOne.verifyPassword.label')"
            v-model="state.confirmNewPassword"
            type="password"
            aria-describedby="ConfirmNewPasswordTip"
            :password-visibility-option="true"
            :required="true"
            :class="{
              invalid: !meta.valid,
              valid: meta.valid
            }"
            :hint="t('resetPassword.stepOne.verifyPassword.hint')"
            :hint-class="state.hintClass"
          />

          <button
            type="submit"
            class="flex btn primary extended-btn"
            style="width: 13rem;"
            :class="{ disabled: !meta.dirty || !meta.valid || loading }"
            :disabled="!meta.dirty || !meta.valid || loading"
            >
            {{ t('resetPassword.stepOne.updatePasswordCta') }}
          </button>
        </div>
      </form>
      <div v-if="state.showStep2" class="stack size-">
        <p class="text-center">{{ t('Your password was successfully updated.') }}<br/>
          {{ t('resetPassword.stepTwo.loginText') }}
        </p>
        <button
          type="button"
          class="btn primary extended-btn"
        >
          <router-link to="login" class=""
            >{{ t('resetPassword.stepTwo.returnToLoginCta') }}</router-link>
        </button>
      </div>
      <div v-if="state.showResetError" class="stack">
        <p class="text-center">{{ t('resetPassword.resetError.text') }}</p>
        <button
          type="button"
          class="btn primary extended-btn"
        >
          <router-link to="forgotEmailOrPassword" class="black"
            >{{ t('resetPassword.resetError.requestNewLink') }}</router-link>
        </button>
      </div>
      <google-recaptcha
        ref="googleRecaptcha"
        :verified-callback="resetPassword"
        @loaded="onRecaptchaLoaded"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import ErrorHelper from '@/exports/error'
import { useLoginStore } from '@/stores/LoginStore'
import { useCommonStore } from '@/stores/CommonStore'
import { reactive, ref, onMounted, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import TextInput from '@/components/library/TextInput.vue'
import { useForm } from 'vee-validate'
import PasswordHelper from '@/exports/passwordHelper'
import { storeToRefs } from 'pinia'
import GoogleRecaptcha from '@/components/GoogleRecaptchaComponent.vue'
import { useI18n, I18nT } from 'vue-i18n'

const { t } = useI18n({ useScope: 'global' })

const router = useRouter()
const route = useRoute()

const loginStore = useLoginStore()
const commonStore = useCommonStore()
const { updatePassword, verifyPasswordResetCodeFromEmail } = loginStore
const { loading, isDesktopWidth } = storeToRefs(commonStore)
const { setLoading } = commonStore

const googleRecaptcha = ref(GoogleRecaptcha)

const formValidationSchema = {
  NewPasswordInput: 'required|password',
  ConfirmNewPasswordInput: () => {
    if (state.confirmNewPassword === state.newPassword) return true
    return t('resetPassword.stepOne.verifyPassword.noMatchError')
  }
}

const { handleSubmit, meta, handleReset } = useForm({
  validationSchema: formValidationSchema
})

const state = reactive({
  email: '',
  showStep1: false,
  showStep2: false,
  newPassword: '',
  newPasswordInit: false,
  confirmNewPassword: '',
  confirmNewPasswordInit: false,
  showResetError: false,
  recaptchaLoaded: false,
  hintClass: 'font-weight-0'
})

function onRecaptchaLoaded() {
  state.recaptchaLoaded = true
}

const onSubmit = handleSubmit(() => {
  resetPassword()
})

const passwordHint = computed(() => {
  return t('resetPassword.stepOne.newPassword.hint')
})

onMounted(() => {
  setLoading(true)
  // Both reset modes must now verify using token before attempting to add new password
  const code = route.query.c?.toString()

  if (code) {
    const payload = {
      verifyCode: code
    }
    verifyPasswordResetCodeFromEmail(payload).then((email) => {
      if (email) {
        state.showStep1 = true
        state.email = email
        setLoading(false)
      } else {
        state.showResetError = true
        setLoading(false)
      }
    })
  } else {
    // something not right if you get here, redirect to login
    router.push('login')
  }
  
})

async function resetPassword(token?: string) {
  if (!token) {
    //GoogleRecaptcha.verify()
    googleRecaptcha.value?.verify()
    return
  }
  setLoading(true)
  // todo figure out what value for request code should be
  try {
    const payload = {
      newPassword: state.newPassword,
      email: state.email,
      requestCode: '',
      recaptchaToken: token
    }
    updatePassword(payload).then((result) => {
      if (result) {
        state.showStep1 = false
        state.showStep2 = true
        setLoading(false)
        handleReset()
      } else {
        state.showStep1 = false
        state.showStep2 = false
        state.showResetError = true
        setLoading(false)
      }
    })
  } catch (e) {
    state.showResetError = true
    ErrorHelper.handleError(e, 'resetPassword')
    setLoading(false)
    return
  }
}

function sendToForgotEmailOrPassword() {
  router.push({ name: 'forgotEmailOrPassword'})
}

</script>
<style scoped lang="scss">
:deep(.label-hint) {
  font-weight: var(--font-weight-1);
  margin-top: 0;
}
.back-button {
  position: absolute;
  bottom: 6rem;
  @media (min-width: 48em) {
    bottom: calc(100%);
    right: calc(100% - .5rem);
  }
}

.extended-btn {
  width: 13rem;
  a {
    color: var(--text-3);
  }
}
</style>