function rootMeanSquare(samples: Float32Array): number {
  const sumSq = samples.reduce((sumSq, sample) => sumSq + sample * sample, 0)
  return Math.sqrt(sumSq / samples.length)
}

interface LocalAudioTrack {
  mediaStreamTrack: MediaStreamTrack
}

const connectOptions = {
  // Available only in Small Group or Group Rooms only. Please set "Room Type"
  // to "Group" or "Small Group" in your Twilio Console:
  // https://www.twilio.com/console/video/configure
  bandwidthProfile: {
    video: {
      dominantSpeakerPriority: 'high',
      mode: 'collaboration',
      clientTrackSwitchOffControl: 'auto',
      contentPreferencesMode: 'auto'
    }
  },
  maxAudioBitrate: 16000,

  // Capture 720p video @ 24 fps.
  video: { height: 720, frameRate: 24, width: 1280 }
}

type UserFriendlyErrorFunction = (error?: Error) => string
const USER_FRIENDLY_ERRORS: Record<string, UserFriendlyErrorFunction> = {
  NotAllowedError: () => {
    return (
      '<b>Causes: </b><br>1. The user has denied permission for your app to access the input device either by dismissing the permission dialog or clicking on the "deny" button.<br> 2. The user has denied permission for your app to access the input device in the browser settings.<br>' +
      '<br><b>Solutions: </b><br> 1. The user should reload your app and grant permission to access the input device.<br> 2. The user should allow access to the input device in the browser settings and then reload your app.'
    )
  },
  NotFoundError: () => {
    return (
      "<b>Cause: </b><br>1. The user has disabled the input device for the browser in the system settings.<br>2. The user's machine does not have such input device connected to it.<br>" +
      '<br><b>Solution</b><br>1. The user should enable the input device for the browser in the system settings<br>2. The user should have at least one input device connected.'
    )
  },
  NotReadableError: () => {
    return (
      '<b>Cause: </b><br>The browser could not start media capture with the input device even after the user gave permission, probably because another app or tab has reserved the input device.<br>' +
      '<br><b>Solution: </b><br>The user should close all other apps and tabs that have reserved the input device and reload your app, or worst case, restart the browser.'
    )
  },
  OverconstrainedError: (error?: Error) => {
    if (error && 'constraint' in error) {
      const constraintError = error as { constraint: string }
      return constraintError.constraint === 'deviceId'
        ? '<b>Cause: </b><br>Your saved microphone or camera is no longer available.<br><br><b>Solution: </b><br>Please make sure the input device is connected to the machine.'
        : '<b>Cause: </b><br>Could not satisfy the requested media constraints. One of the reasons ' +
            'could be that your saved microphone or camera is no longer available.<br><br><b>Solution: </b><br>Please make sure the input device is connected to the machine.'
    }
    return '<b>Cause: </b><br>An unknown error occurred.<br>'
  },
  TypeError: () => {
    return (
      '<b>Cause: </b><br><code>navigator.mediaDevices</code> does not exist.<br>' +
      "<br><b>Solution: </b><br>If you're sure that the browser supports " +
      '<code>navigator.mediaDevices</code>, make sure your app is being served ' +
      'from a secure context (<code>localhost</code> or an <code>https</code> domain).'
    )
  }
}

type LevelChangedCallback = (level: number) => void

export default {
  async pollAudioLevel(
    track: LocalAudioTrack,
    onLevelChanged: LevelChangedCallback
  ): Promise<void> {
    const AudioContext = window.AudioContext
    const audioContext = AudioContext ? new AudioContext() : null

    if (!audioContext) {
      return
    }

    await audioContext.resume()

    const analyser = audioContext.createAnalyser()
    analyser.fftSize = 1024
    analyser.smoothingTimeConstant = 0.5

    const stream = new MediaStream([track.mediaStreamTrack])
    const source = audioContext.createMediaStreamSource(stream)
    source.connect(analyser)

    const samples = new Uint8Array(analyser.frequencyBinCount)
    let level: number | null = null

    requestAnimationFrame(function checkLevel() {
      analyser.getByteFrequencyData(samples)
      const rms = rootMeanSquare(new Float32Array(samples))
      const log2Rms = rms && Math.log2(rms)

      const newLevel = Math.ceil((10 * (log2Rms ?? 0)) / 8)
      if (level !== newLevel) {
        level = newLevel
        onLevelChanged(level)
      }

      if (track.mediaStreamTrack.readyState === 'live') {
        requestAnimationFrame(checkLevel)
      } else {
        requestAnimationFrame(() => onLevelChanged(0))
      }
    })
  },
  getConnectionOptions(isMobile: boolean) {
    return connectOptions
  },
  getUserFriendlyError(error: Error): string {
    const errorName = [error.name, error.constructor.name].find(
      (name) => name in USER_FRIENDLY_ERRORS
    )
    return errorName ? USER_FRIENDLY_ERRORS[errorName](error) : error.message
  }
}
