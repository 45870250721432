export default {
  async generateKey() {
    return await window.crypto.subtle.generateKey(
      {
        name: 'AES-GCM',
        length: 256
      },
      false,
      ['encrypt', 'decrypt']
    )
  },
  async generateKeyFromData(data: string, salt: string) {
    const baseKey = await window.crypto.subtle.importKey(
      'raw',
      new TextEncoder().encode(data),
      'PBKDF2',
      false,
      ['deriveKey']
    )

    return await window.crypto.subtle.deriveKey(
      {
        name: 'PBKDF2',
        salt: new TextEncoder().encode(salt),
        iterations: 10000,
        hash: 'SHA-256'
      },
      baseKey,
      { name: 'AES-GCM', length: 256 },
      true,
      ['encrypt', 'decrypt']
    )
  },
  async encryptData(key: CryptoKey, data: string) {
    const encoder = new TextEncoder()
    const iv = window.crypto.getRandomValues(new Uint8Array(12))
    const encodedData = encoder.encode(data)

    const encryptedData = await window.crypto.subtle.encrypt(
      {
        name: 'AES-GCM',
        iv: iv
      },
      key,
      encodedData
    )

    return { iv, encryptedData }
  },
  async decryptData(
    key: CryptoKey,
    iv: BufferSource,
    encryptedData: BufferSource
  ) {
    const decryptedData = await window.crypto.subtle.decrypt(
      {
        name: 'AES-GCM',
        iv: iv
      },
      key,
      encryptedData
    )

    const decoder = new TextDecoder()

    return decoder.decode(decryptedData)
  }
}
