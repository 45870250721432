<template>
  <transition name="modal-animation" appear>
    <div v-if="modalActiveRef" class="modal" :class="{ blur: blurBackground }">
      <transition name="modal-animation-inner" appear>
        <div ref="modalRef" v-show="modalActiveRef" class="modal-inner">
          <!-- Close Modal -->
          <button
            v-if="showCloseButtonRef"
            class="btn circle dialog-close"
            @click="close"
          >
            <font-awesome-icon
              icon="fa-light fa-xmark"
              class="gray-light"
              style="width: 20px; height: 20px"
            />
            <span class="sr-only">close</span>
          </button>

          <!-- title -->
          <div
            class="dialog-header stack text-center"
            v-scroll-lock="modalActiveRef && isDesktopWidth"
          >
            <slot name="header">
              <p class="font-size-2 ellipsis">{{ props.title }}</p>
            </slot>
          </div>
          <!-- Content -->
          <slot name="content">
            <div
              v-if="showDialogRef"
              class="dialog-body"
              v-html="props.message"
            ></div>
          </slot>

          <!-- <p class="black font-size-1" style="padding-inline: var(--size-2)">
            {{ props.message }}
          </p> -->
          <!-- Fotter -->
          <div v-if="showFooterRef" class="dialog-footer align-center">
            <slot name="footer"></slot>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useCommonStore } from '@/stores/CommonStore'
import { onClickOutside } from '@vueuse/core'
import { ref, toRef } from 'vue'

const commonStore = useCommonStore()
const { isDesktopWidth } = storeToRefs(commonStore)

const props = defineProps({
  title: String,
  message: String,
  buttonLabel: String,
  modalActive: {
    type: Boolean,
    required: true
  },
  closeOnOutsideClick: {
    type: Boolean,
    default: true
  },
  showDialog: {
    type: Boolean,
    default: true
  },
  showFooter: {
    type: Boolean,
    default: true
  },
  showCloseButton: {
    type: Boolean,
    default: true
  },
  blurBackground: {
    type: Boolean,
    default: false
  }
})

const modalActiveRef = toRef(props, 'modalActive')
const showDialogRef = toRef(props, 'showDialog')
const showFooterRef = toRef(props, 'showFooter')
const showCloseButtonRef = toRef(props, 'showCloseButton')

const emit = defineEmits<{
  (e: 'close'): void
}>()

const modalRef = ref(null)

onClickOutside(modalRef, (event) => {
  if (modalActiveRef.value && props.closeOnOutsideClick) {
    emit('close')
  }
})

function close() {
  emit('close')
}
</script>

<style lang="scss" scoped>
.modal-animation-enter-active,
.modal-animation-leave-active {
  transition: opacity 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}
.modal-animation-enter-from,
.modal-animation-leave-to {
  opacity: 0;
}
.modal-animation-inner-enter-active {
  transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02) 0.15s;
}
.modal-animation-inner-leave-active {
  transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}
.modal-animation-inner-enter-from {
  opacity: 0;
  transform: scale(0.8);
}
.modal-animation-inner-leave-to {
  transform: scale(0.8);
}
.modal {
  margin-top: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: rgb(0 0 0 / 0.55);
  z-index: 3000;

  @media (min-width: 48em) {
    justify-content: center;
    align-items: center;
  }
}
.modal-inner {
  position: relative;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  background-color: var(--surface-1);

  @media (min-width: 48em) {
    max-width: 640px;
    width: 80%;
  }
}

.dialog-header {
  padding: var(--size-1) var(--size-5);
  border-bottom: solid 1px #cfcfcf;
  font-weight: var(--font-weight-1);
  word-wrap: anywhere;
  font-size: var(--font-size-2);
}
.dialog-close {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  inset-block-start: 1.25rem;
  inset-inline-end: 1.25rem;

  svg {
    width: 1rem;
  }
}
.blur {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
</style>
