import {
  ApplicationInsights,
  DistributedTracingModes
} from '@microsoft/applicationinsights-web'
import { generateW3CId } from '@microsoft/applicationinsights-core-js'
import { useCommonStore } from '@/stores/CommonStore'
import { useLoginStore } from '@/stores/LoginStore'
import { computed } from 'vue'

/**
 * Install function passed to Vue.use() show documentation on vue.js website.
 *
 * @param Vue
 * @param options
 */
function install(Vue, options) {
  const config = options.appInsightsConfig || {}
  ;(config.connectionString =
    config.connectionString || options.connectionString),
    // Required for end-to-end traces
    (config.disableAjaxTracking = false)
  config.disableCorrelationHeaders = false
  config.disableFetchTracking = false
  config.enableCorsCorrelation = true
  config.enableRequestHeaderTracking = true
  config.enableDebug = true
  config.loggingLevelConsole = 2
  config.distributedTracingMode = DistributedTracingModes.W3C
  config.loggingLevelTelemetry = 2
  const url = import.meta.env.VITE_APP_API_BASE_URL.replace(
    /^\/\/|^.*?:(\/\/)?/,
    ''
  ).replace('/', '')
  config.correlationHeaderDomains = [url, url + ':443']

  if (options.appInsights) {
    Vue.appInsights = options.appInsights
  } else {
    Vue.appInsights = new ApplicationInsights({ config })
    Vue.appInsights.addTelemetryInitializer((envelope) => {
      const commonStore = useCommonStore()
      const _traceUser = commonStore.fullUserInfo.traceUser

      // Only send telemetry for users where Trace User is set, or non-prod environments
      if (import.meta.env.VITE_NODE_ENV !== 'production' || _traceUser) {
        const telemetryItem = envelope.baseData

        // List of endpoints to ignore
        const ignoredEndpoints = [
          '/service-worker.js', // call to check for new service worker (happens every 5 minutes)
          'cdp.talkingparents.com', // calls to bloomreach
          'pagead2.googlesyndication.com' // calls to Adsense
        ]

        // Check if the telemetry item is an AJAX call
        if (telemetryItem && telemetryItem.target) {
          // Ignore the request if the URL matches any of the ignored endpoints
          if (
            ignoredEndpoints.some((endpoint) =>
              telemetryItem.target.includes(endpoint)
            )
          ) {
            return false // Returning false will filter out this telemetry item
          }
        }

        // Proceed with sending telemetry
        return true
      }

      // Do not send telemetry if conditions are not met
      return false
    })
    Vue.appInsights.loadAppInsights()
    Vue.appInsights.trackPageView()
    if (typeof options.onAfterScriptLoaded === 'function') {
      options.onAfterScriptLoaded(Vue.appInsights)
    }
  }

  const router = options.router

  // Watch route event if router option is defined.
  if (router) {
    if (options.trackInitialPageView !== false) {
      setupPageTracking(options, Vue)
    } else {
      router.onReady(() => setupPageTracking(options, Vue))
    }
  }
}

/**
 * Track route changes as page views with AppInsights
 * @param options
 */
function setupPageTracking(options, Vue) {
  const router = options.router
  const commonStore = useCommonStore()
  const loginStore = useLoginStore()
  const baseName = options.baseName || '(Vue 3 App)'

  router.beforeEach((route, from, next) => {
    const _traceUser = commonStore.fullUserInfo.traceUser
    if (import.meta.env.VITE_NODE_ENV != 'production' || _traceUser) {
      const name = baseName + ' / ' + route.name
      Vue.appInsights.context.telemetryTrace.traceID = generateW3CId()
      Vue.appInsights.context.telemetryTrace.name = route.name
      Vue.appInsights.startTrackPage(name)
    }
    next()
  })

  router.afterEach((route) => {
    const _traceUser = commonStore.fullUserInfo.traceUser
    if (import.meta.env.VITE_NODE_ENV != 'production' || _traceUser) {
      const name = baseName + ' / ' + route.name
      const url = location.protocol + '//' + location.host + route.fullPath
      const _token = loginStore.authToken
      const _userId = commonStore.fullUserInfo.userId
      const _traceUser = commonStore.fullUserInfo.traceUser
      Vue.appInsights.stopTrackPage(name, url, {
        AuthToken: _token,
        UserID: _userId,
        TraceUser: _traceUser
      })
      Vue.appInsights.flush()
    }
  })
}

// auto install for navigator
if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(install)
}

export default install
