<template>
  <img :alt="props.imageAlt" :src="props.imageUrl" :aria-hidden="true" />

  <div class="stack size-1 welcome">
    <h2 class="font-weight-2 font-size-2 mt-1 text-center">
      {{ subHeaderText }}
    </h2>

    <ul class="stack size-2 mt-1">
      <li
        v-for="bullet in bulletList"
        :key="`${bullet.mainText}${bullet.secondaryText}`"
        class="flex"
      >
        <component
          v-if="iconIsSVG(bullet.bulletIcon)"
          :is="bullet.bulletIcon"
          class="bullet-icon flex-shrink-0"
          :aria-hidden="true"
        />

        <font-awesome-icon
          v-else
          :icon="bullet.bulletIcon"
          class="bullet-icon flex-shrink-0"
          :aria-hidden="true"
        />

        <div class="flex-column">
          <h3 class="main-text" v-html="bullet.mainText" />

          <h4 class="mt-000 second-text" v-html="bullet.secondaryText" />
        </div>
      </li>
    </ul>
  </div>

  <slot name="ctaButtons"></slot>
</template>
<script setup lang="ts">
import type { PropType } from 'vue'
import type { IWelcomePageBullet } from '@/models/interfaces'

const props = defineProps({
  imageUrl: {
    type: String,
    required: true
  },
  imageAlt: {
    type: String,
    required: true
  },
  subHeaderText: {
    type: String
  },
  bulletList: {
    type: Object as PropType<IWelcomePageBullet[]>
  }
})

// import svg in parent component and pass it inside the bulletList object
function iconIsSVG(iconName: string | Function) {
  return typeof iconName != 'string'
}

const emit = defineEmits<{
  (e: 'close'): void
}>()
</script>
<style scoped>
.bullet-icon {
  width: var(--size-3);
  height: var(--size-3);
  color: var(--brand-7);
  margin-right: var(--size-1);

  @media (width >= 48em) {
    align-self: center;
  }
}

.main-text {
  font-size: var(--font-size-2);
  font-variation-settings: 'wght' 600;
}

.second-text {
  font-size: var(--font-size-0);
}

img {
  width: 100%;
  max-width: 17rem !important;
  margin-inline: auto;
  display: block;
}

h2,
h3,
h4 {
  line-height: 1.4rem;
  text-wrap: wrap;
}

:slotted(.btn.primary) {
  font-variation-settings: 'wght' 500;
}
</style>
