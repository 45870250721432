import { defineStore } from 'pinia'
import moment, { type Moment } from 'moment-timezone'
import {
  ApiRequestResult,
  BooleanApiResult,
  ChangePasswordResult,
  CreateSubscriptionCheckoutSessionResult,
  DisableMfaSmsResult,
  DuplicateSubscriptionError,
  EnableMfaSmsResult,
  type IChangePasswordParam,
  type ICreateCheckoutSessionRequest,
  type ICreateCustomerPortalSessionRequest,
  type ICreateSubscriptionCheckoutSessionRequest,
  type ICreateSubscriptionCheckoutSessionResult,
  type IDateFormat,
  type IProcessEmailChangeRequest,
  type ISubscription
} from '@/models/models'
import constants from '../exports/constants'
import helpers from '../exports/helper'
import type { IAccountSettingsState } from '@/models/stores/accountSettings'
import httpClient from '@/httpClient'
import httpClientAnonymous from '@/httpClient'
import type {
  IDateFormats,
  IPlan,
  IRouterLinkToObject,
  ITimeZone,
  ITosResult
} from '@/models/interfaces'
import ErrorHelper from '../exports/error'
import { useCommonStore } from './CommonStore'
import { useLoginStore } from './LoginStore'
import i18n from '@/i18n'

const initialState = (): IAccountSettingsState => ({
  phoneInfo: {},
  subscriptionInfo: {
    isFreeTrialEligible: false,
    duplicateSubscriptionError: DuplicateSubscriptionError._0,
    highestTier: 0,
    hasSubscription: false,
    activeSubscriptions: [],
    suspendedSubscriptions: []
  },
  callingBalance: null,
  plans: [] as IPlan[],
  timeZones: [],
  dateFormats: [] as IDateFormats[],
  smsAccount: {},
  enableMfaSmsErrorMessage: '',
  verifyPhoneSmsErrorMessage: '',
  consentUrl: '',
  paywallPreviousPage: {},
  enableMfaSmsErrorCode: -1,
  verifyPhoneSmsErrorCode: -1,
  showAddMinutesSuccessToast: false
})

const paths: string[] = ['paywallPreviousPage']

export const useAccountSettingsStore = defineStore('accountSettings', {
  state: initialState,
  persist: {
    paths: paths
  },
  getters: {
    freeTrialEligible: (state) => {
      if (helpers.isNullEmpty(state.subscriptionInfo)) {
        return false
      } else {
        if (state.subscriptionInfo.highestTier != undefined) {
          return (
            state.subscriptionInfo.highestTier <
              constants.subscriptionTiers.Standard &&
            state.subscriptionInfo?.isFreeTrialEligible
          )
        } else {
          return false
        }
      }
    },
    subscriptionT0: (state) => {
      return (
        state.subscriptionInfo &&
        state.subscriptionInfo.highestTier == constants.subscriptionTiers.Free
      )
    },
    subscriptionT1: (state) => {
      return (
        state.subscriptionInfo &&
        state.subscriptionInfo?.highestTier ==
          constants.subscriptionTiers.Standard
      )
    },
    subscriptionT2: (state) => {
      return (
        state.subscriptionInfo &&
        state.subscriptionInfo?.highestTier ==
          constants.subscriptionTiers.Premium
      )
    },
    smsNotificationsEnabled: (state) => {
      return state.smsAccount && (state.smsAccount?.balance || 0) > 0
    },
    getActiveSubscription: (state): ISubscription | undefined => {
      const _activeSubcription =
        !helpers.isNullEmpty(state.subscriptionInfo) &&
        state.subscriptionInfo?.activeSubscriptions?.length == 1
          ? state.subscriptionInfo?.activeSubscriptions[0]
          : state.subscriptionInfo?.activeSubscriptions?.find(
              (subscription) =>
                subscription.tier == state.subscriptionInfo?.highestTier
            )

      return _activeSubcription
    },
    getAccountStatus(): number {
      let _status = constants.subscriptionStatus.None
      if (this.getActiveSubscription) {
        _status = this.getActiveSubscription.canceled
          ? constants.subscriptionStatus.Canceled
          : constants.subscriptionStatus.Active
      }
      return _status
    },
    getAccountType(): number {
      let _type = constants.subscriptionTypes.None
      if (this.getActiveSubscription) {
        _type =
          this.getActiveSubscription.type || constants.subscriptionTypes.None
      }
      return _type
    },
    canAccountUpgradeOrCancel(): boolean {
      return (
        this.getAccountType == constants.subscriptionTypes.None ||
        this.getAccountType == constants.subscriptionTypes.Braintree ||
        this.getAccountType == constants.subscriptionTypes.Stripe
      )
    },
    getSubscriptionExpiresDate(): Moment | null {
      const sub = this.getActiveSubscription
      if (sub) {
        return moment.utc(sub.expiresDate, 'YYYY-MM-DDThh:mm:ss')
      }
      return null
    },
    getHasSubscriptionIssue: (state) => {
      return state.subscriptionInfo.duplicateSubscriptionError
    },
    getHasBillingIssue(): boolean {
      return (
        (this.getActiveSubscription &&
          this.getActiveSubscription.hasBillingIssue) ||
        false
      )
    },
    isAppleSubscription(): boolean {
      return (
        this.getAccountType == constants.subscriptionTypes.Apple ||
        this.getAccountType == constants.subscriptionTypes.AppleRenewing
      )
    },
    isAndroidSubscription(): boolean {
      return this.getAccountType == constants.subscriptionTypes.PlayStore
    },
    isPromotional(): boolean {
      return this.getAccountType == constants.subscriptionTypes.Promotional
    },
    getPlanName(): string {
      let _plan = 'Free Plan'
      if (this.subscriptionT1) {
        _plan = 'Standard Plan'
      }
      if (this.subscriptionT2) {
        _plan = 'Premium Plan'
      }
      return _plan
    },
    appStoreSvgUrl(): string {
      return 'https://media.talkingparents.com/images/app-store.svg'
    },
    playStoreSvgUrl(): string {
      return 'https://media.talkingparents.com/images/google-play.svg'
    }
  },
  actions: {
    reset() {
      Object.assign(
        this.$state,
        helpers.omit(initialState(), paths as (keyof IAccountSettingsState)[])
      )
    },
    async createSubscriptionCheckoutSession(
      payload: ICreateSubscriptionCheckoutSessionRequest
    ): Promise<ICreateSubscriptionCheckoutSessionResult> {
      try {
        const response = await httpClient.post(
          '/web/api/payments/CreateSubscriptionCheckoutSession',
          payload
        )

        if (!response?.data?.success) {
          const error = new Error(response.data.errorMessage)
          const code = response.data.errorCode

          if (code == 1) {
            ErrorHelper.handleError(
              error,
              'createSubscriptionCheckoutSession',
              true,
              i18n.global.t('errors.existingSubscriptionMessage')
            )
            return {}
          }

          throw new Error(response.data.errorMessage)
        }

        return CreateSubscriptionCheckoutSessionResult.fromJS(response.data)
      } catch (e) {
        ErrorHelper.handleError(e, 'createSubscriptionCheckoutSession')
        return {}
      }
    },
    async createCustomerPortalSession(
      payload: ICreateCustomerPortalSessionRequest
    ): Promise<ICreateSubscriptionCheckoutSessionResult> {
      try {
        const response = await httpClient.post(
          '/web/api/payments/CreateCustomerPortalSession',
          payload
        )

        return CreateSubscriptionCheckoutSessionResult.fromJS(response.data)
      } catch (e) {
        ErrorHelper.handleError(e, 'createCustomerPortalSession')
        return {}
      }
    },
    async createCheckoutSession(
      payload: ICreateCheckoutSessionRequest
    ): Promise<ICreateSubscriptionCheckoutSessionResult> {
      try {
        const response = await httpClient.post(
          '/web/api/payments/CreateCheckoutSessionForSms',
          payload
        )

        if (!response?.data?.success)
          throw new Error(response?.data?.errorMessage)

        return CreateSubscriptionCheckoutSessionResult.fromJS(response.data)
      } catch (e) {
        ErrorHelper.handleError(e, 'createCustomerPortalSession')
        return {}
      }
    },
    async createCheckoutSessionForAddMinutes(
      payload: ICreateCheckoutSessionRequest
    ): Promise<ICreateSubscriptionCheckoutSessionResult> {
      try {
        const response = await httpClient.post(
          '/web/api/payments/CreateCheckoutSessionForAddMinutes',
          payload
        )

        if (!response?.data?.success)
          throw new Error(response?.data?.errorMessage)

        return CreateSubscriptionCheckoutSessionResult.fromJS(response.data)
      } catch (e) {
        ErrorHelper.handleError(e, 'createCustomerPortalSession')
        return {}
      }
    },
    async cancelProcessing(sessionId: string): Promise<void> {
      try {
        await httpClient.get(
          '/web/api/payments/CancelProcessing?session_id=' + sessionId
        )
      } catch (e) {
        ErrorHelper.handleError(e, 'cancelProcessing')
      }
    },
    async orderSuccess(sessionId: string): Promise<void> {
      try {
        const response = await httpClient.get(
          '/web/api/payments/OrderSuccess?session_id=' + sessionId
        )

        if (!response?.data?.success)
          throw new Error(response?.data?.errorMessage)
      } catch (e) {
        ErrorHelper.handleError(e, 'orderSuccess')
      }
    },
    async smsSuccess(sessionId: string): Promise<void> {
      try {
        const response = await httpClient.get(
          '/web/api/payments/smsSuccess?session_id=' + sessionId
        )
        if (!response?.data?.success)
          throw new Error(response?.data?.errorMessage)
      } catch (e) {
        ErrorHelper.handleError(e, 'smsSuccess')
      }
    },
    async addMinSuccess(sessionId: string) {
      try {
        const response = await httpClient.get(
          '/web/api/payments/addMinutesSuccess?session_id=' + sessionId
        )
        if (!response?.data?.success)
          throw new Error(response?.data?.errorMessage)
      } catch (e) {
        ErrorHelper.handleError(e, 'smsSuccess')
      }
    },
    async fetchPhoneInfo() {
      try {
        const response = await httpClient.get(
          '/web/api/MyAccount/GetUserMobilePhoneInfo'
        )
        if (!response?.data?.success)
          throw new Error(response?.data?.errorMessage)

        this.phoneInfo = response.data.value
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchPhoneInfo')
      }
    },
    async fetchSubscriptionInfo() {
      try {
        const response = await httpClient.get(
          '/web/api/Subscription/GetUserSubscription',
          {
            headers: { 'api-version': 3 }
          }
        )
        if (!response?.data?.success)
          throw new Error(response?.data?.errorMessage)

        this.subscriptionInfo = response.data.value
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchSubscriptionInfo')
      }
    },
    async fetchCallingBalance() {
      try {
        const response = await httpClient.get(
          '/web/api/MyAccount/GetCompleteCallingBalance'
        )
        if (!response?.data?.success)
          throw new Error(response?.data?.errorMessage)

        this.callingBalance = response.data.value
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchCallingBalance')
      }
    },
    async fetchPlans() {
      try {
        const response = await httpClient.get('/web/api/BuyMinutes/GetPlans')
        if (!response?.data?.success)
          throw new Error(response?.data?.errorMessage)

        this.plans = response.data.value
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchPlans')
      }
    },
    async fetchTimeZones() {
      try {
        const response = await httpClientAnonymous.get(
          '/web/api/MyAccount/GetTimeZones'
        )
        if (!response?.data?.success)
          throw new Error(response?.data?.errorMessage)

        this.timeZones = response.data.value
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchTimeZones')
      }
    },
    async fetchDateFormats() {
      try {
        const response = await httpClient.get(
          '/web/api/MyAccount/GetDateFormats'
        )
        if (!response?.data?.success)
          throw new Error(response?.data?.errorMessage)

        this.dateFormats = response.data.value
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchDateFormats')
      }
    },
    getTimeZoneDictionary(): Map<string, ITimeZone> {
      const timeZoneMap = new Map<string, ITimeZone>()

      this.timeZones.forEach((t) => timeZoneMap.set(t.displayName || '', t))
      return timeZoneMap
    },
    getDateFormatDictionary(): Map<string, IDateFormat> {
      const dateFormatMap = new Map<string, IDateFormat>()

      this.dateFormats.forEach((d) =>
        dateFormatMap.set(
          `${d.formatName} ${d.shortDateFormat.toUpperCase()}` || '',
          d
        )
      )
      return dateFormatMap
    },
    async fetchSmsAccount() {
      try {
        const response = await httpClient.get(
          '/web/api/SmsNotification/GetAccount'
        )
        if (!response?.data?.success)
          throw new Error(response?.data?.errorMessage)

        this.smsAccount = response.data.value
      } catch (e) {
        ErrorHelper.handleError(e, 'fetchSmsAccount')
      }
    },
    async verifyEmailChange(payload: IProcessEmailChangeRequest) {
      try {
        const url = '/web/api/MyAccount/ProcessEmailAddressChange'
        const result = await httpClientAnonymous.post(url, {
          Code: payload.code
        })

        if (!result.data.success) {
          const err = new Error(result.data.errorMessage)
          ErrorHelper.handleError(
            err,
            'verifyEmailChange',
            true,
            result.data.errorMessage
          )
          return
        } else {
          return result.data.value
        }
      } catch (e) {
        // 0 = generic server error
        ErrorHelper.handleError(e, 'verifyEmailChange', false)
        return
      }
    },
    async setPaywallPreviousPage(payload: IRouterLinkToObject) {
      this.paywallPreviousPage = payload
    },
    async confirmVerificationCode(code: string): Promise<boolean> {
      // commit('setEditPhoneNumberErrorMessage', null)
      try {
        const url = '/web/api/MyAccount/ConfirmVerificationCode'
        const result = await httpClient.post(url, { verifyCode: code })

        if (!result.data.success) {
          this.verifyPhoneSmsErrorCode = result.data.errorCode
        } else {
          this.fetchPhoneInfo()
        }
        return result.data.success
      } catch (e) {
        // 0 = generic server error
        ErrorHelper.handleError(e, 'confirmVerificationCode', false)
        return false
      }
    },
    async disableMfaSms(): Promise<boolean> {
      try {
        const url = '/web/api/MyAccount/DisableMfaSms'
        const result = await httpClient.post(url)
        const _reponseCamel: DisableMfaSmsResult = result.data
        if (!_reponseCamel.success) {
          const err = new Error(_reponseCamel.errorMessage)
          ErrorHelper.handleError(
            err,
            'disableMfaSms',
            true,
            _reponseCamel.errorMessage
          )
        } else {
          const login = useLoginStore()
          await login.setAuthTokenMethod(_reponseCamel.newToken || '')

          const commonStore = useCommonStore()
          await commonStore.fetchFullUserInfo()
        }
        return _reponseCamel.success || false
      } catch (e) {
        // 0 = generic server error
        ErrorHelper.handleError(e, 'disableMfaSms', false)
        return false
      }
    },
    async enableMfaSms(
      phoneNumber: string,
      isSignup?: boolean
    ): Promise<boolean> {
      try {
        const url = '/web/api/MyAccount/EnableMfaSms'
        const result = await httpClient.post(url, {
          phoneNumber: phoneNumber
        })

        this.setEnableMfaSmsErrorMessage('')
        const _reponseCamel: EnableMfaSmsResult = result.data
        if (!_reponseCamel.success) {
          if (isSignup && _reponseCamel.errorCode) {
            this.setEnableMfaSmsErrorMessage(_reponseCamel.errorMessage || '')
            this.setEnableMfaSmsErrorCode(_reponseCamel.errorCode)
          }
          const err = new Error(_reponseCamel.errorMessage)
          ErrorHelper.handleError(
            err,
            'enableMfaSms',
            true,
            _reponseCamel.errorMessage
          )
        }
        if (!isSignup) {
          const commonStore = useCommonStore()
          await commonStore.fetchFullUserInfo()
          await this.fetchPhoneInfo()
        }

        return _reponseCamel.success || false
      } catch (e) {
        // 0 = generic server error
        ErrorHelper.handleError(e, 'enableMfaSms', false)
        return false
      }
    },
    async confirmEnableMfaSms(code: string) {
      this.verifyPhoneSmsErrorMessage = ''

      try {
        const response = await httpClient.post(
          '/web/api/MyAccount/ConfirmEnableMfaSms',
          {
            code: code
          }
        )

        if (!response.data.success) {
          this.enableMfaSmsErrorCode = response.data.errorCode
        } else {
          const loginStore = useLoginStore()
          loginStore.setAuthTokenMethod(response.data.newToken)
          loginStore.setMfaCookieMethod(response.data.mfaCookie)
        }

        return response.data.success as boolean
      } catch (e) {
        ErrorHelper.handleError(e, 'confirmEnableMfaSms')
        return false
      }
    },
    async sendVerificationCode(phoneNumber: string): Promise<boolean> {
      try {
        const url = '/web/api/MyAccount/SendVerificationCode'
        const result = await httpClient.post(
          url,
          {
            PhoneNumber: phoneNumber
          },
          {
            headers: { 'api-version': '2' }
          }
        )
        const _reponseCamel: ApiRequestResult = result.data
        if (!_reponseCamel.success) {
          const err = new Error(_reponseCamel.errorMessage)
          ErrorHelper.handleError(
            err,
            'sendVerificationCode',
            true,
            _reponseCamel.errorMessage
          )
        }
        return _reponseCamel.success || false
      } catch (e) {
        ErrorHelper.handleError(e, 'sendVerificationCode', false)
        return false
      }
    },
    async updateDateTimeSettings(
      timeZoneId: number,
      dateFormatId: number
    ): Promise<boolean> {
      try {
        const url = '/web/api/MyAccount/UpdateDateTimeSettings'
        const result = await httpClient.post(url, {
          timeZoneId: timeZoneId,
          dateFormatId: dateFormatId
        })
        const _reponseCamel: ApiRequestResult = result.data
        if (!_reponseCamel.success) {
          const err = new Error(_reponseCamel.errorMessage)
          ErrorHelper.handleError(
            err,
            'updateDateTimeSettings',
            true,
            _reponseCamel.errorMessage
          )
        } else {
          const common = useCommonStore()

          await this.fetchTimeZones()

          await common.fetchFullUserInfo()
        }
        return _reponseCamel.success || false
      } catch (e) {
        ErrorHelper.handleError(e, 'updateDateTimeSettings', false)
        return false
      }
    },
    setDateTimeSettings(timeZoneId: number, dateFormatId: number) {
      const timeZone = this.timeZones.find((t) => t.itemID == timeZoneId)
      const dateFormat = this.dateFormats.find((d) => d.itemID == dateFormatId)

      const commonStore = useCommonStore()

      commonStore.fullUserInfo.dateFormatId = dateFormatId
      commonStore.fullUserInfo.dateFormatName = dateFormat?.formatName
      commonStore.fullUserInfo.shortDateFormat =
        dateFormat?.shortDateFormat ?? 'M/d/yyyy'
      commonStore.fullUserInfo.timeZoneId = timeZoneId
      commonStore.fullUserInfo.timeZoneIdentifierString = timeZone?.identifier
      commonStore.fullUserInfo.timeZoneNameForMomentJs =
        timeZone?.displayName ?? ''
    },
    async hasUserAcceptedLatest(tosType: string): Promise<BooleanApiResult> {
      try {
        const response = await httpClient.get(
          '/web/api/TermsOfService/HasUserAcceptedLatest?tosType=' + tosType
        )
        if (!response?.data?.success)
          throw new Error(response?.data?.errorMessage)

        return response.data.value
      } catch (e) {
        ErrorHelper.handleError(e, 'hasUserAcceptedLatest')
        return new BooleanApiResult()
      }
    },
    async updateAllowCallsFromOtherUser(
      allowCallsFromOtherUser: boolean
    ): Promise<ApiRequestResult> {
      try {
        const url =
          '/web/api/MyAccount/UpdateAllowCallsFromOtherUser?allowCallsFromOtherUser=' +
          allowCallsFromOtherUser
        const result = await httpClient.post(url)
        const _responseCamel: ApiRequestResult = result.data
        if (!_responseCamel.success) {
          const err = new Error(_responseCamel.errorMessage)
          ErrorHelper.handleError(
            err,
            'updateAllowCallsFromOtherUser',
            true,
            _responseCamel.errorMessage
          )
        }
        return _responseCamel
      } catch (e) {
        ErrorHelper.handleError(e, 'sendVerificationCode', false)
        return new ApiRequestResult()
      }
    },
    async getLatestToS(tosType: string): Promise<ITosResult | null> {
      try {
        const response = await httpClient.get(
          '/web/api/TermsOfService/GetLatestToS?tosType=' + tosType
        )
        if (!response?.data?.success)
          throw new Error(response?.data?.errorMessage)

        const _reponseCamel: ITosResult = response.data.value
        return _reponseCamel
      } catch (e) {
        ErrorHelper.handleError(e, 'hasUserAcceptedLatest')
        return null
      }
    },
    async acceptTermsOfService(termsOfServiceId: number): Promise<boolean> {
      try {
        const response = await httpClient.post(
          '/web/api/TermsOfService/AcceptTermsOfService?termsOfServiceId=' +
            termsOfServiceId
        )
        if (!response?.data?.success)
          throw new Error(response?.data?.errorMessage)

        return response?.data?.success
      } catch (e) {
        ErrorHelper.handleError(e, 'hasUserAcceptedLatest')
        return false
      }
    },
    async updateAllowVideoCalls(
      val: boolean
    ): Promise<ApiRequestResult | null> {
      try {
        const url =
          '/web/api/MyAccount/UpdateAllowVideoCalls?allowVideoCalls=' + val
        const result = await httpClient.post(url)
        const _reponseCamel: ApiRequestResult = result.data.value

        if (!_reponseCamel.success) {
          const err = new Error(_reponseCamel.errorMessage)
          ErrorHelper.handleError(
            err,
            'updateAllowVideoCalls',
            true,
            _reponseCamel.errorMessage
          )
        }
        return _reponseCamel
      } catch (e) {
        ErrorHelper.handleError(e, 'hasUserAcceptedLatest')
        return null
      }
    },
    async updateSendNewMessageEmails(
      val: boolean
    ): Promise<ApiRequestResult | null> {
      try {
        const url =
          '/web/api/MyAccount/UpdateSendNewMessageEmails?sendNewMessageEmails=' +
          val
        const result = await httpClient.post(url)
        const _reponseCamel: ApiRequestResult = result.data
        if (!_reponseCamel.success) {
          const err = new Error(_reponseCamel.errorMessage)
          ErrorHelper.handleError(
            err,
            'updateAllowVideoCalls',
            true,
            _reponseCamel.errorMessage
          )
        }
        return _reponseCamel
      } catch (e) {
        ErrorHelper.handleError(e, 'hasUserAcceptedLatest')
        return null
      }
    },
    async refundSmsBalance(): Promise<any> {
      try {
        const url = '/web/api/SmsNotification/RefundBalance'
        const result = await httpClient.post(url, undefined, {
          headers: { 'api-version': '2' }
        }) as any
        
        if (!result.success) {
          const err = new Error(result.errorMessage)

          ErrorHelper.handleError(
            err,
            'refundSmsBalance',
            true,
            result.errorMessage
          )
        }
        return result
      } catch (e) {
        ErrorHelper.handleError(e, 'refundSmsBalance')
        return null
      }
    },
    async changePassword(
      payload: IChangePasswordParam
    ): Promise<ChangePasswordResult | null> {
      try {
        const url = '/web/api/MyAccount/ChangePassword'
        const result = await httpClient.post(url, payload)
        const _reponseCamel: ChangePasswordResult = result.data.value

        if (!_reponseCamel.success) {
          const err = new Error(_reponseCamel.errorMessage)
          const _errorCode = _reponseCamel.errorCode || 0
          if (_errorCode == 0) {
            // 1 = Password incorrect
            // 2 = Inknown password format
            ErrorHelper.handleError(
              err,
              'updateAllowVideoCalls',
              true,
              _reponseCamel.errorMessage
            )
          }
        } else {
          const loginStore = useLoginStore()
          loginStore.setAuthTokenMethod(_reponseCamel.newAuthToken || '')
        }
        return _reponseCamel
      } catch (e) {
        ErrorHelper.handleError(e, 'changePassword')
        return null
      }
    },
    async removePhoneNumber() {
      try {
        const url = '/web/api/MyAccount/RemoveMobilePhoneInfo'
        const result = await httpClient.post(url, {})

        if (!result.data.success) {
          throw new Error(result.data.errorMessage)
        } else {
          const loginStore = useLoginStore()
          const commonStore = useCommonStore()

          loginStore.setAuthTokenMethod(result.data.value.newToken)
          commonStore.setMfaSmsEnabled(false)

          await this.fetchPhoneInfo()
          return result.data.success
        }
      } catch (e) {
        ErrorHelper.handleError(e, 'removePhoneNumber')
      }
    },
    async updateBrandConsistencyEnabled(enabled: boolean) {
      const url = 'api/MyAccount/UpdateBrandConsistencyEnabled'
      const payload = { enabled: enabled }
      const result = await httpClient.post(url, payload, {
        headers: { 'api-version': '2' }
      })
      return result.data.success
    },
    async updateName(firstName: string, lastName: string): Promise<boolean> {
      try {
        const url = '/web/api/MyAccount/SetRealName'
        const result = await httpClient.post(url, {
          firstName: firstName,
          lastName: lastName
        })
        const _reponseCamel: ApiRequestResult = result.data
        if (!_reponseCamel.success) {
          const err = new Error(_reponseCamel.errorMessage)
          ErrorHelper.handleError(
            err,
            'setRealName',
            true,
            _reponseCamel.errorMessage
          )
        }

        const commonStore = useCommonStore()

        commonStore.fullUserInfo.firstName = firstName
        commonStore.fullUserInfo.lastName = lastName
        commonStore.fullUserInfo.fullName = `${firstName} ${lastName}`
        return _reponseCamel.success || false
      } catch (e) {
        ErrorHelper.handleError(e, 'setRealName', false)
        return false
      }
    },
    async updateEmail(email: string): Promise<boolean> {
      try {
        const url = '/web/api/MyAccount/UpdateEmail'
        const result = await httpClient.post(
          url,
          {
            email: email
          },
          { headers: { 'api-version': '2' } }
        )
        const _reponseCamel: ApiRequestResult = result.data
        if (!_reponseCamel.success) {
          const err = new Error(_reponseCamel.errorMessage)
          ErrorHelper.handleError(
            err,
            'updateEmail',
            true,
            _reponseCamel.errorMessage
          )
        }

        const commonStore = useCommonStore()

        commonStore.fullUserInfo.email = email

        return _reponseCamel.success || false
      } catch (e) {
        ErrorHelper.handleError(e, 'setRealName', false)
        return false
      }
    },
    async setVerifyPhoneSmsErrorMessage(errorMessage: string) {
      this.verifyPhoneSmsErrorMessage = errorMessage
    },
    setConsentUrl(consentUrl: string) {
      this.consentUrl = consentUrl
    },
    setEnableMfaSmsErrorMessage(enableMfaSmsErrorMessage: string) {
      this.enableMfaSmsErrorMessage = enableMfaSmsErrorMessage
    },
    setEnableMfaSmsErrorCode(enableMfaSmsErrorCode: number) {
      this.enableMfaSmsErrorCode = enableMfaSmsErrorCode
    },
    setVerifyPhoneSmsErrorCode(verifyPhoneSmsErrorCode: number) {
      this.verifyPhoneSmsErrorCode = verifyPhoneSmsErrorCode
    },
    setShowAddMinutesSuccessToast(showToast: boolean) {
      this.showAddMinutesSuccessToast = showToast
    },
    getSubscriptionPrice(level: string) {
      const common = useCommonStore()
      return common.appSettings?.stripeSubscriptions
        ? common.appSettings?.stripeSubscriptions.find(
            (x) => x.entitlementIdentifier == level && x.legacy == false
          )?.price
        : ''
    },
    async getStripePriceId(level: string) {
      const common = useCommonStore()
      return common.appSettings?.stripeSubscriptions
        ? common.appSettings?.stripeSubscriptions.find(
            (x) => x.entitlementIdentifier == level && x.legacy == false
          )?.priceId
        : ''
    },
    async getStripePriceIdForProduct(key: string) {
      const common = useCommonStore()
      return common.appSettings?.stripeProducts
        ? common.appSettings?.stripeProducts?.products
            ?.find((r) => r.key == key)
            ?.priceId?.trim()
        : ''
    },
    async getBetaToggleAvailability() {
      try {
        const url = 'web/api/MyAccount/GetBetaToggleAvailability'

        const result = await httpClient.get(url, {
          headers: { 'api-version': '2' }
        })
        const _responseCamel: ApiRequestResult = result.data

        if (!_responseCamel.success) {
          const err = new Error(_responseCamel.errorMessage)
          ErrorHelper.handleError(
            err,
            'getBetaToggleAvailability',
            true,
            _responseCamel.errorMessage
          )
        }
        const commonStore = useCommonStore()
        commonStore.showBetaToggle = result.data.value
        return
      } catch (e) {
        ErrorHelper.handleError(e, 'getBetaToggleAvailibility', false)
        return false
      }
    }
  }
})
