<template>
  <footer>
    Copyright &copy; 2012 &mdash; {{ year }} Monitored Communications, LLC
  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  data() {
    return {
      year: this.$moment().format('YYYY')
    }
  }
})
</script>

<style lang="scss" scoped>
footer {
  background: var(--surface-1);
  display: grid;
  place-content: center;
  height: 1.5625rem;
  font-size: var(--font-size-00);
  color: var(--gray-6);
  border-top:1px solid var(--surface-3);

  @media (min-width: 414px) {
    height: 3.125rem;
    font-size: var(--font-size-0);
  }
}
</style>
