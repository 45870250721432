import { createI18n, type I18nOptions } from 'vue-i18n'
import en from './locales/en.json'
import es from './locales/es.json'

const options: I18nOptions = {
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  globalInjection: true,
  silentFallbackWarn: false, // Ensure fallback warnings are visible
  silentTranslationWarn: false, // Ensure translation warnings are visible
  messages: {
    en,
    es
  },
  missing: (locale, key) => {
    console.warn(`Missing translation for ${key} in locale ${locale}`)
  }
}

export default createI18n<false, typeof options>(options)
