﻿import moment, { type Moment } from 'moment-timezone'
import ErrorHelper from '@/exports/error'
import type {
  IDateTimeSettings,
  IActivityListItem,
  IScheduledPayment
} from '@/models/interfaces'
import {
  RequestStatus,
  ScheduledPaymentStatus,
  TransferStatus,
  ActivityType
} from './MTConstants'
import i18n from '@/i18n'

export default {
  checkApiError(result: any, source = '', handleError = true) {
    if (!result?.data?.success) {
      if (handleError) {
        ErrorHelper.handleError(new Error(result?.data?.errorMessage), source)
      }
      return true
    }

    return false
  },
  getDateTimeDisplay(
    utcDate: Date | string | undefined,
    dateTimeSettings: IDateTimeSettings
  ) {
    if (utcDate == undefined) {
      return moment
        .utc()
        .tz(dateTimeSettings.timeZoneNameForMomentJs)
        .format(dateTimeSettings.shortDateFormat.toUpperCase() + ' @ h:mm A')
    }

    return moment
      .utc(utcDate)
      .tz(dateTimeSettings.timeZoneNameForMomentJs)
      .format(dateTimeSettings.shortDateFormat.toUpperCase() + ' @ h:mm A')
  },
  getDateDisplay(
    utcDate: Date | string | undefined,
    dateTimeSettings: IDateTimeSettings,
    convertToTimeZone: boolean
  ) {
    if (convertToTimeZone) {
      return moment(utcDate)
        .tz(dateTimeSettings.timeZoneNameForMomentJs)
        .format(dateTimeSettings.shortDateFormat.toUpperCase())
    }

    return moment
      .utc(utcDate)
      .format(dateTimeSettings.shortDateFormat.toUpperCase())
  },
  getPaymentStatusString(
    paymentStatus: number,
    paidByUser?: boolean,
    coparentFullName?: string
  ) {
    switch (paymentStatus) {
      case TransferStatus.PENDING:
        return paidByUser != undefined
          ? paidByUser
            ? i18n.global.t(
                'payments.activityList.paymentActivity.titleToPending',
                { name: coparentFullName }
              )
            : i18n.global.t(
                'payments.activityList.paymentActivity.titleFromPending',
                { name: coparentFullName }
              )
          : i18n.global.t(
              'payments.activityList.paymentActivity.details.statusPending'
            )
      case TransferStatus.PROCESSED:
        return paidByUser != undefined
          ? paidByUser
            ? i18n.global.t(
                'payments.activityList.paymentActivity.titleToCompleted',
                { name: coparentFullName }
              )
            : i18n.global.t(
                'payments.activityList.paymentActivity.titleFromCompleted',
                { name: coparentFullName }
              )
          : i18n.global.t(
              'payments.activityList.paymentActivity.details.statusCompleted'
            )
      case TransferStatus.FAILED:
        return paidByUser != undefined
          ? paidByUser
            ? i18n.global.t(
                'payments.activityList.paymentActivity.titleToFailed',
                { name: coparentFullName }
              )
            : i18n.global.t(
                'payments.activityList.paymentActivity.titleFromFailed',
                { name: coparentFullName }
              )
          : i18n.global.t(
              'payments.activityList.paymentActivity.details.statusFailed'
            )
      case TransferStatus.CANCELLED:
        return paidByUser != undefined
          ? paidByUser
            ? i18n.global.t(
                'payments.activityList.paymentActivity.titleToCanceled',
                { name: coparentFullName }
              )
            : i18n.global.t(
                'payments.activityList.paymentActivity.titleFromCanceled',
                { name: coparentFullName }
              )
          : i18n.global.t(
              'payments.activityList.paymentActivity.details.statusCanceled'
            )
      default:
        return ''
    }
  },
  getRequestStatusString(requestStatus: number) {
    switch (requestStatus) {
      case RequestStatus.REQUESTED:
        return i18n.global.t(
          'payments.activityList.requestActivity.details.statusRequested'
        )
      case RequestStatus.ACCEPTED:
        return i18n.global.t(
          'payments.activityList.requestActivity.details.statusAccepted'
        )
      case RequestStatus.DECLINED:
        return i18n.global.t(
          'payments.activityList.requestActivity.details.statusDeclined'
        )
      case RequestStatus.CANCELED:
        return i18n.global.t(
          'payments.activityList.requestActivity.details.statusCanceled'
        )
    }
  },
  getScheduledPaymentStatusString(scheduledPaymentStatus: number) {
    switch (scheduledPaymentStatus) {
      case ScheduledPaymentStatus.ENABLED:
        return i18n.global.t(
          'payments.activityList.scheduledPaymentActivity.details.statusScheduled'
        )
      case ScheduledPaymentStatus.DISABLED:
        return i18n.global.t(
          'payments.activityList.scheduledPaymentActivity.details.statusPaused'
        )
      case ScheduledPaymentStatus.CANCELED:
      case ScheduledPaymentStatus.SYSTEM_CANCELED_UNVERIFIED_CUSTOMER:
      case ScheduledPaymentStatus.SYSTEM_CANCELED_UNVERIFIED_FUNDINGSOURCE:
      case ScheduledPaymentStatus.SYSTEM_CANCELED_COPARENT_UNVERIFIED:
      case ScheduledPaymentStatus.SYSTEM_CANCELED_COPARENT_FUNDINGSOURCE_UNVERIFIED:
        return i18n.global.t(
          'payments.activityList.scheduledPaymentActivity.details.statusCanceled'
        )
    }
  },
  getSortByDate(parent: any, child: any, childPrecedence: string) {
    return child
      ? child[childPrecedence]
      : parent.lastUpdatedWhen
      ? parent.lastUpdatedWhen
      : parent.createdWhen
  },
  getRelatedRequestActivityTitle(
    requestedByUser: boolean,
    requestStatus: number,
    coParentFullName: string
  ) {
    return requestStatus == RequestStatus.DECLINED
      ? requestedByUser
        ? i18n.global.t(
            'payments.activityList.requestActivity.relatedTitleDeclined',
            {
              name: coParentFullName
            }
          )
        : i18n.global.t(
            'payments.activityList.requestActivity.relatedTitleDeclinedByYou'
          )
      : requestStatus == RequestStatus.CANCELED
        ? requestedByUser
          ? i18n.global.t(
              'payments.activityList.requestActivity.relatedTitleCanceledByYou'
            )
          : i18n.global.t(
              'payments.activityList.requestActivity.relatedTitleCanceled',
              {
                name: coParentFullName
              }
            )
        : ''
  },
  getRelatedScheduledPaymentActivityTitle(
    scheduledByUser: boolean,
    status: number,
    repeats: boolean,
    coParentFullName: string
  ) {
    switch (status) {
      case ScheduledPaymentStatus.CANCELED:
        return scheduledByUser
          ? i18n.global.t(
              'payments.activityList.scheduledPaymentActivity.relatedTitleCanceledByYou'
            )
          : i18n.global.t(
              'payments.activityList.scheduledPaymentActivity.relatedTitleCanceled',
              {
                name: coParentFullName
              }
            )
      case ScheduledPaymentStatus.SYSTEM_CANCELED_UNVERIFIED_CUSTOMER:
      case ScheduledPaymentStatus.SYSTEM_CANCELED_UNVERIFIED_FUNDINGSOURCE:
      case ScheduledPaymentStatus.SYSTEM_CANCELED_COPARENT_UNVERIFIED:
      case ScheduledPaymentStatus.SYSTEM_CANCELED_COPARENT_FUNDINGSOURCE_UNVERIFIED:
      case ScheduledPaymentStatus.SYSTEM_CANCELED_FAILED_PAYMENT:
        return i18n.global.t(
          'payments.activityList.scheduledPaymentActivity.relatedTitleSystemCanceled'
        )
      default:
        return ''
    }
  },
  getRelatedScheduledPaymentActivityDetails(
    scheduledByUser: boolean,
    status: number
  ) {
    switch (status) {
      case ScheduledPaymentStatus.ENABLED:
      case ScheduledPaymentStatus.CANCELED:
        return ''
      case ScheduledPaymentStatus.SYSTEM_CANCELED_UNVERIFIED_CUSTOMER:
        return scheduledByUser
          ? i18n.global.t(
              'payments.activityList.scheduledPaymentActivity.details.relatedDetailsAccount'
            )
          : i18n.global.t(
              'payments.activityList.scheduledPaymentActivity.details.relatedDetailsAccountCoparent'
            )
      case ScheduledPaymentStatus.SYSTEM_CANCELED_COPARENT_UNVERIFIED:
        return scheduledByUser
          ? i18n.global.t(
              'payments.activityList.scheduledPaymentActivity.details.relatedDetailsAccountCoparent'
            )
          : i18n.global.t(
              'payments.activityList.scheduledPaymentActivity.details.relatedDetailsAccount'
            )
      case ScheduledPaymentStatus.SYSTEM_CANCELED_UNVERIFIED_FUNDINGSOURCE:
        return scheduledByUser
          ? i18n.global.t(
              'payments.activityList.scheduledPaymentActivity.details.relatedDetailsBankAccount'
            )
          : i18n.global.t(
              'payments.activityList.scheduledPaymentActivity.details.relatedDetailsBankAccountCoparent'
            )
      case ScheduledPaymentStatus.SYSTEM_CANCELED_COPARENT_FUNDINGSOURCE_UNVERIFIED:
        return scheduledByUser
          ? i18n.global.t(
              'payments.activityList.scheduledPaymentActivity.details.relatedDetailsBankAccountCoparent'
            )
          : i18n.global.t(
              'payments.activityList.scheduledPaymentActivity.details.relatedDetailsBankAccount'
            )
      case ScheduledPaymentStatus.SYSTEM_CANCELED_FAILED_PAYMENT:
        return i18n.global.t(
          'payments.activityList.scheduledPaymentActivity.details.relatedDetailsFailedPayment'
        )
      default:
        return ''
    }
  },
  getPaymentTitle(
    paidByUser: boolean,
    paymentStatus: number,
    coParentFullName: string
  ) {
    if (paymentStatus == TransferStatus.CANCELLED) {
      return i18n.global.t(
        'payments.activityList.paymentActivity.titleCanceled'
      )
    }
    return this.getPaymentStatusString(
      paymentStatus,
      paidByUser,
      coParentFullName
    )
  },
  getScheduledPaymentTitle(
    paidByUser: boolean,
    repeat: boolean,
    coParentFullName: string
  ) {
    if (repeat) {
      return paidByUser
        ? i18n.global.t(
            'payments.activityList.scheduledPaymentActivity.titleToRecurring',
            {
              name: coParentFullName
            }
          )
        : i18n.global.t(
            'payments.activityList.scheduledPaymentActivity.titleFromRecurring',
            {
              name: coParentFullName
            }
          )
    }

    return paidByUser
      ? i18n.global.t(
          'payments.activityList.scheduledPaymentActivity.titleToScheduled',
          {
            name: coParentFullName
          }
        )
      : i18n.global.t(
          'payments.activityList.scheduledPaymentActivity.titleFromScheduled',
          {
            name: coParentFullName
          }
        )
  },
  getScheduledPaymentSubTitle(
    repeat: boolean,
    utcDate: Date | string,
    dateTimeSettings: IDateTimeSettings
  ) {
    const scheduledWhen = moment.utc(utcDate)
    const scheduledWhenDisplay = scheduledWhen.format(
      dateTimeSettings.shortDateFormat.toUpperCase()
    )
    const scheduledWhenDayOfMonth = scheduledWhen.format('Do')

    return repeat
      ? i18n.global.t(
          'payments.activityList.scheduledPaymentActivity.subtitleRepeats',
          {
            day: scheduledWhenDayOfMonth,
            date: scheduledWhenDisplay
          }
        )
      : i18n.global.t(
          'payments.activityList.scheduledPaymentActivity.subtitleScheduled',
          {
            date: scheduledWhenDisplay
          }
        )
  },
  getBasicRequest(
    request: any,
    requestedByUser: boolean,
    userInitials: string,
    coParentInitials: string,
    coParentFullName: string,
    dateTimeSettings: IDateTimeSettings
  ) {
    const title = requestedByUser
      ? i18n.global.t('payments.activityList.requestActivity.titleTo', {
          name: coParentFullName
        })
      : i18n.global.t('payments.activityList.requestActivity.titleFrom', {
          name: coParentFullName
        })

    const SendingInitials = requestedByUser ? userInitials : coParentInitials

    return {
      title: title,
      relatedActivityTitle: this.getRelatedRequestActivityTitle(
        requestedByUser,
        request?.status || 0,
        coParentFullName
      ),
      amount: request.amount,
      description: request.description,
      descriptionInitials: SendingInitials,
      viewedWhen: request.viewedWhen ?? null,
      createdWhen: request.createdWhen,
      sending: requestedByUser,
      type: ActivityType.REQUEST
    }
  },
  formatMoney(amount: string | number | undefined) {
    if (!amount) return ''
    if (typeof amount == 'string') {
      return Number.parseFloat(amount).toFixed(2)
    } else {
      return amount
    }
  },
  // to cast payments, requests, and scheduled payments to activity list items with setting the type
  toActivityItem(activity: any, type: number) {
    // todo lulu add parameter checks
    const activityItem = activity as IActivityListItem
    activityItem.type = type
    return activityItem
  },
  getHasUpcomingPayment(
    scheduledPayment: IActivityListItem | null | undefined
  ) {
    if (!scheduledPayment) return false

    if (scheduledPayment.status != ScheduledPaymentStatus.ENABLED) {
      return false
    }

    const now = moment()
    let scheduledWithin48Hours =
      moment(scheduledPayment.scheduledWhen) > now &&
      moment(scheduledPayment.scheduledWhen) <= moment(now).add(2, 'days')

    if (scheduledWithin48Hours) return true

    if (
      scheduledPayment.repeat &&
      (!scheduledPayment.lastProcessedWhen ||
        moment(scheduledPayment.lastProcessedWhen).date() != now.date())
    ) {
      const daysInThisMonth = moment().daysInMonth()
      const nextScheduledDayForThisMonth =
        moment(scheduledPayment.scheduledWhen).day() > daysInThisMonth
          ? daysInThisMonth
          : moment(scheduledPayment.scheduledWhen).day()
      const nextScheduledDay = moment(now).day(nextScheduledDayForThisMonth)

      scheduledWithin48Hours =
        nextScheduledDay >= moment(scheduledPayment.scheduledWhen) &&
        nextScheduledDay > now &&
        nextScheduledDay <= moment(now).add(2, 'days')

      if (scheduledWithin48Hours) return true
    }

    return false
  }
}
