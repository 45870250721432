<template>
  <Teleport to="body">
    <div
      ref="movableContainer"
      class="movable-container"
      :class="{ fullscreen: fullscreen }"
      style="touch-action: none"
      :style="[style, rootStyle]"
    >
      <!-- handle/header -->
      <div
        class="drag-handle"
        ref="dragHandle"
        :class="{ 'hide-drag': !showDragHandle }"
      >
        <slot name="dragHandle">
          <div class="drag-icon-wrap">
            <font-awesome-icon icon="fa-regular fa-grip-lines" class="white" />
          </div>
        </slot>
      </div>
      <!-- body -->
      <div
        class="section-main relative"
        :class="{
          'mobile-drag': showDragHandle
        }"
      >
        <slot name="mainContent" />
      </div>
      <!-- resize handler -->
      <!-- Resize handle -->
      <div
        ref="resizeHandle"
        class="resize-handle"
        @mousedown.stop.prevent="startResize"
      >
        <font-awesome-icon
          icon="fa-regular fa-arrow-down-left-and-arrow-up-right-to-center"
          class="white"
        />
      </div>
    </div>
  </Teleport>
</template>
<script setup lang="ts">
import { useDraggable } from '@vueuse/core'
import { ref, toRefs } from 'vue'

defineOptions({
  inheritAttrs: false
})

const props = withDefaults(
  defineProps<{
    showDragHandle: boolean
    allowDrag: boolean
    rootStyle: string
    fullscreen: boolean
  }>(),
  {
    showDragHandle: true,
    allowDrag: true,
    rootStyle: '',
    fullscreen: false
  }
)
const { rootStyle, fullscreen } = toRefs(props)

const movableContainer = ref<HTMLElement | null>(null)
const dragHandle = ref<HTMLElement | null>(null)

// Drag and Drop and resize
const playerWidth = parseInt(
  getComputedStyle(document.documentElement).getPropertyValue('--player-width')
)
const playerHeight = parseInt(
  getComputedStyle(document.documentElement).getPropertyValue('--player-height')
)

const leftOffset = window.innerWidth * 0.5 - playerHeight / 2
const topOffset = window.innerHeight * 0.5 - playerWidth / 2

const { style } = useDraggable(movableContainer, {
  initialValue: { x: leftOffset, y: topOffset },
  preventDefault: true,
  handle: dragHandle,
  disabled: !props.allowDrag
})

const isResizing = ref(false)
let startX = 0
let startY = 0
let startWidth = 0
let startHeight = 0

const startResize = (event: MouseEvent) => {
  event.stopPropagation()
  event.preventDefault()
  isResizing.value = true

  startWidth = parseInt(
    document.defaultView?.getComputedStyle(movableContainer.value!).width ??
      '0',
    10
  )
  startHeight = parseInt(
    document.defaultView?.getComputedStyle(movableContainer.value!).height ??
      '0',
    10
  )

  startX = event.clientX
  startY = event.clientY
  document.addEventListener('mousemove', doResize)
  document.addEventListener('mouseup', stopResize)
}

const doResize = (event: MouseEvent) => {
  if (isResizing.value && movableContainer.value) {
    const newWidth = startWidth + event.clientX - startX
    const newHeight = startHeight + event.clientY - startY

    movableContainer.value.style.width = `${newWidth}px`
    movableContainer.value.style.height = `${newHeight}px`
  }
}

const stopResize = () => {
  isResizing.value = false
  document.removeEventListener('mousemove', doResize)
  document.removeEventListener('mouseup', stopResize)
}
//#endregion
</script>
<style scoped lang="scss">
.movable-container {
  --player-width: 85%;
  --player-height: 85%;

  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  overflow: hidden;
  z-index: 2000;
  user-select: none;
  background-color: var(--surface-1);

  box-shadow: var(--shadow-3);

  @media (width >= 48em) {
    min-width: 21.375rem;
    min-height: 21.375rem;
    width: var(--player-width);
    height: var(--player-height);
    left: calc(50vw - var(--player-width) / 2); // left: 0;
    top: calc(50vh - var(--player-height) / 2); // top: 0;
    right: unset;
    bottom: unset;
    border-radius: 0.5rem;
  }

  &.fullscreen {
    width: 100% !important;
    height: 100% !important;
    inset: 0 !important;
  }
}

.movable-container :deep(video) {
  object-fit: cover;
}

.movable-container :deep(.menu-buttons) {
  margin-left: 1rem;
  margin-right: 0;
}

.section-main {
  height: 100%;
  // overflow: hidden;
  // display: grid;

  @media (width >= 48em) {
    height: calc(100% - 2.25rem);
  }
}

.section-main.mobile-drag {
  height: calc(100% - 3rem);

  @media (width >= 48em) {
    height: calc(100% - 2.25rem);
  }
}

.resize-handle {
  display: none;

  @media (width >= 48em) {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 40px;
    height: 40px;
    background-color: #13a19c;
    cursor: se-resize;
    z-index: 5011;
    border-radius: 0.5em 0 0.5em 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.drag-handle.hide-drag {
  display: none;
}

.drag-handle {
  @media (width >= 48em) {
    width: 100%;
    background-color: var(--surface-2);
    cursor: grab;
    z-index: 2001;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem 0.5rem 0 0;
  }
}

.drag-icon-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.8625rem;
  height: 1.8625rem;
  border-radius: 50%;
  padding: 0rem;
}

.drag-icon-wrap svg {
  width: 24px;
  height: 24px;
  color: var(--brand);
}
</style>
