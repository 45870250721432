<template>
  <div class="network-quality-bar">
    <div v-for="n in 5" :key="n" :class="getBarClass(n)" class="bar"></div>
  </div>
</template>

<script>
export default {
  props: {
    quality: {
      type: [Number, null],
      required: false,
      default: 0,
      validator: (value) => value >= 1 && value <= 5
    }
  },
  methods: {
    getBarClass(n) {
      return n <= this.quality ? 'bar active' : 'bar'
    }
  }
}
</script>

<style scoped>
.network-quality-bar {
  display: flex;
  align-items: flex-end;
  height: 20px;
}

.bar {
  width: 10px;
  height: 100%;
  margin-right: 3px;
  background-color: #ccc;
  transition: background-color 0.3s;
}

.bar.active {
  background-color: #4caf50;
}

.bar:nth-child(1) {
  height: 20%;
}

.bar:nth-child(2) {
  height: 40%;
}

.bar:nth-child(3) {
  height: 60%;
}

.bar:nth-child(4) {
  height: 80%;
}

.bar:nth-child(5) {
  height: 100%;
}
</style>
