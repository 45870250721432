<template>
  <div style="text-align: center">
    <select style="width: 100%" v-model="selectedDeviceId">
      <option
        v-for="camera in cameras"
        :key="camera.deviceId"
        :value="camera.deviceId"
      >
        {{ camera.label || t('calling.videoCall.unnamedDevice') }}
      </option>
    </select>
    <div
      class="local-video"
      style="align-self: center"
      ref="localVideoDiv"
    ></div>
    <div class="action-buttons">
      <button @click="cancel" class="btn secondary">{{ t('calling.videoCall.videoDevice.cancel') }}</button>
      <button @click="selectVideoSource" class="btn primary">{{ t('calling.videoCall.videoDevice.apply') }}</button>
      <!-- Blur background button - uncomment when ready to add to the ui -->
      <!-- <input
        name="toggleBlur"
        :value="state.isBlurred"
        :checked="initialBlur"
        type="checkbox"
        @click="toggleBlur"
        style="align-self: center"
      />
      <label 
        for="toggleBlur"
        style="align-self: center"
      >
        Blur Background
      </label> -->
      <!-- Blur background button - uncomment when ready to add to the ui -->
    </div>
    <div color: v-if="errorMsg.length > 0">
      {{ errorMsg }}
    </div>
  </div>
</template>
<script lang="ts" setup>
import { onMounted, reactive, ref, type Ref } from 'vue'
import { useDevicesList, watchIgnorable } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import {
  createLocalVideoTrack,
  LocalVideoTrack
} from 'twilio-video'
import {
  Pipeline,
  GaussianBlurBackgroundProcessor
} from '@twilio/video-processors'
import { useCallingStore } from '@/stores/CallingStore'
import { useI18n } from 'vue-i18n'
const { t } = useI18n({ useScope: 'global' })

const {
  videoInputs: cameras,
} = useDevicesList()

const callingStore = useCallingStore()
const { setMediatId } = callingStore
const { videoInputId } = storeToRefs(callingStore)

const localVideoDiv = ref() as Ref<HTMLElement>
let localVideoTrack: null | void | LocalVideoTrack = null
const selectedDeviceId = ref<string>('')
const errorMsg = ref<string>('')

const props = defineProps({
  initialBlur: {
    type: Boolean,
  }
})

const state = reactive({
  isBlurred: false,
})

const emit = defineEmits<{
  (e: 'updateTrack', newTrack: LocalVideoTrack | null, isBlurred: boolean): void
  (e: 'cancel'): void
}>()

onMounted(async () => {
  init()
})

const { ignoreUpdates: ignoreSelectIdUpdate } = watchIgnorable(
  selectedDeviceId, 
  (newValue) => {
    applyInputDevice(newValue)
  }
)

async function init() {
  const _deviceId =
    videoInputId.value.length > 0
      ? videoInputId.value
      : cameras.value[0].deviceId

  ignoreSelectIdUpdate(() => {
    selectedDeviceId.value = _deviceId
  })

  state.isBlurred = props.initialBlur
  await applyInputDevice(_deviceId)
}

function selectVideoSource() {
  // only update if changed
  if (videoInputId.value == selectedDeviceId.value && props.initialBlur == state.isBlurred) {
    cancel()
  } else {
    updateVideo()
  }
}

function updateVideo() {
  if (localVideoTrack) {
    if (videoInputId.value == selectedDeviceId.value) {
      if (localVideoTrack) {
        localVideoTrack.stop()
        localVideoTrack?.detach().forEach((element: HTMLMediaElement) => {
          element.srcObject = null
          element.remove()
        })
      }
      // console.log('Updating blur only.')
      emit('updateTrack', null, state.isBlurred)
    } else {
      // processor can't be removed after the modal is closed
      if (state.isBlurred) {
        localVideoTrack.removeProcessor(blurBackground)
      }
      // console.log('Change device and/or blur state.')
      setMediatId('videoinput', selectedDeviceId.value)
      emit('updateTrack', localVideoTrack, state.isBlurred)
    }
  } else {
    setMediatId('videoinput', selectedDeviceId.value)
    emit('cancel')
  }
}

function cancel() {
  if (localVideoTrack) {
    localVideoTrack.stop()
    localVideoTrack = null
  }
  emit('cancel')
}

async function applyInputDevice(deviceId: string) {
  // Stop the previous LocalTrack, if present, and update the reactive reference
  if (localVideoTrack) {
    localVideoTrack.stop()
    localVideoTrack?.detach().forEach((element: HTMLMediaElement) => {
      element.srcObject = null
      element.remove()
    })
  }

  localVideoTrack = await createLocalVideoTrack({
    deviceId: selectedDeviceId.value,
    name: deviceId
  }).catch(handleVideoError)
  if (localVideoTrack) {
    localVideoDiv.value.appendChild(localVideoTrack.attach())
   // console.log('getLocalMedia - attaching video')
  }

  if (state.isBlurred) {
    state.isBlurred = false
    toggleBlur()
  }
}

function handleVideoError() {
  errorMsg.value =
    'There is an issue with your camera. Check to see if another program has access to your camera'
}

// BLURRING
const blurBackground = new GaussianBlurBackgroundProcessor({
  assetsPath: '/twilio',
  pipeline: Pipeline.WebGL2,
  debounce: true
})

async function toggleBlur() {
  if (localVideoTrack) {
    if (state.isBlurred) {
      localVideoTrack.removeProcessor(blurBackground)
    } else {
      if (isWebGL2Supported()) {
        // Create a WebGL2 context explicitly
        const canvas = document.createElement('canvas')
        const gl = canvas.getContext('webgl2')
        if (!gl) {
          console.error('Failed to create WebGL2 context.')
          return
        }
        //console.log('WebGL2 context created:', gl)

        checkWebGLErrors(gl)

        await blurBackground.loadModel().then(() => {
          localVideoTrack?.addProcessor(blurBackground, {
            inputFrameBufferType: 'video',
            outputFrameBufferContextType: 'webgl2'
          })
        })
        checkWebGLErrors(gl)
      }
    }
    state.isBlurred = !state.isBlurred
  }
}

function isWebGL2Supported() {
  try {
    const canvas = document.createElement('canvas')
    const gl =
      canvas.getContext('webgl2') || canvas.getContext('experimental-webgl2')
    if (!gl) {
      console.error('WebGL2 context not created.')
    } else {
    //  console.log('WebGL2 context created successfully.')
    }
    return !!gl
  } catch (e) {
    console.error('Error checking WebGL2 support:', e)
    return false
  }
}

function checkWebGLErrors(gl: WebGL2RenderingContext) {
  const error = gl.getError()
  if (error !== gl.NO_ERROR) {
    console.error('WebGL error:', error)
  } else {
    console.log('No WebGL errors.')
  }
}
</script>
<style scoped>
.local-video { margin-top: 1rem; }
.local-video :deep(video){ border-radius: .75rem; width: 100%; }
.action-buttons { display: flex; gap: 1rem; margin: 1rem auto 0; width: fit-content; }
</style>
