<template>
  <nav class="flex relative align-middle">
    <button
      v-if="showGoBack"
      @click="goBack()"
      type="button"
      class="btn tertiary go-back-button"
    >
      <font-awesome-icon :icon="goBackIconString" class="icon-size-1" />
    </button>
    <div
      v-if="displayRootPathAlways && showEllipse"
      class="flex align-middle crumb-container first"
      @click="clickCrumb(getRootCrumb, 0)"
      style="list-style-type: none"
    >
      <strong class="crumb">
        <font-awesome-icon
          v-if="props.displayVaultIcon"
          icon="fa-kit fa-vault"
        />
        {{ getRootCrumb.crumbName }}
      </strong>
      <font-awesome-icon
        :icon="pathDividerIconString"
        :class="pathDividerIconClass"
      />
    </div>
    <font-awesome-icon
      ref="ellipseIcon"
      v-if="showEllipse"
      icon="fa-light fa-circle-ellipsis"
      :class="ellipseIconClass"
      @click="toggleHiddenFolders()"
    />
    <ol
      class="hidden-folders-list"
      :class="{ 'keep-root': displayRootPathAlways }"
      v-if="state.displayPrevFolders"
    >
      <li
        v-for="(crumb, i) in state.hiddenCrumbs.slice(
          displayRootPathAlways ? 1 : 0
        )"
        :key="i"
        @click="clickCrumb(crumb, i + (displayRootPathAlways ? 1 : 0))"
      >
        <font-awesome-icon
          icon="fa-light fa-folder"
          class="icon-size-1 ml-000 mr-000"
        />
        <strong style="--base-weight: 500">
          {{ crumb.crumbName }}
        </strong>
      </li>
    </ol>
    <ol class="flex align-middle">
      <li
        v-for="(crumb, i) in state.displayedCrumbs"
        :key="i"
        class="crumb-container"
        :class="{ first : getRootCrumb.breadcrumbData === crumb.breadcrumbData  }"
      >
        <font-awesome-icon
          v-if="i || showEllipse"
          :icon="pathDividerIconString"
          :class="pathDividerIconClass"
        />

        <!-- <span
          v-if="
            crumb.breadcrumbData?.isFolder &&
            i == state.displayedCrumbs?.length - 1 &&
            showFolderIcon
          "
          class="text-1 crumb-folder-icon"
        >
          <font-awesome-icon icon="fa-light fa-folder" class="icon-size-1" />
        </span> -->

        <strong
          class="crumb"
          @click="clickCrumb(crumb, state.hiddenCrumbs.length + i)"
          :style="highlightCrumb(i, state.displayedCrumbs.length)"
        >
          <font-awesome-icon
            v-if="
              props.displayVaultIcon &&
              crumb.breadcrumbData == getRootCrumb.breadcrumbData
            "
            icon="fa-kit fa-vault"
          />
          {{ crumb.crumbName }}
        </strong>

        <button
          v-if="
            crumb.breadcrumbData?.isFolder &&
            !crumb.breadcrumbData?.isTrashed &&
            i == state.displayedCrumbs?.length - 1 &&
            allowEdit
          "
          class="btn tertiary crumb-folder-edit"
          @click="edit(crumb)"
        >
          <font-awesome-icon icon="fa-light fa-pen" class="icon-size-0" />
        </button>
      </li>
    </ol>
  </nav>
</template>
<script setup lang="ts">
import {
  computed,
  onBeforeMount,
  reactive,
  ref,
  watch,
  type PropType,
  type StyleValue
} from 'vue'
import type { IBreadcrumbEvent } from '@/models/interfaces'
import { onClickOutside } from '@vueuse/core'

const props = defineProps({
  breadcrumbs: {
    type: Array<IBreadcrumbEvent>,
    required: true
  },
  breadcrumbViewLimit: {
    type: Number,
    default: 3
  },
  displayRootPathAlways: {
    type: Boolean,
    default: false
  },
  highlightLatestCrumb: {
    type: Boolean,
    default: false
  },
  highlightStyle: {
    type: Object as PropType<StyleValue>,
    default: () => {
      return {
        'font-weight': 'var(--font-weight-2)',
        'background-color': 'var(--brand-3)'
      }
    }
  },
  showGoBackButton: {
    type: Boolean,
    default: false
  },
  backButtonOverride: {
    type: Boolean,
    default: false
  },
  goBackIconString: {
    type: String,
    default: 'fa-regular fa-arrow-left'
  },
  pathDividerIconString: {
    type: String,
    default: 'fa-light fa-chevron-right'
  },
  pathDividerIconClass: {
    type: String,
    default: 'icon-size-2 ml-0 mr-0'
  },
  ellipseIconClass: {
    type: String,
    default: 'icon-size-3 ml-000 mr-0'
  },
  showFolderIcon: {
    type: Boolean,
    default: true
  },
  allowEdit: {
    type: Boolean,
    default: true
  },
  displayVaultIcon: {
    type: Boolean,
    default: false
  },
  allowEllipse: {
    type: Boolean,
    default: true
  }
})

const emit = defineEmits<{
  (e: 'breadcrumbClicked', crumb: IBreadcrumbEvent, i?: number): void
  (
    e: 'goBack',
    popCrumb?: IBreadcrumbEvent,
    newLatestCrumb?: IBreadcrumbEvent
  ): void
  (e: 'edit', crumb: IBreadcrumbEvent): void
}>()

const state = reactive({
  hiddenCrumbs: [{} as IBreadcrumbEvent],
  displayedCrumbs: [{} as IBreadcrumbEvent],
  displayPrevFolders: false
})

const ellipseIcon = ref(null)

onBeforeMount(() => {
  setHiddenAndDisplayCrumbs(props.breadcrumbs)
})

onClickOutside(
  ellipseIcon,
  () => {
    toggleHiddenFolders(false)
  },
  { ignore: [ellipseIcon] }
)

// set 'deep: true' to iterate through all objects when using a getter
watch(
  () => props.breadcrumbs,
  (newValue) => {
    setHiddenAndDisplayCrumbs(newValue)
  },
  {
    deep: true
  }
)

function clickCrumb(crumb: IBreadcrumbEvent, i: number) {
  emit('breadcrumbClicked', crumb, i)
}

function toggleHiddenFolders(override?: boolean) {
  if (override != undefined) {
    state.displayPrevFolders = override
  } else {
    state.displayPrevFolders = !state.displayPrevFolders
  }
}

function setHiddenAndDisplayCrumbs(newBreadcrumbs: IBreadcrumbEvent[]) {
  if (newBreadcrumbs.length <= props.breadcrumbViewLimit) {
    state.displayedCrumbs = newBreadcrumbs
    state.hiddenCrumbs = []
  } else {
    state.displayedCrumbs = newBreadcrumbs.slice(
      0 - (props.breadcrumbViewLimit - +props.displayRootPathAlways)
    )
    state.hiddenCrumbs = newBreadcrumbs.slice(
      0,
      0 - (props.breadcrumbViewLimit - +props.displayRootPathAlways)
    )
  }
}

function highlightCrumb(i: number, len: number) {
  return props.highlightLatestCrumb && i == len - 1 ? props.highlightStyle : ''
}

function goBack() {
  const popCrumb = {
    ...props.breadcrumbs[props.breadcrumbs.length - 1],
    folderDepth: props.breadcrumbs.length - 1
  }
  const leftCrumb = {
    ...props.breadcrumbs[props.breadcrumbs.length - 2],
    folderDepth: props.breadcrumbs.length - 2
  }
  emit('goBack', popCrumb, leftCrumb)
}

function edit(crumb: IBreadcrumbEvent) {
  emit('edit', crumb)
}

const showEllipse = computed(() =>
  (props.breadcrumbs.length > props.breadcrumbViewLimit) &&
  props.allowEllipse
)

const getRootCrumb = computed(() => {
  return props.breadcrumbs[0]
})

const showGoBack = computed(() => {
  return (
    (props.showGoBackButton && props.breadcrumbs.length > 1) ||
    props.backButtonOverride
  )
})
</script>
<style scoped lang="scss">
li {
  width: 100%;
  // max-width: fit-content;
  display: flex;
  align-items: center;
}
.display-more-folders {
  transform: rotate(90deg);
  border: 1px solid var(--gray-6);
  padding: 0.125rem;
  border-radius: 50%;
}
.hidden-folders-list {
  position: absolute;
  top: 2.5rem;
  color: var(--text-0);
  background-color: var(--surface-1);
  border: solid 1px var(--surface-3);
  box-shadow: var(--dropdown-shadow);
  z-index: 1;
  border-radius: var(--size-0);
  overflow: hidden;

  li {
    padding: var(--size-00);
    padding-right: var(--size-1);
    font-size: var(--size-1);
    transition: background-color 0.25s ease-in-out;
    cursor: pointer;
  }
  li:hover {
    background-color: var(--surface-2);
  }
  &.keep-root {
    left: 8rem;
  }
}

.crumb {
  padding: var(--size-00);
  border-radius: var(--radius-3);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  --base-weight: 500;
  transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;

  &:hover {
    background-color: var(--surface-2);
    cursor: pointer;
    color: var(--text-0);
  }
}

.go-back-button {
  padding: var(--size-00);
  margin-right: var(--size-00);
  transition: all 0s, background-color 0.25s ease-in-out;

  &:hover {
    background-color: var(--surface-2);
  }
}

.crumb-container {
  display: flex;
  align-items: center;
}
li.crumb-container {
  width: auto;
  flex-grow: 1;

}
li:nth-child(1) {min-width: 6ch;}
li:nth-child(2) {min-width: 6ch;}
nav svg { flex: none; }
.crumb-folder-icon {
  padding-inline-start: var(--size-00);
}

.crumb-folder-edit {
  padding: var(--size-00);
}
</style>
