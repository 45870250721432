<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div
    v-skeleton="{
      loading: $props.fetching,
      minWidth: 5,
      maxWidth: 5,
      avatar: true
    }"
    class="avatar"
    :class="[
      avatarClass,
      avatarSizeClass,
      avatarInverseClass,
      { disabled: disabled }
    ]"
  >
    <span class="sr-only user-name">{{ getName }}</span>
    <span class="user-initials font-weight-2" aria-hidden="true">{{
      getInitials
    }}</span>
  </div>
</template>

<script lang="ts" setup>
import { useCommonStore } from '@/stores/CommonStore'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

const props = defineProps({
  isParent: Boolean,
  alt: Boolean,
  size: {
    type: Number,
    default: 2
  },
  invertColor: { type: Boolean, default: false },
  fetching: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false }
})

const commonStore = useCommonStore()
const { fullUserInfo } = storeToRefs(commonStore)

const avatarClass = computed(() => {
  return props.isParent
    ? props.alt
      ? 'current-user-alt'
      : 'current-user'
    : props.alt
    ? 'co-parent-alt'
    : 'co-parent'
})

const avatarSizeClass = computed(() => {
  return props.size ? `size-${props.size}` : 'size-2'
})

const avatarInverseClass = computed(() => {
  return props.invertColor ? 'inverse-avatar' : ''
})

const getInitials = computed(() => {
  return props.isParent
    ? fullUserInfo.value.initials
    : fullUserInfo.value.coparentInitials
})

const getName = computed(() => {
  return props.isParent
    ? fullUserInfo.value.fullName
    : fullUserInfo.value.coparentFullName
})
</script>

<style lang="scss" scoped>
.avatar.inverse-avatar {
  border-color: var(--white) !important;
  color: var(--white) !important;
  background-color: transparent;
  background: none;
}

.avatar.skeleton,
.avatar.co-parent.skeleton {
  border: none;
  background: none;
  --background-color: rgba(111, 111, 111, 0.2) !important;
}

.disabled {
  opacity: 0.5;
}
</style>
