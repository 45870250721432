<template>
  <div
    ref="adContainer"
    v-observe-visibility="viewHandler"
    class="adsense-wrapper"
    :class="[isMobileWidth ? 'mobile' : 'desktop', wrapperClass]"
  >
    <ins
      :class="props.insclass"
      :style="props.insstyle"
      :data-ad-client="state.clientCode"
      :data-ad-slot="props.slotcode"
      :data-ad-format="props.format"
      data-full-width-responsive="false"
      :data-adtest="props.adtest"
    />
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { ref, reactive, computed, watch } from 'vue'
import { useAccountSettingsStore } from '@/stores/AccountSettingsStore'
import constants from '@/exports/constants'
import { useCommonStore } from '@/stores/CommonStore'

declare global {
  interface Window {
    adsbygoogle: { [key: string]: unknown }[] //https://stackoverflow.com/questions/64694173/how-to-add-adsbygoogle-adsense-property-to-the-window-global-object-in-types
  }
}

const commonStore = useCommonStore()
const { isMobileWidth } = storeToRefs(commonStore)

const accountSettingsStore = useAccountSettingsStore()
const { subscriptionT0 } = storeToRefs(accountSettingsStore)
const adContainer = ref<HTMLInputElement | null>(null)

const props = defineProps({
  insclass: {
    type: String,
    default: 'adsbygoogle'
  },
  insstyle: {
    type: String,
    default: 'display:block'
  },
  slotcode: {
    type: String,
    required: true
  },
  format: {
    type: String,
    default: constants.adFormat.auto
  },
  adtest: {
    type: String,
    default: constants.adTest.off
  }
})

const state = reactive({
  scriptLoaded: false,
  clientCode: import.meta.env.VITE_APP_ADSENSE_CLIENT_CODE,
  googleAdSenseUrl: import.meta.env.VITE_APP_GOOGLE_ADSEMSE_URL,
  adSenseDivIsVisible: false,
  adsAreLoaded: false
})

const canPushAds = computed(() => {
  return (
    subscriptionT0.value &&
    state.adSenseDivIsVisible &&
    state.scriptLoaded &&
    adContainer.value &&
    adContainer.value?.clientWidth > 0 &&
    !state.adsAreLoaded
  )
})

const wrapperClass = computed(() => {
  switch (props.format) {
    case constants.adFormat.vertical:
    case constants.adFormat.horizontal:
      return props.format
    default:
      return isMobileWidth.value
        ? constants.adFormat.horizontal
        : constants.adFormat.vertical
  }
})

watch(
  () => state.adSenseDivIsVisible,
  async () => {
    let scriptFound = false
    const scripts = document.getElementsByTagName('script')
    for (let i = scripts.length; i--; ) {
      if (scripts[i].src === state.googleAdSenseUrl) scriptFound = true
    }
    if (!scriptFound) {
      const head = document.head
      const s = document.createElement('script')
      s.type = 'text/javascript'
      s.src = state.googleAdSenseUrl
      s.setAttribute('data-ad-client', state.clientCode)
      s.onload = () => {
        scriptFound = true
        state.scriptLoaded = true
        pushGoogleAds()
      }
      head.appendChild(s)
    } else {
      state.scriptLoaded = true
      if (state.adSenseDivIsVisible) {
        pushGoogleAds()
      }
    }
  }
)

function pushGoogleAds() {
  let adsbygoogle: unknown

  if (canPushAds.value) {
    if (typeof window.adsbygoogle !== 'undefined') {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ;(adsbygoogle = window.adsbygoogle || []).push({})
      state.adsAreLoaded = true
    }
  }
}

function viewHandler(isVisible: boolean) {
  if (isVisible) {
    state.adSenseDivIsVisible =
      (adContainer.value && adContainer.value?.clientWidth > 0) ?? false
  }
}
</script>
<style scoped>
.adsense-wrapper {
  position: relative;
  background: var(--surface-1);
  border: solid 1px var(--surface-4);
  border-radius: 0 0 var(--radius-2) var(--radius-2);
  align-self: flex-start;
  margin-block: 1.75rem 1rem;
  margin-inline: auto;

  @media (width >= 48em) {
    margin-left: revert;
    margin-right: revert;
    margin-inline: revert;
  }

  &::before {
    content: 'ADVERTISEMENT';
    letter-spacing: 0.2ch;
    border-radius: var(--radius-2) var(--radius-2) 0 0;
    font-size: var(--size-0);
    font-variation-settings: 'wght' 500;
    color: var(--text-2);
    width: calc(100% + 2px);
    left: -1px;
    transform: translateY(-100%);
    position: absolute;
    background: var(--surface-3);
    padding: 0.5rem;
    line-height: 1;
    display: block;
  }

  &.horizontal.desktop {
    width: 100%;
    padding-block-end: 0;
  }

  &.horizontal.mobile {
    width: calc(100% - 2rem);
  }

  &.vertical.mobile {
    width: 336px;
    height: auto;
  }

  &.vertical.desktop {
    width: 336px;
    height: auto;
  }
}
</style>
