<template>
  <div class="content-wrap" :class="{ 'p-2': padding }" id="content-wrap">
    <transition-group
      name="toast-notification"
      tag="div"
      class="toast-notifications"
    >
      <Toast key="toast"></Toast>
    </transition-group>
    <!-- SVG Sprite -->
    <main
      id="main-content-area"
      class="app-main layout-full"
      :class="[layoutClass]"
    >
      <div
        id="main-content"
        ref="withAd"
        class="main-content stack size-1 relative"
        :class="{ 'no-count': showingNoCount }"
      >
        <slot id="slot" />
      </div>
      <!-- AdSense  -->
      <div
        v-if="showDesktopAd"
        id="divAdRightDesktop"
        ref="divAdRightDesktop"
        class="main-adsense"
      >
        <ad-sense-component
          :key="isMobileWidth ? 'mobileKey' : 'desktopKey'"
          :slotcode="isMobileWidth ? adsenseMobileKey : adsenseDesktopKey"
          :format="isMobileWidth ? 'auto' : 'vertical'"
          :adtest="isDev ? 'on' : 'off'"
        />
      </div>
      <!-- /AdSense  -->
    </main>
  </div>
</template>
<script lang="ts" setup>
import { useAccountSettingsStore } from '@/stores/AccountSettingsStore'
import { useCommonStore } from '@/stores/CommonStore'
import { storeToRefs } from 'pinia'
import { computed, nextTick, onMounted, ref, toRef } from 'vue'
import { useRoute } from 'vue-router'
import AdSenseComponent from '@/components/AdsenseComponent.vue'
import Toast from '@/components/library/Toast.vue'
import { validMethods } from 'workbox-routing/utils/constants'

const route = useRoute()
const divAdRightDesktop = ref<HTMLInputElement | null>(null)
const withAd = ref<HTMLInputElement | null>(null)

const props = defineProps({
  layoutClass: { type: String, default: 'layout-md' },
  padding: { type: Boolean, default: true }
})
const layoutClass = toRef(props, 'layoutClass')
const padding = toRef(props, 'padding')

const commonStore = useCommonStore()
const AccountSettingsStore = useAccountSettingsStore()
const {
  isMobileWidth,
  isDesktopWidth,
  adsenseDesktopKey,
  adsenseMobileKey,
  layoutOptions,
  isDev,
  showingNoCount
} = storeToRefs(commonStore)
const { subscriptionT0 } = storeToRefs(AccountSettingsStore)

const showDesktopAd = computed(() => {
  return subscriptionT0.value && route.meta.layoutContainsAds
})

const isLocalhost = computed(() => {
  const url = window.location.href
  return url.includes('localhost') ? 'on' : 'off'
})

onMounted(() => {
  nextTick(() => {
    const contentWrap = document.getElementById('content-wrap')
    const mainContentArea = document.getElementById('main-content-area')
    const slot = document.getElementById('main-content')
      ?.firstElementChild as HTMLElement

    const observer = new MutationObserver(function (mutations, observer) {
      if (contentWrap) contentWrap.style.height = '100%'
      if (mainContentArea) mainContentArea.style.height = '100%'
      if (slot) slot.style.height = '100%'
    })

    if (contentWrap)
      observer.observe(contentWrap, {
        attributes: true,
        attributeFilter: ['style']
      })
    if (mainContentArea)
      observer.observe(mainContentArea, {
        attributes: true,
        attributeFilter: ['style']
      })
    if (slot)
      observer.observe(slot, {
        attributes: true,
        attributeFilter: ['style']
      })
  })
})
</script>

<style lang="scss" scoped>
.no-count {
  min-height: 100% !important;
}

.toast-notification-move, /* apply transition to moving elements */
.toast-notification-enter-active,
.toast-notification-leave-active {
  transition: all 0.5s ease;
}

.toast-notification-enter-from,
.toast-notification-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.content-wrap {
  min-width: 0;
  height: 100%;

  @media (min-width: 48em) {
    display: flex;
    flex-direction: column;
  }
}

.toast-notifications {
  display: flex;
  justify-content: center;
}

.main-adsense {
  background: var(--surface-1);

  @media (width >= 48em) {
    background: unset;
  }
}
</style>
