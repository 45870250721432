<template>
  <div ref="modalRef" class="error-modal-root">
    <!-- Content -->
    <svg
      class="logo-svg"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 750 517.58"
    >
      <path
        class="st2"
        fill="var(--logo-green)"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M458.48 406.25c-4.31-17.25-33.42-2.16-57.14 9.7-40.97 20.48-85.17 31.27-131.53 32.34-115.36 0-218.86-69-263.06-175.73-2.16-3.23-6.47-5.39-6.47-2.16l1.08 3.23C44.49 398.7 163.08 482.79 295.68 482.79c42.05 0 84.09-8.62 122.9-25.87 8.63-3.24 47.44-21.56 39.9-50.67"
      />
      <path
        class="st2"
        fill="var(--logo-green)"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M738.79 406.33c-9.7 14.02-30.19-9.7-47.44-26.95-29.11-31.27-64.69-54.98-103.5-71.15-100.26-37.74-213.46-11.86-287.85 65.76-2.16 2.16-6.47 2.16-5.39-1.08l2.16-2.16c79.78-94.87 210.23-129.37 325.59-85.17 36.66 14.02 71.15 35.58 99.19 62.53 6.45 6.47 33.41 35.58 17.24 58.22"
      />
      <path
        class="st3"
        fill="var(--logo-teal)"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M329.11 296.37c3.23 0 6.47 1.08 9.7 1.08 7.55-5.39 16.17-10.78 24.8-15.09-6.47-1.08-12.94-2.16-19.41-3.23-69-6.47-134.76 31.27-162.79 93.8l-1.08 2.16c0 1.08 2.16 1.08 3.23 0 28.03-53.93 85.17-84.11 145.55-78.72"
      />
      <path
        class="st2"
        fill="var(--logo-green)"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M501.6 36.54c51.75 0 93.8 46.36 93.8 103.5s-42.05 103.5-93.8 103.5-93.8-46.36-93.8-103.5 42.05-103.5 93.8-103.5"
      />
      <path
        class="st2"
        fill="var(--logo-green)"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M112.41 113.09c51.75 0 93.8 46.36 93.8 103.5s-42.05 103.5-93.8 103.5-93.8-46.36-93.8-103.5 42.05-103.5 93.8-103.5"
      />
      <path
        class="st3"
        fill="var(--logo-teal)"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M306.47 134.65c30.19-1.08 54.98 25.87 56.06 59.3s-23.72 61.45-53.91 61.45c-30.19 1.08-54.98-25.87-56.06-59.3-1.08-33.42 23.72-60.37 53.91-61.45"
      />
    </svg>

    <span class="title">
      {{ errorModal.title }}
    </span>

    <hr />

    <template v-if="errorModal.showFixError">
      <p class="helper-text" v-html="fixError" />

      <p class="error-text" v-html="errorText" />
    </template>

    <p
      v-else-if="errorModal.showGenericError"
      class="helper-text"
      v-html="genericError"
    />
  </div>
</template>

<script lang="ts" setup>
import { useCommonStore } from '@/stores/CommonStore'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'

const { t } = useI18n({ useScope: 'global' })

const commonStore = useCommonStore()
const { errorModal } = storeToRefs(commonStore)

const fixError = t('errorModal.fixError', {
  contact:
    '<a href="mailto:support@talkingparents.com">support@talkingparents.com</a>'
})

const errorText = t('errorModal.errorText', {
  error: `<span>${errorModal.value.errorMessage}</span>`
})

const genericError = t('errorModal.genericError', {
  contact:
    '<a href="mailto:support@talkingparents.com">support@talkingparents.com</a>'
})
</script>

<style scoped>
.error-modal-root {
  display: flex;
  flex-direction: column;
  gap: var(--size-1);
  align-items: center;

  padding: var(--size-3) var(--size-1) var(--size-1);

  @media (width >= 48em) {
    padding: var(--size-3);
  }
}

.logo-svg {
  --logo-green: light-dark(#014847, #ffffff);
  --logo-teal: light-dark(#29b99a, #ffffff);

  height: 2.5rem;
}

hr {
  margin-block: 0;
  margin-inline: calc(var(--size-1) * -1);

  width: calc(100% + var(--size-3) * 2);

  @media (width >= 48em) {
    margin-inline: calc(var(--size-3) * -1);

    width: calc(100% + var(--size-3) * 2);
  }
}

.title {
  font-variation-settings: 'wght' 500;
  font-size: var(--font-size-5);
}

.helper-text {
  text-align: center;
  font-size: var(--font-size-1);
}

.error-text {
  background: var(--surface-2);
  border-radius: 0.5rem;

  padding: var(--size-00) var(--size-1);

  text-align: center;
  font-size: var(--font-size-1);
  font-variation-settings: 'wght' 500;

  & > :deep(span) {
    font-variation-settings: 'wght' 400;
  }
}
</style>
